import React from 'react';
import { Button, Col, Row, Typography } from 'antd';
import PropType from 'prop-types';
import ProductGrid from './ProductsGrid';
import * as productActions from '../../actions/productActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import ProductFilters from './ProductFilters';
import { productsDefaultQuery } from '../../constants/constants';
import Statistics from '../widgets/Statistics';

const { Title } = Typography;

class ProductsView extends React.Component {
  constructor() {
    super();
    this.state = {
      isFiltersShown: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.actions.getProducts(productsDefaultQuery);
  }

  toggleFilters() {
    this.setState({ isFiltersShown: !this.state.isFiltersShown });
  }

  onProductItemViewClicked(v) {
    this.props.history.push(`/products/view/${v}`);
  }

  onPaginate(paginateObject) {
    this.props.actions.getProducts(
      Object.assign({}, productsDefaultQuery, {
        ...this.props.gridFilterQuery,
        ...paginateObject,
      }),
    );
    this.props.actions.setPagination(paginateObject);
  }

  onFilterClicked(filterObject) {
    this.props.actions.setGridFilterQuery(filterObject);
    this.props.actions.getProducts(filterObject);
  }

  render() {
    return (
      <div className="dashboard-subpage-container">
        <Statistics />
        <div className="subpage-main-content">
          <Title level={3}>All Items & Services</Title>
          <Row justify="space-between" className="grid-actions">
            <Col className="left">
              <Button type="link" onClick={this.toggleFilters.bind(this)}>
                Filters <DownOutlined />
              </Button>
            </Col>
            <Col className="right">
              <Button
                className="sp-primary-alt"
                onClick={() => this.props.history.push(`/products/add-new`)}
              >
                <PlusOutlined /> Add New Item/Service
              </Button>
            </Col>
          </Row>
          <ProductFilters
            onFilterClicked={this.onFilterClicked.bind(this)}
            visible={this.state.isFiltersShown}
          />
          <ProductGrid
            isLoading={this.props.isLoading}
            data={this.props.products}
            onPaginate={this.onPaginate.bind(this)}
            onItemViewClicked={this.onProductItemViewClicked.bind(this)}
            getProducts={this.props.actions.getProducts}
          />
        </div>
        {/*<pre>{JSON.stringify(this.props.products, null, 2)}</pre>*/}
      </div>
    );
  }
}

ProductsView.propTypes = {
  products: PropType.object,
  actions: PropType.object,
  history: PropType.object,
  isLoading: PropType.bool,
  gridFilterQuery: PropType.object,
  gridPagination: PropType.object,
};

function mapStateToProps(state) {
  return {
    products: state.products.gridData,
    gridFilterQuery: state.products.gridFilterQuery,
    gridPagination: state.products.gridPagination,
    isLoading: state.common.isLoadingData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(productActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsView);
