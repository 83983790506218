import React, { useState } from 'react';
import { Button, Form } from 'antd';
import otpSubmitIcon from '../../assets/otp-submit-icon.svg';
import PropType from 'prop-types';
import OtpInput from 'react-otp-input';

const OTPVerifyModelForm = (props) => {
  const [otpCode, setOtpCode] = useState(null);

  return (
    <>
      <Form
        layout="vertical"
        name="form_in_modal"
        // initialValues={{
        //   modifier: 'public',
        // }}
      >
        <div style={{ textAlign: 'center' }}>
          <img src={otpSubmitIcon} />
          <p className="otptitle">Verification code</p>
          <p className="otpdescription">
            A PIN has been sent to your mobile number. Please enter the PIN to verify.
          </p>
          <Form.Item
            name="otp_number"
            rules={[
              {
                required: true,
                message: 'Please input the OTP!',
              },
            ]}
          >
            <OtpInput
              numInputs={6}
              onChange={setOtpCode}
              value={otpCode}
              containerStyle="otp-input-container"
              isInputNum={true}
            />
          </Form.Item>
          <p>
            {`Haven't received it? `}
            <a href="#" onClick={props.onResendPressed || null}>
              Resend
            </a>
          </p>
        </div>
      </Form>
      <Button
        className="sp-primary"
        loading={props.isLoadingData}
        onClick={() => {
          props.onVerifyPressed(otpCode);
        }}
        disabled={!(otpCode && otpCode.length === 6)}
      >
        Verify
      </Button>
    </>
  );
};

OTPVerifyModelForm.propTypes = {
  onVerifyPressed: PropType.func,
  onResendPressed: PropType.func,
  isLoadingData: PropType.bool,
};

export default OTPVerifyModelForm;
