import { MAX_IMAGEUPLOAD } from '../constants/config';

export const imageRules = () => [
  () => ({
    validator(rule, value) {
      if (!value || (value.fileList && value.fileList.length === 0)) {
        return Promise.reject('Please upload at least one document/image');
      } else if (value.fileList.length > MAX_IMAGEUPLOAD) {
        return Promise.reject(
          `Only ${MAX_IMAGEUPLOAD} items are allowed to upload. Please remove ${
            value.fileList.length - MAX_IMAGEUPLOAD
          } of ${value.fileList.length} image/s to continue.`,
        );
      } else {
        return Promise.resolve();
      }
    },
  }),
];

export const priceValidator = (min, max) => [
  () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.reject('You can’t keep this empty');
      } else if (value < min) {
        return Promise.reject(`Please enter a value above Rs: ${min}.00`);
      } else if (value > max) {
        return Promise.reject(`Please enter a value below Rs: ${max}.00`);
      } else if (!RegExp(/^(?:\d*\.\d{1,2}|\d+)$/).test(value)) {
        return Promise.reject('Please enter a valid price value');
      } else {
        return Promise.resolve();
      }
    },
  }),
];

export const accountNumberValidator = () => [
  () => ({
    validator(rule, value) {
      if (!value || !(value.trim())) {
        return Promise.reject('You can’t keep this empty');
      } else if (value && value.length > 12) {
        return Promise.reject('Account number is too lengthy');
      } else if (!RegExp('^\\d+$').test(value)) {
        return Promise.reject('Please enter a valid character');
      } else {
        return Promise.resolve();
      }
    },
  }),
];
export const accountNameValidator = () => [
  () => ({
    validator(rule, value) {
      if (!value || !(value.trim())) {
        return Promise.reject('You can’t keep this empty');
      } else if (value.length < 3) {
        return Promise.reject('Account holder’s name is too short');
      } else if (value && value.length > 25) {
        return Promise.reject('Account holder’s name is too lengthy');
      } else if (!RegExp('^\\b(?!.*?\\s{2})[A-Za-z ]{0,25}\\b$').test(value)) {
        return Promise.reject('Please enter a valid character');
      } else {
        return Promise.resolve();
      }
    },
  }),
];

export const mobileNumberValidator = () => [
  () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.reject('You can’t keep this empty');
      } else if (!RegExp(/^[0][7][764]{1}\d{7}$/gs).test(value)) {
        return Promise.reject('Please enter a valid Dialog number');
      } else {
        return Promise.resolve();
      }
    },
  }),
];


export const nameValidator = () => [
  () => ({
    validator(rule, value) {
      if (!value || !(value.trim())) {
        return Promise.reject('You can’t keep this empty');
      } else if (value.length < 3) {
        return Promise.reject('Name is too short');
      } else if (value && value.length > 15) {
        return Promise.reject('Name is too lengthy');
      } else if (!RegExp('^\\b(?!.*?\\s{2})[A-Za-z ]{0,25}\\b$').test(value)) {
        return Promise.reject('Please enter a valid character');
      } else {
        return Promise.resolve();
      }
    },
  }),
];

export const emailValidator = () => [
  () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.reject('You can’t keep this empty');
      } else if (!RegExp('^[\\w-\\.\\+]+@([\\w-]+\\.)+[\\w-]{2,4}$').test(value)) {
        return Promise.reject('Please enter a valid email');
      } else {
        return Promise.resolve();
      }
    },
  }),
];

export const natureOfBusinessValidator = () => [
  () => ({
    validator(rule, value) {
      if (!value || value.length == 0) {
        return Promise.reject('You can’t keep this empty');
      }
      {
        return Promise.resolve();
      }
    },
  }),
];

export const natureOfBusinessOtherValidator = () => [
  () => ({
    validator(rule, value) {
      if (!value || !(value.trim())) {
        return Promise.reject('You can’t keep this empty');
      } else if (value && value.length > 250) {
        return Promise.reject(
          'Nature Of Business is too lengthy')
        }else {
        return Promise.resolve();
      }
    },
  }),
];
