import React from 'react';
import { Button, Col, Row, Spin, Table } from 'antd';
import PropType from 'prop-types';
import moment from 'moment';
import { hot } from 'react-hot-loader';
import { EyeTwoTone } from '@ant-design/icons';

class OrdersGrid extends React.Component {
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows,
      );
    },
  };

  constructor() {
    super();
    this.columns = [
      {
        title: 'Order reference',
        dataIndex: 'invoiceNumber',
        key: 'invoiceNumber',
        width: 20,
        render: (key) => {
          return key ? key.toUpperCase() : '-';
        },
      },
      {
        title: 'Item/Service name',
        dataIndex: 'product',
        key: 'product',
        width: 200,
        render: (product) => {
          return product && product.name;
        },
      },
      {
        title: 'Price + Delivery fee(LKR)',
        dataIndex: 'price',
        key: 'price',
        align: 'right',
        width: 200,
        render: (text, row) => (row.deliverAvailable) ? (Number(row.price) + Number(row.deliverFee)).toFixed(2) : Number(row.price).toFixed(2),
      },
      {
        title: 'Order date',
        dataIndex: 'orderDate',
        key: 'orderDate',
        width: 110,
        render: (text, row) => {
          return row.orderDate ? moment(row.orderDate).format('YYYY-MM-DD') : 
                 row.createdOn ? moment(row.createdOn).format('YYYY-MM-DD') : '-';
        },
      },
      {
        title: 'Status',
        key: 'transStatus',
        width: 235,
        align: 'center',
        render: (v) => {
          //return v && v.transStatus && this.orderStatusSelector(v.transStatus);
          return(
            <div className="status__wrapper">
              <div className={"status-item small " + (v.transStatus === 'delivery in progress' ? 'dip' : v.transStatus)}>
                <div className="icon-holder"></div> 
                <span>{v.transStatus}</span>
              </div>
            </div>
          )
        },
      },
      {
        title: 'Actions',
        key: 'action',
        width: 20,
        align: 'center',
        render: (v) => {
          return (
            <Button
              type="link"
              onClick={() => {
                this.props.onItemViewClicked(v.paymentKey);
              }}
            >
              View
              <EyeTwoTone />
            </Button>
          );
        },
      },
    ];
  }

  render() {
    const onChange = (value) => {
      const offset = value.current === 1 ? 0 : (value.current - 1) * 10;
      this.props.onPaginate({ offset, limit: value.pageSize });
    };
    const { data, isLoading } = this.props;

    console.log(data);
    return data && data.results ? (
      <Table
        loading={isLoading}
        // rowSelection={{
        //   type: 'checkbox',
        //   ...this.rowSelection,
        // }}
        className="product-grid"
        pagination={{
          current: this.props.data.currentPage,
          total: this.props.data.totalResults,
        }}
        onChange={onChange}
        columns={this.columns}
        rowKey={(record) => record.id}
        dataSource={data.results}
      />
    ) : (
      <Row align="middle" justify="center">
        <Col>
          <Spin size="large" tip="loading table data.." />
        </Col>
      </Row>
    );
  }
}

OrdersGrid.propTypes = {
  data: PropType.object,
  onItemViewClicked: PropType.func,
  isLoading: PropType.bool,
  getProducts: PropType.func,
  onPaginate: PropType.func,
};

export default hot(module)(OrdersGrid);
