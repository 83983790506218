import {
  RECEIVE_BANK_ACCOUNT_DATA,
  SET_LOADER,
  SET_MERCHANT_MOBILE_UPDATED,
  SET_MERCHANT_OTP_ID,
  SET_MERCHANT_OTP_VERIFICATION,
} from '../constants/actionTypes';
import getHttpClient from '../apis/httpclient';
import { Modal, notification } from 'antd';
import { fetchUserInfo } from './commonActions';
import { getErrorMessageFromResponse } from '../utils/actionHelpers';

const bankUpdateSuccess = () => {
  Modal.success({
    title:
      'Bank details has been updated successfully.\n' +
      'Changes will be applicable after the\n' +
      'approval of the admin team.',
    centered: true,
  });
};

const commercialDocsUpdateSuccess = () => {
  Modal.success({
    title:
      'Commercial documents have been updated successfully.\n' +
      'Changes will be applicable after the\n' +
      'approval of the admin team.',
    centered: true,
  });
};

const contactUpdateSuccess = () => {
  Modal.success({
    title:'Mobile number has been updated successfully.',
    centered: true,
  });
};

const profileUpdateSuccess = () => {
  Modal.success({
    title:'Profile details has been updated successfully.',
    centered: true,
  });
};

export function getBankAccountInfo(id) {
  console.log('called');

  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .get(`/merchant/bankFinancial/${id}`)
      .then((res) => {
        if (res.data.error) {
          console.error(res.error);
        }
        if (res.data.success) {
          dispatch({ type: RECEIVE_BANK_ACCOUNT_DATA, payload: res.data.data });
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}
export function verifyOtpMobile(values) {
  console.log(values);

  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .post('/otp/verify', values)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.otpId
        ) {
          dispatch({
            type: SET_MERCHANT_OTP_ID,
            payload: response.data.data.otpId,
          });
        } else {
          return Promise.reject({
            message: getErrorMessageFromResponse(response),
          });
        }
      })
      .catch((error) => {
        console.log(error);
        notification.error({
          message: getErrorMessageFromResponse(error ? error.response : null),
        });
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}
export function verifyOtp(values) {
  console.log(values);

  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .post('/otp/submitPin', values)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        dispatch({ type: SET_MERCHANT_OTP_VERIFICATION, payload: true });
      })
      .catch((error) => {
        console.log(error.response);
        notification.error({
          message: getErrorMessageFromResponse(error ? error.response : null),
        });
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function updateContactNumber(values) {
  console.log(values);

  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .put(`/merchant/contactDetails`, values)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        dispatch({
          type: SET_MERCHANT_OTP_ID,
          payload: null,
        });
        dispatch({ type: SET_MERCHANT_MOBILE_UPDATED, payload: true });
        dispatch({ type: SET_MERCHANT_OTP_VERIFICATION, payload: false });
        dispatch({ type: SET_MERCHANT_OTP_ID, payload: null });
        contactUpdateSuccess();
        dispatch(fetchUserInfo());
      })
      .catch((error) => {
        console.log(error);
        // notification.error({ message: error.message });
        notification.error({ message: 'Failed to update mobile number.' });
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function updateBankAccount(bankAccountId, formData) {
  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .put(`/merchant/bankAccount/${bankAccountId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        bankUpdateSuccess();
      })
      .catch((error) => {
        notification.error({
          message: getErrorMessageFromResponse(error ? error.response : null),
        });
        console.log(error);
      })
      .finally(() => {
        dispatch(getBankAccountInfo(bankAccountId));
        dispatch(fetchUserInfo());
      });
  };
}
export function updateFinancialDetails(formData) {
  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .put(`/merchant/financialDetails`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log(JSON.stringify(response.data));
        commercialDocsUpdateSuccess();
      })
      .catch(function (error) {
        notification.error({
          message: getErrorMessageFromResponse(error ? error.response : null),
        });
        console.log(error);
      })
      .finally(() => {
        dispatch(fetchUserInfo());
      });
  };
}
export function updateContactDetails(merchantId, values) {
  console.log(values);

  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .put(`/merchant/${merchantId}/contactDetails`, values)
      .then(() => {
        dispatch({
          type: SET_MERCHANT_MOBILE_UPDATED,
          payload: true,
        });
      })
      .catch((error) => {
        console.log(error.response);
        notification.error({
          message: getErrorMessageFromResponse(error ? error.response : null),
        });
      })
      .finally(() => {
        dispatch(fetchUserInfo());
      });
  };
}
export function resetOTPState() {
  return (dispatch) => {
    dispatch({ type: SET_MERCHANT_OTP_VERIFICATION, payload: false });
    dispatch({
      type: SET_MERCHANT_OTP_ID,
      payload: null,
    });
    dispatch({
      type: SET_MERCHANT_MOBILE_UPDATED,
      payload: false,
    });
  };
}

export function updateMerchantProfile(formData) {
  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .put(`/merchant/profile`, formData)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        profileUpdateSuccess();
        dispatch(fetchUserInfo());
      })
      .catch((error) => {
        console.log(error);
        notification.error({ message: 'Failed to update user profile.' });
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}  
