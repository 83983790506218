import { ContactsOutlined, ExclamationCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tabs,
  Tooltip,
  Typography,
  Upload
} from 'antd';
import PropType from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as moment from 'moment'
import * as merchantActions from '../../actions/merchantActions';
import * as commonActions from '../../actions/commonActions';
import * as provisioningActions from '../../actions/provisioningActions';
import { BASE_URL, MAX_IMAGEUPLOAD } from '../../constants/config';
import { ProfileState } from '../../constants/enums';
import {
  accountNameValidator,
  accountNumberValidator,
  mobileNumberValidator,
  natureOfBusinessValidator,
  natureOfBusinessOtherValidator
} from '../../utils/inputValidators';
import LandingPage from '../LandingPage';
import ProvisionMobileVerification from '../provisioning/ProvisionMobileVerification';
import { showErrorModal } from '../widgets/ErrorModal';
import ThumbWithDeleteAction from '../widgets/ThumbWithDeleteAction';
import natureData from '../../data/natureofbusiness';

const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

class BankDetailsPage extends React.Component {
  constructor() {
    super();
    this.formRef = React.createRef();
    this.financialDocumentsForm = React.createRef();
    this.mobileNumberForm = React.createRef();
    this.validateMessages = {
      required: '',
      pattern: {
        mismatch: 'Please enter a valid character !',
      },
      max: 'Exceeded maximum allowed character count',
      min: 'Input character count is less than the allowed',
    };
    this.state = {
      disabledFields: {
        bank: true,
        branch: true,
        accountNo: true,
        accountName: true,
        mobile: true,
        passbookProofs: true,
        financialDocuments: true,
        businessName: true
      },
      bankInfo: null,
      financialInfo: null,
      contactInfo: null,
      updatedBankDetails: { deletePassbookProofsIds: [] },
      updatedFinancialDetails: {
        deleteBusinessRegistrationDocs: [],
        deleteBusinessLogoImage: null,
        businessName: '',
        otherNatureOfBusiness:'',
        natureOfBusiness:[],
        nicNumber: '',
        brNumber: '',
      },
      otpFormVisible: false,
      isNicNumberExists: false,
      isBrNumberExists: false,


    };
  }

  componentDidMount() {

    this.props.merchantActions.getBankAccountInfo(
      this.props.user.bankAccounts[0].id,
    );
    this.props.actions.getBranches(this.props.user.bankAccounts[0].bankCode);
    if (!(this.props.bankList && this.props.bankList.length > 0)) {
      this.props.actions.getBanks();
    }

  }

  componentDidUpdate(){
    console.log("check state",this.state);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !this.props.otpVerified &&
      nextProps.otpVerified &&
      this.state.mobileNumber
    ) {
      this.props.merchantActions.updateContactNumber({
        mobile: `${this.state.mobileNumber.substring(1)}`,
      });
    }
    if (!this.props.mobileUpdated && nextProps.mobileUpdated) {
      this.resetOtpForm();
    }

    if (nextProps && nextProps.bankAccount) {
      const contactInfo = {
        mobile: nextProps.user.mobile.substring(2),
      };

      const bankInfo = {
        bank: nextProps.bankAccount.bankCode,
        bankName: nextProps.bankAccount.bankName,
        branch: nextProps.bankAccount.branchCode,
        branchName: nextProps.bankAccount.branchName,
        accountNumber: nextProps.bankAccount.accountNumber,
        accountHolderName: nextProps.bankAccount.accountHolderName,
        passbookProofs: [],
        passbookProofsLength: 0,
      };

      if (
        nextProps.bankAccount.passbookProofsIds &&
        nextProps.bankAccount.passbookProofsIds.length > 0
      ) {
        bankInfo.passbookProofsLength = nextProps.bankAccount.passbookProofsIds.length;
        for (
          let i = 0;
          i < nextProps.bankAccount.passbookProofsIds.length;
          i++
        ) {
          bankInfo.passbookProofs.push({
            id: nextProps.bankAccount.passbookProofsIds[i],
            url: `${BASE_URL}/resources?resourceId=${nextProps.bankAccount.passbookProofsIds[i]}&isOriginal=false`,
          });
        }
      }

      const financialInfo = {
        brDocuments: [],
        brDocsLength: null,
        logo: null,
        businessName: '',
        otherNatureOfBusiness:'',
        natureOfBusiness: [],
        nicNumber: '',
        brNumber: '',
        docType: []
      };

      if (nextProps.user.businessName) {
        financialInfo.businessName = nextProps.user.businessName
      }
      if (nextProps.user.nicNumber) {
        financialInfo.nicNumber = nextProps.user.nicNumber;
        financialInfo.docType.push('nic');
      }
      if (nextProps.user.brNumber) {
        financialInfo.brNumber = nextProps.user.brNumber;
        financialInfo.docType.push('br');
      }
      if (nextProps.user.businessLogoId) {
        financialInfo.logo = {
          id: nextProps.user.businessLogoId,
          url: `${BASE_URL}/resources?resourceId=${nextProps.user.businessLogoId}&isOriginal=false`,
        };
      }
      if (
        nextProps.user.businessRegistrationDocumentsIds &&
        nextProps.user.businessRegistrationDocumentsIds.length > 0
      ) {
        financialInfo.brDocsLength = nextProps.user.businessRegistrationDocumentsIds.length;
        for (
          let i = 0;
          i < nextProps.user.businessRegistrationDocumentsIds.length;
          i++
        ) {
          financialInfo.brDocuments.push({
            id: nextProps.user.businessRegistrationDocumentsIds[i],
            url: `${BASE_URL}/resources?resourceId=${nextProps.user.businessRegistrationDocumentsIds[i]}&isOriginal=false`,
          });
        }
      }

      if(nextProps.user.natureOfBusinesses.length > 0){
        for(let i=0;i < nextProps.user.natureOfBusinesses.length;i++){
          financialInfo.natureOfBusiness.push(nextProps.user.natureOfBusinesses[i].nature);
        }
      }

      //this.state.financialInfo.natureOfBusiness.
      // filter((a)=>a.includes("Other-"))[0].replace('Other-','')

      if(nextProps.user.natureOfBusinesses.length > 0 && nextProps.user.natureOfBusinesses.
        filter((a) => a.nature.includes("Other-")).length > 0){
          financialInfo.otherNatureOfBusiness = nextProps.user.natureOfBusinesses.
          filter((a) => a.nature.includes("Other-"))[0].nature.replace('Other-','');
          console.log("finacial",nextProps.user.natureOfBusinesses.
          filter((a) => a.nature.includes("Other-")))
        }

      this.setState({ bankInfo, financialInfo, contactInfo });
    }

    //validation on nicNumber duplicate
    if((!this.props.docTypeValNic && nextProps.docTypeValNic) ||  (this.props.docTypeValNic && this.props.docTypeValNic !== nextProps.docTypeValNic)){
      if(nextProps.docTypeValNic.data.index !== -1 && this.state.updatedFinancialDetails.nicNumber !== this.state.financialInfo.nicNumber){
        this.setState({
            ...this.state,
            isNicNumberExists: true,
          }, function(){
            this.financialDocumentsForm.current.validateFields();
          })
      }else{
        this.setState({
          ...this.state,
          isNicNumberExists: false,
        }, function(){
            this.financialDocumentsForm.current.validateFields();
        })
      }
    }
    //validation on br Number duplicate
    if((!this.props.docTypeValBr && nextProps.docTypeValBr) ||  (this.props.docTypeValBr && this.props.docTypeValBr !== nextProps.docTypeValBr)){
      if(nextProps.docTypeValBr.data.index !== -1 && this.state.updatedFinancialDetails.brNumber !== this.state.financialInfo.brNumber){
        this.setState({
          ...this.state,
          isBrNumberExists: true,
        }, function(){
          this.financialDocumentsForm.current.validateFields(["brNumber"]);
        })
      }else{
        this.setState({
          ...this.state,
          isBrNumberExists: false,
        }, function(){
          this.financialDocumentsForm.current.validateFields(["brNumber"]);
        })
      }
    }
  }

  onSelectChangeNature = (value) => {
    this.setState((state) => ({
      financialInfo: {
        ...state.financialInfo,
        natureOfBusiness: value
      },
    }));
  }

  onSelectChangeDocType = (value) => {
    this.setState((state) => ({
      financialInfo: {
        ...state.financialInfo,
        docType: value
      },
    }));
  }

  toggleFieldDisability(fieldId) {
    let field = null;
    switch (fieldId) {
      case 0: // bank
        field = 'bank';
        break;
      case 1: // branch
        field = 'branch';
        break;
      case 2: // acc#
        field = 'accountNo';
        break;
      case 3: // accName
        field = 'accountName';
        break;
      case 4: // mobile#
        field = 'mobile';
        break;
      case 5: // passbookProofs#
        field = 'passbookProofs';
        break;
      case 6: // financialDocuments#
        field = 'financialDocuments';
      case 7: // financialDocuments#
        field = 'businessName';
        break;
    }

    if (field) {
      this.setState((state) => {
        const r = { ...state.disabledFields };
        r[field] = false;
        return {
          disabledFields: r,
        };
      });
    } else {
      console.log(`ignored toggleFieldDisability event: fieldId=> ${fieldId}`);
    }
  }

  onBankFormValuesChanged(changedVals, allVals) {
    this.setState((state) => ({
      updatedBankDetails: { ...state.updatedBankDetails, ...changedVals },
    }));
    console.log(allVals);
  }
  onMobileNumberFormValuesChanged(changedVals, allVals) {
    this.setState((state) => ({
      ...state,
      ...changedVals,
    }));
    console.log(allVals);
    this.props.merchantActions.resetOTPState();
  }

  onFinancialFormValuesChanged(changedVals, allVals) {
    this.toggleFieldDisability(6);
    this.setState((state) => ({
      updatedFinancialDetails: {
        ...state.updatedFinancialDetails,
        ...changedVals,
      },
    }));
    console.log(allVals);
  }

  onBankProofItemDeletePressed = (proof) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      centered: true,
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.toggleFieldDisability(5);
        let tempPassbookProofs = [...this.state.bankInfo.passbookProofs];
        let index = tempPassbookProofs.indexOf(proof);
        if (index !== -1) {
          tempPassbookProofs.splice(index, 1);
          console.log('temp = ', tempPassbookProofs);
        }
        this.setState((state) => ({
          //TODO has to remove the removed thumbnails from the view
          // ...state.bankInfo,
          // passbookProofs: state.bankInfo.passbookProofs.filter(
          //   (item) => item.id !== proof.id,
          // ),
          updatedBankDetails: {
            ...state.updatedBankDetails,
            deletePassbookProofsIds:
              state.updatedBankDetails.deletePassbookProofsIds.length > 0
                ? [
                  ...state.updatedBankDetails.deletePassbookProofsIds,
                  proof.id,
                ]
                : [proof.id],
          },
          bankInfo: {
            ...state.bankInfo,
            passbookProofs: tempPassbookProofs
          }
        }));
      },
      onCancel: () => {
        console.log('Cancel');
      },
    });
  };

  onBrDocumentItemDeletePressed = (document) => {
    console.log('document = ', document);
    console.log('state  = ', this.state);
    Modal.confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      centered: true,
      cancelText: 'No',
      onOk: () => {
        this.toggleFieldDisability(6);

        let brDocsTemp = [...this.state.financialInfo.brDocuments];
        let index = brDocsTemp.indexOf(document);
        if (index !== -1) {
          brDocsTemp.splice(index, 1);
        }
        this.setState((state) => ({
          //TODO has to remove the removed thumbnails from the view
          updatedFinancialDetails: {
            ...state.updatedFinancialDetails,
            deleteBusinessRegistrationDocs:
              state.updatedFinancialDetails.deleteBusinessRegistrationDocs
                .length > 0
                ? [
                  ...state.updatedFinancialDetails
                    .deleteBusinessRegistrationDocs,
                  document.id,
                ]
                : [document.id],
          },
          financialInfo: {
            ...state.financialInfo,
            brDocuments: brDocsTemp
          }
        }));
      },
      onCancel: () => {
        console.log('Cancel');
      },
    });
  };

  onLogoItemDeletePressed = (logo) => {
    console.log(logo);
    console.log(this.state);
    Modal.confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      centered: true,
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.toggleFieldDisability(6);
        this.setState((state) => ({
          //TODO has to remove the removed thumbnails from the view
          updatedFinancialDetails: {
            ...state.updatedFinancialDetails,
            deleteBusinessLogoImage: logo.id,
          },
          financialInfo: {
            ...state.financialInfo,
            logo: ''
          }
        }));
      },
      onCancel: () => {
        console.log('Cancel');
      },
    });
  };

  onBankDetailUpdate() {
    this.formRef.current
      .validateFields()
      .then(() => {
        const formData = new FormData();
        const updatedData = this.state.updatedBankDetails;
        const existingData = this.state.bankInfo;
        // validation if all photos are removed and none being uploaded
        const passbookProofsFileLength =
          updatedData.passbookproofs && updatedData.passbookproofs.fileList
            ? updatedData.passbookproofs.fileList.length
            : 0;
        if (
          updatedData.deletePassbookProofsIds.length >=
          existingData.passbookProofsLength + passbookProofsFileLength
        ) {
          showErrorModal(
            'At least one proof(photo) of the passbook should be available!',
          );
          throw Error;
        }
        console.log(updatedData);
        console.log(existingData);
        if (this.state.updatedBankDetails.bankCode) {
          formData.append('bankName', updatedData.bankName);
          formData.append('bankCode', updatedData.bankCode);
        } else {
          formData.append('bankName', existingData.bankName);
          formData.append('bankCode', existingData.bank);
        }
        if (updatedData.branchCode) {
          formData.append('branchName', updatedData.branchName);
          formData.append('branchCode', updatedData.branchCode);
        } else {
          formData.append('branchName', existingData.branchName);
          formData.append('branchCode', existingData.branch);
        }
        formData.append(
          'accountNumber',
          updatedData.accountnumber || existingData.accountNumber,
        );
        formData.append(
          'accountHolderName',
          updatedData.accountholdername || existingData.accountHolderName,
        );
        formData.append(
          'deletePassbookProofsIds',
          updatedData.deletePassbookProofsIds,
        );
        if (passbookProofsFileLength > 0) {
          for (let i = 0; i < updatedData.passbookproofs.fileList.length; i++) {
            const f = updatedData.passbookproofs.fileList[i];
            formData.append(`passbookProofs`, f.originFileObj);
          }
        } else {
          formData.append(`passbookProofs`, []);
        }
        this.formRef.current.resetFields(['passbookproofs']);
        // const notficationData = {
        //   type: 'merchant',
        //   message: `New bank account update request has been submitted by ${this.props.user.userName}`,
        // }
        // this.props.commonActions.notificationmsg(notficationData);
        this.props.merchantActions.updateBankAccount(
          this.props.user.bankAccounts[0].id,
          formData,
        );
        this.setState({
          disabledFields: {
            bank: true,
            branch: true,
            accountNo: true,
            accountName: true,
            mobile: true,
            passbookProofs: true,
            businessRegistrationDocuments: true,
            businessLogoImage: true,
          },
          updatedBankDetails: { deletePassbookProofsIds: [] },
        });
      })
      .catch((e) => console.log(e));
  }

  onFinancialDocumentUpdate() {
    this.financialDocumentsForm.current
      .validateFields()
      .then(() => {
          const formData = new FormData();

          const updatedData = this.state.updatedFinancialDetails;
          const existingData = this.state.financialInfo;
          const businessDocsFileLength =
            updatedData.businessdocuments && updatedData.businessdocuments.fileList
              ? updatedData.businessdocuments.fileList.length
              : 0;

          // validation to remove existing logo to upload new
          if (
            !updatedData.deleteBusinessLogoImage &&
            updatedData.businesslogo &&
            updatedData.businesslogo.file
          ) {
            showErrorModal('You need to remove the existing logo to replace!');
            return;
          }
          // validation if all br documents are deleted and none being uploaded
          else if (
            updatedData.deleteBusinessRegistrationDocs.length >=
            existingData.brDocsLength + businessDocsFileLength
          ) {
            showErrorModal(
              'At least one business registration document is needed!',
            );

            return;
          }
          // validation if the business logo is deleted and none being uploaded
          else if (!updatedData.businesslogo && updatedData.deleteBusinessLogoImage) {
            showErrorModal('Business logo/image is mandatory!');
            return;
          }
          formData.append(
            'deleteBusinessRegistrationDocumentsIds',
            updatedData.deleteBusinessRegistrationDocs,
          );
          formData.append(
            'deleteBusinessLogosId',
            updatedData.deleteBusinessLogoImage,
          );
          if (businessDocsFileLength > 0) {
            for (let i = 0; i < businessDocsFileLength; i++) {
              const f = updatedData.businessdocuments.fileList[i];
              formData.append(`businessRegistrationDocuments`, f.originFileObj);
            }
          } else {
            formData.append(`businessRegistrationDocuments`, []);
          }

          formData.append(
            'businessLogo',
            (updatedData.businesslogo && updatedData.businesslogo.file
              ? updatedData.businesslogo.file
              : null) || null,
          );
          console.log("updated data",updatedData);
          console.log("Existing data",existingData);
          if(updatedData.otherNatureOfBusiness){
            const otherNatureOfBusiness = "Other-"+updatedData.otherNatureOfBusiness;

            console.log("updatedData.natureOfBusiness",updatedData.natureOfBusiness)
            if(updatedData.natureOfBusiness.length == 0){
              const natureOfBusinessExisting = Array.prototype.map.call(existingData.natureOfBusiness.
                filter((a)=>!a.includes("Other-")),
                 function(item) {
                    return updatedData.natureOfBusiness.push(item);
                  });
                console.log("natureOfBusinessUpdated",updatedData.natureOfBusiness)
            }
            updatedData.natureOfBusiness.push(otherNatureOfBusiness);
            console.log("updatedData.natureOfBusiness",updatedData.natureOfBusiness)
            formData.append('natureOfBusiness', updatedData.natureOfBusiness);

          }else if(updatedData.natureOfBusiness.length > 0){

            const otherNatureExisting = Array.prototype.map.call(updatedData.natureOfBusiness.
              filter((a)=>a.includes("Other")),
               function(item) {
                  return item;
                }
                );
                console.log("otherNatureExisting",otherNatureExisting);
            if(!updatedData.otherNatureOfBusiness && otherNatureExisting.length > 0){
              updatedData.natureOfBusiness.push(existingData.otherNatureOfBusiness);
              console.log("updatedData.natureOfBusiness",updatedData.natureOfBusiness);
              formData.append('natureOfBusiness', updatedData.natureOfBusiness);
            }else{
              formData.append('natureOfBusiness', updatedData.natureOfBusiness);
            }

          }
          if (updatedData.businessName) {
            formData.append(`businessName`,updatedData.businessName);
          }
          if (updatedData.nicNumber) {
            formData.append(`nicNumber`,updatedData.nicNumber);
          }
          if (updatedData.brNumber) {
            formData.append(`brNumber`,updatedData.brNumber);
          }
          if(updatedData.documentType && updatedData.documentType.length == 1){
            if(updatedData.documentType[0] === 'nic'){
                formData.append(`brNumber`, '');
            }
            else{
                formData.append(`nicNumber`, '');
            }
            console.log('doc type change has happend');
          }
          // if(updatedData.docType && !updatedData.docType.includes('nic')){
          //   formData.append(`nicNumber`,'');
          // }
          // if(updatedData.docType && !updatedData.docType.includes('br')){
          //   formData.append(`brNumber`,'');
          // }

        // socket.emit('msgToServer', {
        //   text: `Document update request send by the merchant`,
        // });
        // const notficationData = {
        //   type: 'merchant',
        //   message: `New Commercial details update request has been submitted by ${this.props.user.userName}`,
        // }
        // this.props.commonActions.notificationmsg(notficationData);
          this.props.merchantActions.updateFinancialDetails(formData);
          this.financialDocumentsForm.current.resetFields();
          this.setState((state) => ({
            disabledFields: {
              ...state.disabledFields,
              financialDocuments: true,
              businessName: true,
              nicNumber: true,
              brNumber: true
            },
            updatedFinancialDetails: {
              deleteBusinessRegistrationDocs: [],
              deleteBusinessLogoImage: null,
            },
            financialInfo: {
              ...state.financialInfo,
              natureOfBusiness: [],
            },
          }));
        })
        .catch((e) => console.log(e));
  }

  onMobileNumberUpdate() {
    this.mobileNumberForm.current
      .validateFields()
      .then(() => {
        this.setState((state) => ({
          ...state,
          otpFormVisible: true,
        }));
      })
      .catch((e) => console.log(e));
  }

  resetOtpForm = () => {
    this.props.merchantActions.resetOTPState();
    this.setState((state) => ({
      ...state,
      disabledFields: {
        ...state.disabledFields,
        mobile: false,
      },
      otpFormVisible: false,
      mobileNumber: null,
    }));
  };

  renderMobileOtpVerificationForm() {
    if (this.state.mobileNumber && this.state.otpFormVisible) {
      const onSendPressed = () => {
        this.props.merchantActions.verifyOtpMobile({
          mobile: this.state.mobileNumber.substring(1),
          appPath: 'MERCHANT_CONTACT_UPDATE_VERIFICATION',
          referenceName: 'keycloakId',
          referenceId: this.props.user.keycloakId
        });
      };
      const onVerifyPressed = (pin) => {
        // this.props.merchantActions.verifyOtp({
        //   pin: Number(pin),
        //   otpId: this.props.otpId,
        // });
        if (!this.props.mobileUpdated) {
          this.props.merchantActions.updateContactNumber({
            mobile: `${this.state.mobileNumber.substring(1)}`,
            otpId: this.props.otpId,
            pin: Number(pin),
          });
        } else {
          this.resetOtpForm();
        }
      };
      const onCancel = () => {
        if (!this.props.isLoading) {
          Modal.confirm({
            okText: 'Yes',
            cancelText: 'No',
            title: 'Are you sure you want to cancel the verification?',
            onOk: () => {
              this.props.merchantActions.resetOTPState();
              this.setState((state) => ({
                ...state,
                otpFormVisible: false,
              }));
            },
          });
        }
      };
      const onCompletePressed = () => {
        if (!this.props.mobileUpdated) {
          this.props.merchantActions.updateContactNumber({
            mobile: `${this.state.mobileNumber.substring(1)}`,
          });
        } else {
          this.resetOtpForm();
        }
      };

      return (
        <ProvisionMobileVerification
          currentMobileNumber={this.state.mobileNumber}
          onSendPressed={onSendPressed}
          onVerifyPressed={onVerifyPressed.bind(this)}
          onCompletePressed={onCompletePressed}
          onCancel={onCancel}
          otpId={this.props.otpId}
          otpVerified={this.props.otpVerified}
          isLoadingData={this.props.isLoading}
        />
      );
    }
  }

  onSelectChangeDocValue (type) {
    this.props.actions.checkdocTypeValExists(type, this.financialDocumentsForm.current.getFieldValue([type]));
  }

  render() {
    const stateAttributes = this.state;
    const notApproved =
      this.props.bankAccount &&
      this.props.bankAccount.state !== ProfileState.ACTIVE &&
      this.props.bankAccount.state !== 'inactive';
    const rejected = this.props.bankAccount && this.props.bankAccount.state == 'inactive';
    const merchantRejected = this.props.user.profileState && (this.props.user.profileState === ProfileState.REJECTED || this.props.user.profileState === ProfileState.DECLINED);
    const merchantModified = this.props.user.profileState && this.props.user.profileState === ProfileState.MODIFIED;
    return this.props.bankAccount && this.state.bankInfo ? (
      <div className="dashboard-subpage-container">
        <div className="provision-content-container">
          <Title level={3}>Bank & Commercial Details</Title>
          <Tabs defaultActiveKey="1" size="large" style={{ marginBottom: 32 }}>
            <TabPane tab="Bank Details" key="1">
              {notApproved && (
                <Alert
                  style={{ width: 'fit-content', marginBottom: '15px' }}
                  message={
                    <span>
                      <b>Awaiting approval..!</b><br />
                      Your bank details update is under pending approval. You will be notified via SMS, once your request has been approved.
                    </span>
                  }
                  type="warning"
                  showIcon
                />
              )}
              {rejected && (
                <Alert
                  style={{ width: 'fit-content', marginBottom: '15px' }}
                  message={
                    <span>
                      {/* <b>Bank account update rejected!</b><br/> */}
                      Your bank account update has been rejected by the admin due to below reason. Please update accordingly.<br />
                      <b><i>{this.props.bankAccount.remarks}</i></b>
                    </span>
                  }
                  type="error"
                  showIcon
                />
              )}
              <Form
                ref={this.formRef}
                initialValues={{
                  bankCode: `${this.state.bankInfo.bankName} [${this.state.bankInfo.bank}]`,
                  branchCode: this.state.bankInfo.branchName,
                  accountnumber: this.state.bankInfo.accountNumber,
                  accountholdername: this.state.bankInfo.accountHolderName,
                }}
                validateMessages={this.validateMessages}
                layout="vertical"
                className="limited-width-form-container"
                onFinish={(formData) => {
                  this.formRef.current
                    .validateFields()
                    .then(() => {
                      this.props.actions.updateBankInfo(
                        Object.assign({}, formData, this.state),
                      );
                    })
                    .catch((er) => {
                      console.log(er);
                    });
                }}
                onValuesChange={this.onBankFormValuesChanged.bind(this)}
              >
                <Form.Item
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                  label="*Bank name"
                  name="bankCode"
                  onClick={() => this.toggleFieldDisability(0)}
                >
                  <Select
                    showSearch={true}
                    placeholder="-select-"
                    optionFilterProp="children"
                    disabled={notApproved}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={(v) => {
                      this.formRef.current.setFieldsValue({ branchCode: null });
                      this.setState((state) => ({
                        updatedBankDetails: {
                          ...state.updatedBankDetails,
                          bankName: this.props.bankList.find(
                            (x) => x.bankCode === v,
                          ).bankName,
                        },
                      }));
                      this.props.actions.getBranches(v);
                    }}
                  >
                    {this.props.bankList
                      ? Object.keys(this.props.bankList).map((k) => (
                        <Option
                          key={this.props.bankList[k].bankCode}
                          value={this.props.bankList[k].bankCode}
                          title={`${this.props.bankList[k].bankName} [${this.props.bankList[k].bankCode}]`}
                        >{`${this.props.bankList[k].bankName} [${this.props.bankList[k].bankCode}]`}</Option>
                      ))
                      : null}
                  </Select>
                </Form.Item>
                <Form.Item
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                  label="*Branch name"
                  name="branchCode"
                  onClick={() => this.toggleFieldDisability(1)}
                >
                  <Select
                    showSearch={true}
                    placeholder="-select-"
                    optionFilterProp="children"
                    loading={this.props.isLoading}
                    disabled={
                      this.props.isLoading ||
                      notApproved
                    }
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={(v) => {
                      this.setState((state) => ({
                        updatedBankDetails: {
                          ...state.updatedBankDetails,
                          branchName: this.props.branchList.find(
                            (x) => x.branchCode === v,
                          ).branchName,
                        },
                      }));
                    }}
                  >
                    {this.props.branchList
                      ? Object.keys(this.props.branchList).map((k) => (
                        <Option
                          key={this.props.branchList[k].branchCode}
                          value={this.props.branchList[k].branchCode}
                          title={this.props.branchList[k].branchName}
                        >{`${this.props.branchList[k].branchName} [${this.props.branchList[k].branchCode}]`}</Option>
                      ))
                      : null}
                  </Select>
                </Form.Item>
                <Form.Item
                  // rules={accountNumberValidator()}
                  rules={[
                    // {
                    //   required: true,
                    // },
                    ...accountNumberValidator(),
                  ]}
                  label="*Account number"
                  name="accountnumber"
                  onClick={() => this.toggleFieldDisability(2)}
                >
                  <Input
                    placeholder="XXXXXXXXXXXXXXXXX"
                    disabled={
                      notApproved
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="*Account holder's name"
                  name="accountholdername"
                  rules={[
                    // {
                    //   required: true,
                    // },
                    ...accountNameValidator(),
                  ]}
                  onClick={() => this.toggleFieldDisability(3)}
                >
                  <Input
                    disabled={
                      notApproved
                    }
                  />
                </Form.Item>

                <Form.Item
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                  label={
                    <>
                      *Proofs(Photos) of the passbook &nbsp;
                      <Tooltip
                        placement="top"
                        title={
                          'Please upload a copy of bank passbook or a bank confirmation letter which shows bank name, ' +
                          'bank code, branch name, branch code, account number and account holder’s name.'
                        }
                      >
                        <InfoCircleFilled />
                      </Tooltip>
                    </>
                  }
                  name="passbookproofs"
                  valuePropName="passbookproofs"
                >
                  <Upload
                    disabled={notApproved}
                    className="sp-file-upload"
                    accept=".jpg,.png,.pdf"
                    multiple
                    listType="picture"
                    beforeUpload={(file) => {
                      if (file.size > 4194304) {
                        return Promise.reject(
                          showErrorModal('Maximum size exceeded!'),
                        );
                      }
                      if (
                        this.formRef.current.getFieldValue('passbookproofs') &&
                        this.state.bankInfo.passbookProofs &&
                        (this.formRef.current.getFieldValue('passbookproofs')
                          .fileList.length + this.state.bankInfo.passbookProofs.length) >
                        MAX_IMAGEUPLOAD - 1
                      ) {
                        return Promise.reject(
                          showErrorModal(
                            `Only ${MAX_IMAGEUPLOAD} items are allowed!`,
                          ),
                        );
                      } else {
                        this.toggleFieldDisability(5);
                        return false;
                      }
                    }}
                  >
                    <Input disabled placeholder="<4MB,PNG,JPEG,PDF" />
                    <Button>Browse</Button>
                  </Upload>
                </Form.Item>
                <Form.Item>
                  <Row>
                    {this.state.bankInfo.passbookProofs.map((proof, idx) => (
                      <Col key={idx} className="deletable-thumb-container">
                        <ThumbWithDeleteAction
                          thumb={proof}
                          onActionTriggered={
                            notApproved
                              ? () => void 0
                              : this.onBankProofItemDeletePressed
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Button
                    className="sp-primary"
                    onClick={this.onBankDetailUpdate.bind(this)}
                    disabled={
                      (this.state.disabledFields.bank &&
                        this.state.disabledFields.branch &&
                        this.state.disabledFields.accountNo &&
                        this.state.disabledFields.accountName &&
                        this.state.disabledFields.passbookProofs) ||
                      notApproved
                    }
                  >
                    Update Bank Details
                  </Button>
                </Form.Item>
              </Form>
            </TabPane>
            <TabPane tab="Commercial Details & Documents" key="2">
              {merchantModified && (
                <Alert
                  style={{ width: 'fit-content', marginBottom: '15px' }}
                  message={
                    <span>
                      <b>Awaiting approval..!</b><br />
                        Your commercial documents/details update is under pending approval.You will be notified via SMS, once your request has been approved.
                      </span>
                  }
                  type="warning"
                  showIcon
                />
              )}
              {merchantRejected && (
                <Alert
                  style={{ width: 'fit-content', marginBottom: '15px' }}
                  message={
                    <span>
                      Your commercial document update has been rejected/declined by the admin due to below reason. Please update accordingly.<br />
                      <b><i>{this.props.user.remarks}</i></b>
                    </span>
                  }
                  type="error"
                  showIcon
                />
              )}
              <Form
                ref={this.financialDocumentsForm}
                initialValues={{
                  // otherNatureOfBusiness: this.state.financialInfo.natureOfBusiness.
                  // filter((a)=>a.includes("Other-")).length > 0 ? this.state.financialInfo.natureOfBusiness.
                  // filter((a)=>a.includes("Other-"))[0].replace('Other-','') : null,
                  natureOfBusiness: Array.prototype.map.call(this.state.financialInfo.natureOfBusiness.
                    filter((a)=>!a.includes("Other-")),
                    function(item){
                      return item;
                    }),
                  documentType: this.state.financialInfo.docType
                }}
                validateMessages={this.validateMessages}
                layout="vertical"
                className="limited-width-form-container"
                onValuesChange={this.onFinancialFormValuesChanged.bind(this)}
              >
                <Form.Item
                  label={
                    <>
                      *Business name &nbsp;
                      <Tooltip
                        placement="top"
                        title={
                          'Business name will be visible to your customers'
                        }
                      >
                        <InfoCircleFilled />
                      </Tooltip>
                    </>
                  }
                  name="businessName"
                  initialValue={this.state.financialInfo.businessName}
                  rules={[
                    // {
                    //   required: true,
                    // },
                    () => ({
                      validator(rule, value) {
                        if (!value || !(value.trim())) {
                          return Promise.reject('You can’t keep this empty');
                        } else if (value.length < 3) {
                          return Promise.reject('Business name is too short');
                        } else if (value && value.length > 30) {
                          return Promise.reject(
                            'Business name is too lengthy',
                          );
                        }
                        // else if (
                        //   !RegExp('^\\b(?!.*?\\s{2})[A-Za-z ]{0,25}\\b$').test(value)
                        // ) {
                        //   return Promise.reject('Please enter a valid character');
                        // }
                        else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                  onClick={() => this.toggleFieldDisability(7)}
                >
                  <Input
                    disabled={this.props.isLoading || merchantModified}
                    placeholder="Type your Business name"
                  />
                </Form.Item>
                <Form.Item
                  label="*Document type"
                  name="documentType"
                  rules={[
                    // {
                    //   required: true,
                    // },
                    () => ({
                      validator(rule, value) {
                        if (!value) {
                          return Promise.reject('You can’t keep this empty');
                        }else if(value.length === 0){
                          return Promise.reject('You can’t keep this empty');
                        }else
                        {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                  onClick={() => this.toggleFieldDisability(6)}
                >
                  <Select
                    mode="multiple"
                    className={'ant-custom-select'}
                    size={'large'}
                    allowClear
                    placeholder="Select the document type"
                    optionFilterProp="children"
                    onChange={this.onSelectChangeDocType}
                    loading={this.props.isLoading}
                    disabled={this.props.isLoading || merchantModified}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="nic">National Identity Card</Option>
                    <Option value="br">Business Registration</Option>
                  </Select>
                </Form.Item>
                {this.state.financialInfo.docType.includes("nic") ||
                (this.state.updatedFinancialDetails.documentType  && this.state.updatedFinancialDetails.documentType.includes("nic")) ?
                  <Form.Item
                    label="*National identity card number"
                    name="nicNumber"
                    initialValue={this.state.financialInfo.nicNumber ? this.state.financialInfo.nicNumber : ''}
                    validateTrigger='onChange'
                    onChange={(value) => {
                      this.setState({ isNicNumberExists: false });
                    }}
                    rules={[
                      () => ({
                        validator(rule, value) {
                          if (!value) {
                            return Promise.reject('You can’t keep this empty');
                          } else if (value && value.length > 12) {
                            return Promise.reject('NIC number is too lengthy');
                          } else if (!RegExp('^([0-9]{9}[x|X|v|V]|[0-9]{12})$').test(value)) {
                            return Promise.reject('Please enter a valid NIC number');
                          } else if(stateAttributes.isNicNumberExists == true ){
                            return Promise.reject('NIC number already exists.');
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Type your NIC number"
                      onBlur={this.onSelectChangeDocValue.bind(this, 'nicNumber')}
                      disabled={this.props.isLoading || merchantModified}
                    />
                  </Form.Item>
                : null}
                {this.state.financialInfo.docType.includes("br") ||
                (this.state.updatedFinancialDetails.documentType  && this.state.updatedFinancialDetails.documentType.includes("br")) ?
                  <Form.Item
                    label="*Business registration number"
                    name="brNumber"
                    initialValue={this.state.financialInfo.brNumber ? this.state.financialInfo.brNumber : ''}
                    onChange={(value) => {
                      this.setState({ isBrNumberExists: false });
                    }}
                    rules={[
                      () => ({
                        validator(rule, value) {
                          if (!value || !(value.trim())) {
                            return Promise.reject('You can’t keep this empty');
                          } else if (value && value.length > 15) {
                            return Promise.reject('BR number is too lengthy');
                          } else if (value && value.length < 5) {
                            return Promise.reject('BR number is too short')
                          } else if(stateAttributes.isBrNumberExists == true ){
                            return Promise.reject('BR number already exists.');
                          } else if(!RegExp('(?=.*?[0-9]).+').test(value)){
                            return Promise.reject('Should include at least one number ');
                          } else if(!RegExp('(?=.*?[A-Za-z]).+').test(value)){
                            return Promise.reject('Should include at least one alphabet');
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Type your BR number"
                      onBlur={this.onSelectChangeDocValue.bind(this, 'brNumber')}
                      disabled={this.props.isLoading || merchantModified}
                    />
                  </Form.Item>
                : null}
                <Form.Item
                  label={<>*NIC/Business registration documents</>}
                  name="businessdocuments"
                  valuePropName="businessdocuments"
                >
                  <Upload
                    disabled={merchantModified}
                    className="sp-file-upload"
                    accept=".jpg,.png,.pdf"
                    // disabled={notApproved}
                    listType="picture"
                    beforeUpload={(file) => {
                      if (file.size > 4194304) {
                        return Promise.reject(
                          Modal.error({
                            content: 'Maximum size exceeded',
                            centered: true,
                            maskClosable: false,
                          }),
                        );
                      }
                      if (
                        this.financialDocumentsForm.current.getFieldValue(
                          'businessdocuments',
                        ) &&
                        this.state.financialInfo.brDocuments &&
                        (this.financialDocumentsForm.current.getFieldValue(
                          'businessdocuments',
                        ).fileList.length + this.state.financialInfo.brDocuments.length) >
                        MAX_IMAGEUPLOAD - 1
                      ) {
                        return Promise.reject(
                          showErrorModal(
                            `Only ${MAX_IMAGEUPLOAD} items are allowed!`,
                          ),
                        );
                      } else return false;
                    }}
                  >
                    <Input disabled placeholder="<4MB,PNG,JPEG,PDF" />
                    <Button>Browse</Button>
                  </Upload>
                </Form.Item>
                <Form.Item>
                  <Row>
                    {this.state.financialInfo.brDocuments.map((doc, idx) => (
                      <Col key={idx} className="deletable-thumb-container">
                        <ThumbWithDeleteAction
                          thumb={doc}
                          onActionTriggered={this.onBrDocumentItemDeletePressed}
                        // onActionTriggered={
                        //   notApproved
                        //     ? () => void 0
                        //     : this.onBrDocumentItemDeletePressed
                        // }
                        />
                      </Col>
                    ))}
                  </Row>
                </Form.Item>
                <Form.Item
                  label={<>*Business logo/image</>}
                  name="businesslogo"
                  valuePropName="businesslogo"
                >
                  <Upload
                    disabled={merchantModified}
                    className="sp-file-upload"
                    accept=".jpg,.png"
                    multiple
                    // disabled={notApproved}
                    listType="picture"
                    beforeUpload={(file) => {
                      if (file.size > 2621440) {
                        return Promise.reject(
                          showErrorModal('Maximum size exceeded!'),
                        );
                      }
                      if (
                        this.financialDocumentsForm.current.getFieldValue(
                          'businesslogo',
                        ) &&
                        this.financialDocumentsForm.current.getFieldValue(
                          'businesslogo',
                        ).fileList.length > 0
                      ) {
                        return Promise.reject(
                          showErrorModal('Only 1 item is allowed!'),
                        );
                      } else return false;
                    }}
                  >
                    <Input disabled placeholder="<4MB,PNG,JPEG" />
                    <Button>Browse</Button>
                  </Upload>
                </Form.Item>
                <Form.Item>
                  <Row>
                    {this.state.financialInfo.logo && (
                      <Col className="deletable-thumb-container">
                        <ThumbWithDeleteAction
                          thumb={this.state.financialInfo.logo}
                          onActionTriggered={this.onLogoItemDeletePressed.bind(
                            this,
                          )}
                        // onActionTriggered={
                        //   notApproved
                        //     ? () => void 0
                        //     : this.onLogoItemDeletePressed.bind(this)
                        // }
                        />
                      </Col>
                    )}
                  </Row>
                </Form.Item>

                {/* Add a dropdwon */}
                <Form.Item
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                  rules={natureOfBusinessValidator()}
                  label="*Nature of business"
                  name="natureOfBusiness"
                  onClick={() => this.toggleFieldDisability(6)}
                >
                  <Select
                    mode="multiple"
                    className={'ant-custom-select'}
                    size={'large'}
                    allowClear
                    placeholder="Select the nature of your business"
                    optionFilterProp="children"
                    onChange={this.onSelectChangeNature}
                    loading={this.props.isLoading}
                    disabled={this.props.isLoading || merchantModified}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {natureData.natureList.map((itm, idx) => {
                      return (
                        <Option key={idx} value={itm.nature}>
                          {itm.nature}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                  {this.state.financialInfo.natureOfBusiness.
                    filter((a)=>a.includes("Other")).length > 0 ? <Form.Item
                    rules={natureOfBusinessOtherValidator()}
                    label="*Other"
                    name="otherNatureOfBusiness"
                    initialValue={this.state.financialInfo.otherNatureOfBusiness}
                    //onClick={() => this.toggleFieldDisability(2)}
                  >
                    <TextArea
                      placeholder="Type the nature of your business"
                      disabled={this.props.isLoading || merchantModified}
                    />
                </Form.Item> : null}

                <Form.Item>
                  <Button
                    className="sp-primary"
                    onClick={this.onFinancialDocumentUpdate.bind(this)}
                    disabled={(this.state.disabledFields.financialDocuments && this.state.disabledFields.businessName) || merchantModified}
                  // disabled={
                  //   this.state.disabledFields.financialDocuments ||
                  //   notApproved
                  // }
                  >
                    Update Commercial Documents
                  </Button>
                </Form.Item>

              </Form>
            </TabPane>
            <TabPane tab="Contact Details" key="3">
              <Form
                initialValues={{
                  mobileNumber: `0${this.state.contactInfo.mobile}`,
                }}
                ref={this.mobileNumberForm}
                validateMessages={this.validateMessages}
                layout="vertical"
                onValuesChange={this.onMobileNumberFormValuesChanged.bind(this)}
                className="limited-width-form-container"
              >
                <Form.Item
                  rules={mobileNumberValidator()}
                  label="Mobile number"
                  name="mobileNumber"
                  onClick={() => this.toggleFieldDisability(4)}
                >
                  <Input
                  // disabled={this.state.disabledFields.mobile}
                  // disabled={this.state.disabledFields.mobile || notApproved}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    className="sp-primary"
                    onClick={this.onMobileNumberUpdate.bind(this)}
                    disabled={
                      this.state.disabledFields.mobile ||
                      !this.state.mobileNumber ||
                      this.state.contactInfo.mobile === this.state.mobileNumber
                    }
                  // disabled={
                  //   this.state.disabledFields.mobile ||
                  //   !this.state.mobileNumber ||
                  //   this.state.contactInfo.mobile ===
                  //     this.state.mobileNumber ||
                  //   notApproved
                  // }
                  >
                    Update Contact Details
                  </Button>
                </Form.Item>
              </Form>
              {this.renderMobileOtpVerificationForm()}
            </TabPane>
          </Tabs>
        </div>
      </div>
    ) : (
        <LandingPage />
      );
  }
}

BankDetailsPage.propTypes = {
  user: PropType.object,
  actions: PropType.object,
  merchantActions: PropType.object,
  commonActions: PropType.any,
  bankList: PropType.array,
  branchList: PropType.array,
  isLoading: PropType.bool,
  bankAccount: PropType.object,
  otpId: PropType.string,
  otpVerified: PropType.bool,
  mobileUpdated: PropType.bool,
  docTypeValNic: PropType.object,
  docTypeValBr: PropType.object,
};

function mapStateToProps(state) {
  return {
    bankList: state.provisioning.banksList,
    branchList: state.provisioning.branchesList,
    docTypeValNic: state.provisioning.docTypeValNicExists,
    docTypeValBr: state.provisioning.docTypeValBrExists,
    user: state.common.user,
    isLoading: state.common.isLoadingData,
    bankAccount: state.merchant.bankAccount,
    otpId: state.merchant.otpId,
    otpVerified: state.merchant.otpVerified,
    mobileUpdated: state.merchant.mobileUpdated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    merchantActions: bindActionCreators(merchantActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    actions: bindActionCreators(provisioningActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BankDetailsPage);
