import React from 'react';
import Keycloak from 'keycloak-js';
import { KeycloakProvider } from '@react-keycloak/web';
import PropTypes from 'prop-types';
import Idle from '../components/widgets/idle';

// import '../configs/keycloak.json';

// const keycloak = new Keycloak({
//   realm: 'click2pay',
//   url: 'http://localhost:8080/auth',
//   clientId: 'click2pay-frontend',
// });
export const keycloak = Keycloak('/configs/keycloak.json');

class C2PKeyCloakProvider extends React.Component {
  constructor() {
    super();
    this.state = { hasTokenReceived: false };
  }

  onTokensReceivedEvent(tokens) {
    console.log('onKeycloakTokens', tokens);
    window.kcit = tokens.token;
    this.setState({ hasTokenReceived: true });
  }

  onKeycloakEvent(event, error) {
    console.log('onKeycloakEvent', event, error);
  }

  render() {
    return (
      <KeycloakProvider
        keycloak={keycloak}
        initConfig={{
          onLoad: 'login-required',
          promiseType: 'native',
          checkLoginIframe: false,
        }}
        onEvent={this.onKeycloakEvent}
        onTokens={this.onTokensReceivedEvent.bind(this)}
      >
        <Idle>{this.state.hasTokenReceived ? this.props.children : null}</Idle>
      </KeycloakProvider>
    );
  }
}
C2PKeyCloakProvider.propTypes = {
  children: PropTypes.any,
};

export default C2PKeyCloakProvider;
