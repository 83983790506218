import React from 'react';
import { Button, Col, DatePicker, Row, Select } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import PropType from 'prop-types';
import Modal from 'antd/es/modal';
import * as moment from 'moment';
import { getPaymentMethod } from '../../constants/enums';

const { Option } = Select;
const { RangePicker } = DatePicker;

class ProductFilters extends React.Component {
  constructor() {
    super();
    this.state = {
      items: [null],
      date: null,
      status: [null],
    };
  }

  render() {
    const onFilterClicked = (download = false) => {
      if (!this.state.date) {
        Modal.error({ title: 'Date range is mandatory' });
        return;
      }
      const data = {
        startOnDate: moment.utc(this.state.date[0]).startOf('day'),
        endOnDate: moment.utc(this.state.date[1],).endOf('day'),
        productCodes: this.state.items
          ? this.state.items.filter((item) => item)
          : [],
        paymentMethodSlugs: this.state.status
          ? this.state.status.filter((item) => item)
          : [],
      };
      if (download) {
        this.props.onDownloadClicked(data);
      } else {
        this.props.onFilterClicked(data);
      }
    };
    const onItemsChange = (value) => {
      let arr;
      if (!this.state.items.includes(null) && value.includes(null)) {
        arr = [null];
      } else if (this.state.items.includes(null) && value.length > 1) {
        arr = value.filter((n) => n);
      } else {
        arr = value;
      }
      this.setState({ items: arr });
    };
    const onDateChange = (value) => {
      this.setState({
        date: value,
      });
    };
    const onPaymentMethodsChange = (value) => {
      let arr;
      if (!this.state.status.includes(null) && value.includes(null)) {
        arr = [null];
      } else if (this.state.status.includes(null) && value.length > 1) {
        arr = value.filter((n) => n);
      } else {
        arr = value;
      }
      this.setState({ status: arr });
    };

    return (
      <div
        className={`dashboard-filters product-filters ${
          this.props.visible ? 'visible' : 'hidden'
        }`}
      >
        <Row align="middle" justify="center" gutter={10}>
          <Col xs={24} sm={24} md={7} lg={7}  className="filter-col">
            <span className="filter-title">Item/Service name</span>
            <Select
              mode="multiple"
              onChange={onItemsChange}
              defaultValue={null}
              value={this.state.items}
            >
              <Option key="itmk0" value={null}>
                - All -
              </Option>
              {this.props.items.map((itm, idx) => {
                const spl = itm.split(' - ');
                return (
                  <Option key={`itmk${idx + 1}`} value={spl[0]}>
                    {spl.slice(1).join(' - ')}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col xs={24} sm={24} md={7} lg={7} className="filter-col">
            <span className="filter-title">Order date</span>
            <RangePicker
              format="YYYY-MM-DD"
              onChange={onDateChange}
              disabledDate={(current) =>
                current && current > moment().endOf('day')
              }
            />
          </Col>
          <Col xs={24} sm={24} md={4} lg={4}  className="filter-col">
            <span className="filter-title">Payment method</span>
            <Select
              mode="multiple"
              onChange={onPaymentMethodsChange}
              defaultValue={null}
              value={this.state.status}
            >
              <Option key="pmk0" value={null}>
                - All -
              </Option>
              {(this.props.paymentMethods || []).map((pm, idx) =>
                pm === 'undefined' ? null : (
                  <Option key={`pmk${idx + 1}`} value={pm}>
                    {getPaymentMethod(pm)}
                  </Option>
                ),
              )}
            </Select>
          </Col>
          <Col xs={24} sm={24} md={3} lg={3} className="filter-col">
            <span className="filter-title">&nbsp;</span>
            <Button
              onClick={() => onFilterClicked()}
              className="sp-primary-alt"
            >
              <SearchOutlined /> Generate
            </Button>
          </Col>
          <Col xs={24} sm={24} md={3} lg={3} className="filter-col">
            <span className="filter-title">&nbsp;</span>
            <Button
              onClick={() => onFilterClicked(true)}
              className="sp-primary-alt"
            >
              <DownloadOutlined />CSV
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

ProductFilters.propTypes = {
  visible: PropType.bool,
  onFilterClicked: PropType.func,
  onDownloadClicked: PropType.func,
  paymentMethods: PropType.array,
  items: PropType.array,
};
export default ProductFilters;
