import React from 'react';
import { Row, Col, Input, DatePicker, Button, Select } from 'antd';
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import PropType from 'prop-types';
import { ordersDefaultQuery } from '../../constants/constants';
import * as moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;
class OrderFilters extends React.Component {
  constructor() {
    super();
    this.state = {
      invoiceNumber: null,
      name: null,
      date: null,
      dateQuery: null,
      status: null,
      customerMsisdn: null
    };
  }

  render() {
    const onFilterClicked = () => {
      const contains = [];
      const betweens = [];
      const equals = [];
      if (this.state.invoiceNumber) {
        contains.push(`invoiceNumber=${this.state.invoiceNumber.toLowerCase()}`);
      }
      if (this.state.name) {
        contains.push(`product.name=${this.state.name}`);
      }
      if (this.state.dateQuery) {
        betweens.push(this.state.dateQuery);
      }
      if (this.state.status) {
        equals.push(`transStatus=${this.state.status.toLowerCase()}`);
      }
      if (this.state.customerMsisdn) {
        let msisdn = `${this.state.customerMsisdn}`
        contains.push(`customerMsisdn=94${msisdn.substring(1)}`);
      }
      equals.push('refundTxId=0');
      this.props.onFilterClicked(
        Object.assign({}, ordersDefaultQuery, { contains, betweens, equals }),
      );
    };

    const onResetClicked = () => {
      this.setState({
        invoiceNumber: null,
        name: null,
        date: null,
        dateQuery: null,
        status: null,
        customerMsisdn: null
      });
      this.props.onFilterClicked(ordersDefaultQuery);
    };
    const onPayment_keyChange = (e) => {
      this.setState({ invoiceNumber: e.target.value });
    };
    const onNameChange = (e) => {
      this.setState({ name: e.target.value });
    };
    const onDateChange = (value) => {
      if (value) {
        this.setState({
          dateQuery: `orderDate,${moment.utc(value[0]).startOf('day')},${moment.utc(
            value[1],
          ).endOf('day')}`,
          date: value,
        });
      }
    };
    const onStatusChange = (value) => {
      this.setState({ status: value });
    };
    const onMsisdnChange = (e) => {
      let msisdn = e.target.value
      //(msisdn.length < 11 && /^\d+$/.test(msisdn))
      if (msisdn.length < 11) {
        this.setState({ customerMsisdn: msisdn });
      }
    };

    return (
      <div
        className={`dashboard-filters product-filters ${this.props.visible ? 'visible' : 'hidden'
          }`}
      >
        <Row align="middle" justify="center" gutter={10}>
          <Col span={3} className="filter-col">
            <span className="filter-title">Order reference</span>
            <Input
              value={this.state.invoiceNumber}
              onChange={onPayment_keyChange}
              placeholder="Eg: INV655801792018"
            />
          </Col>
          <Col span={3} className="filter-col">
            <span className="filter-title">Item/Service name</span>
            <Input
              value={this.state.name}
              onChange={onNameChange}
              placeholder="Eg: Chemistry"
            />
          </Col>
          <Col span={6} className="filter-col">
            <span className="filter-title">Order date</span>
            <RangePicker
              disabledDate={(current) =>
                current && current > moment().endOf('day')
              }
              format="YYYY-MM-DD"
              onChange={onDateChange}
              value={this.state.date}
            />
          </Col>
          <Col span={3} className="filter-col">
            <span className="filter-title">Customer MSISDN</span>
            <Input
              value={this.state.customerMsisdn}
              onChange={onMsisdnChange}
              placeholder="Eg: 07xxxxxxxx"
            />
          </Col>
          <Col span={3} className="filter-col">
            <span className="filter-title">Status</span>
            <Select
              value={this.state.status}
              placeholder="Eg:- Pending"
              onSelect={onStatusChange}
            >
              <Option value="PENDING">PENDING</Option>
              <Option value="PAID">PAID</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="DELIVERY IN PROGRESS">DELIVERY IN PROGRESS</Option>
              <Option value="REFUND">REFUND</Option>
              <Option value="FAILED">FAILED</Option>
            </Select>
          </Col>
          <Col span={3} className="filter-col">
            <span className="filter-title">&nbsp;</span>
            <Button onClick={onFilterClicked} className="sp-primary-alt">
              <SearchOutlined /> Apply
            </Button>
          </Col>
          <Col span={3} className="filter-col">
            <span className="filter-title">&nbsp;</span>
            <Button onClick={onResetClicked} className="sp-primary-alt">
              <UndoOutlined /> Reset
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

OrderFilters.propTypes = {
  visible: PropType.bool,
  onFilterClicked: PropType.func,
};
export default OrderFilters;
