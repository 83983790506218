import {
  RECEIVE_REPORT_GRID_DATA,
  RECEIVE_REPORT_META_DATA,
  SET_LOADER,
  SET_REPORT_GRID_FILTER_QUERY,
  SET_REPORT_GRID_PAGINATION,
} from '../constants/actionTypes';
import { notification } from 'antd';
import * as axios from 'axios';
import getHttpClient from '../apis/httpclient';
import { BASE_URL } from '../constants/config';

export function getReportMetaData() {
  console.log('get report meta called');

  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    dispatch(clearGridData());
    getHttpClient()
      .get(`/transaction/orderPageInformation`)
      .then((response) => {
        if (response && response.data && response.data.data) {
          dispatch({
            type: RECEIVE_REPORT_META_DATA,
            payload: response.data.data,
          });
        } else {
          return Promise.reject();
        }
      })
      .catch((error) => {
        console.log(error);
        notification.error({ message: 'Failed to Load Data' });
        dispatch(clearGridData());
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function generateCollectionReport(data) {
  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    axios
      .default({
        url: `${BASE_URL}/transaction/collection`,
        method: 'post',
        data,
        headers: {
          accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `bearer ${window.kcit}`,
        },
        responseType: 'blob',
      })
      .then((response) => {
        if (response) {
          if(response.data.size > 0){
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const fileName = `Click2Pay_Collection_Report_${new Date()}.csv`; // whatever your file name .
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        } else {
          return Promise.reject();
        }
      })
      .catch((error) => {
        console.log(error);
        notification.error({ message: 'Failed to Load Data' });
        dispatch(clearGridData());
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function getReportGridData(data) {
  console.log('getReportGridData called');

  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    dispatch(clearGridData());
    getHttpClient()
      .post(`/transaction/collection/data`, data)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data instanceof Array
        ) {
          const arr = response.data.data;
          arr.length--;
          dispatch({
            type: RECEIVE_REPORT_GRID_DATA,
            payload: { results: arr },
          });
        } else {
          return Promise.reject();
        }
      })
      .catch((error) => {
        console.log(error);
        notification.error({ message: 'Failed to Load Data' });
        dispatch(clearGridData());
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function setPagination(paginationObject) {
  return (dispatch) => {
    dispatch({ type: SET_REPORT_GRID_PAGINATION, payload: paginationObject });
  };
}

export function setGridFilterQuery(filterQuery) {
  return (dispatch) => {
    dispatch({ type: SET_REPORT_GRID_FILTER_QUERY, payload: filterQuery });
  };
}

export function clearGridData() {
  console.log('clearGridData called');

  return (dispatch) => {
    dispatch({
      type: RECEIVE_REPORT_GRID_DATA,
      payload: {
        currentPage: 1,
        results: [],
        resultsPerPage: 0,
        totalPages: 1,
        totalResults: 0,
      },
    });
  };
}
