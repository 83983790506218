import { Layout, Button } from 'antd';
import React from 'react';
import pendingApproval from '../assets/pending-approval.svg';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import { bindActionCreators } from 'redux';
import * as commonActions from '../actions/commonActions';

const { Content } = Layout;
class PendingApprovalPage extends React.Component {

  constructor() {
    super();
  }

  componentDidMount() {
    this.props.commonActions.fetchUserInfo();
  }

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <img src={pendingApproval} className="infomation-msg-img" />
        <p className="information-msg-title">Sit Back and Relax!</p>
        <p className="infomation-msg-description">
          Your account is in pending approval. This might take 1 - 12 hours in business days. We'll send you a message after the approval.
            </p>
        <Button className="sp-primary-alt" href="/">
          Back to Home
        </Button>
      </div>
    );
  }
};

PendingApprovalPage.propTypes = {
  commonActions: PropType.any
};

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(hot(module)(PendingApprovalPage));