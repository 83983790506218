import React from 'react';
import {
  Tooltip,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Typography,
  Upload,
} from 'antd';
import {
  CloudTwoTone,
  CopyOutlined,
  InfoCircleFilled,
} from '@ant-design/icons';
import PropType from 'prop-types';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/productActions';
import { connect } from 'react-redux';
import SPToggleButton from '../widgets/SPToggleButton';
import { CUSTOMER_UI_URL } from '../../constants/config';
import { imageRules, priceValidator } from '../../utils/inputValidators';

const { Title } = Typography;
const { Dragger } = Upload;

class AddNewItem extends React.Component {
  addItemForm = React.createRef();

  constructor() {
    super();
    this.validateMessages = {
      required: 'You can’t keep this empty !',
      pattern: {
        mismatch: 'Please enter a valid character !',
      },
      max: 'Exceeded maximum allowed character count',
      min: 'Input character count is less than the allowed',
    };
    this.state = {
      homedelivery: false,
      availabilitytype: false,
      showOnetimeUrl: false,
    };
  }

  componentWillUnmount() {
    this.props.actions.resetProductSubmitState(false);
  }

  render() {
    const { user, productCreated, isLoadingData } = this.props;
    return user ? (
      <div className="dashboard-subpage-container">
        <div className="provision-content-container">
          <Title style={{ wordSpacing: '-6px' }} level={3}>
            Add New Item/Service
          </Title>
          <Title level={4}>Item/Service Details</Title>
          <Divider />
          <Form
            ref={this.addItemForm}
            validateMessages={this.validateMessages}
            onFinish={(values) => {
              this.props.actions.addNewItem(
                Object.assign({}, values, {
                  homedelivery: this.state.homedelivery,
                  availabilitytype: this.state.availabilitytype,
                }),
              );
            }}
            className="limited-width-form-container"
            layout="vertical"
          >
            <Form.Item
              name="itemdetail"
              rules={[
                () => ({
                  validator(rule, value) {
                    if (!value || !(value.trim())) {
                      return Promise.reject('You can’t keep this empty');
                    } else if (value.length < 5) {
                      return Promise.reject('Item Details is too short');
                    } else if (value && value.length > 30) {
                      return Promise.reject('The name is too lengthy.');
                    } 
                    // else if (!RegExp('^[A-Za-z0-9 ]+$').test(value)) {
                    //   return Promise.reject(
                    //     'Special characters are not allowed',
                    //   );
                    // } 
                    else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              label={'Name'}
            >
              <Input
                disabled={productCreated || isLoadingData}
                placeholder="Basket ball T shirt"
              />
            </Form.Item>

            <Form.Item
              rules={[
                () => ({
                  validator(rule, value) {
                    if (!value || !(value.trim())) {
                      return Promise.reject('You can’t keep this empty');
                    } else if (value.length < 8) {
                      return Promise.reject(
                        'Should not be less than 8 characters',
                      );
                    } else if (value && value.length > 250) {
                      return Promise.reject('Maximum characters are 250');
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              name="description"
              label={
                <>
                  Description &nbsp;
                  <Tooltip
                    title={`Please
                    enter a valid description to
                    receive the settlement`}
                  >
                    <InfoCircleFilled />
                  </Tooltip>
                </>
              }
            >
              <Input.TextArea
                disabled={productCreated || isLoadingData}
                placeholder="How do you want to describe the item/service (include specifications of the item/service)"
                autoSize={{ minRows: 5, maxRows: 10 }}
              />
            </Form.Item>
            <Title level={4}>Media</Title>
            <Divider />
            <Form.Item
              rules={imageRules()}
              label="Images"
              name="image"
              valuePropName="image"
            >
              <Dragger
                disabled={productCreated || isLoadingData}
                accept=".jpg,.png"
                listType="picture"
                multiple
                beforeUpload={(file) => {
                  if (file.size > 4194304) {
                    return Promise.reject(
                      Modal.error({
                        content: 'Maximum size exceeded',
                        centered: true,
                        maskClosable: false,
                      }),
                    );
                  } else return false;
                }}
              >
                <p className="ant-upload-drag-icon">
                  <CloudTwoTone />
                </p>
                <p className="ant-upload-text">Add or drop images to upload</p>
                <p className="ant-upload-hint">Maximum 4 MB, .jpg and .png only</p>
                <p className="ant-upload-hint">Note: Please upload images of pixels 800x800 for better resolution</p>
              </Dragger>
            </Form.Item>
            <br />

            <Title level={3}>Pricing</Title>
            <Divider />
            <Row gutter={8}>
              <Col xs={24} sm={24} md={12} xl={12}>
                <Form.Item
                  name="price"
                  rules={priceValidator(1, 10000000)}
                  label="Price(LKR)"
                >
                  <Input
                    style={{ width: '-webkit-fill-available' }}
                    disabled={productCreated || isLoadingData}
                    placeholder="eg:- 2500.00"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Title level={4}>Delivery/Pickup</Title>
            <Divider />
            <Row gutter={8}>
              <Col xs={24} sm={24} md={12} xl={12}>
                <Form.Item name={'homedelivery'} label="Delivery">
                  <Row>
                    <Col span={24}>
                      <SPToggleButton
                        onChange={(value) => {
                          this.setState({ homedelivery: value });
                        }}
                        name="onetimeonly"
                        isChecked={this.state.homedelivery}
                        disabled={productCreated || isLoadingData}
                        checkedText="Available"
                        uncheckedText="Not Available"
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            {this.state.homedelivery && (
              <Row gutter={8}>
                <Col xs={24} sm={24} md={12} xl={12}>
                  <Form.Item
                    name="deliveryfee"
                    initialValue="0.00"
                    rules={priceValidator(0, 10000000)}
                    label={
                      <>
                      Delivery Fee &nbsp;
                      <Tooltip
                        title={`if the delivery charges based on a location/weight, 
                        please check with the customer before updating delivery fee`}>
                        <InfoCircleFilled />
                      </Tooltip>
                    </>
                    }
                  >
                    <Input
                      style={{ width: '-webkit-fill-available' }}
                      disabled={productCreated || isLoadingData}
                      placeholder="Delivery Fee"
                      initialValue="0.00"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row gutter={8}>
              <Col xs={24} sm={24} md={12} xl={12}>
                <Form.Item
                  label={
                    <>
                      Availability type &nbsp;
                      <Tooltip
                        title={`You can use this URL for ${
                          this.state.availabilitytype
                            ? 'one transaction only (URL will be expired after one transaction)'
                            : 'more than one time'
                        }`}
                      >
                        <InfoCircleFilled />
                      </Tooltip>
                    </>
                  }
                >
                  <Row gutter={8}>
                    <Col span={24}>
                      <SPToggleButton
                        onChange={(value) => {
                          console.log(value);
                          this.setState({ availabilitytype: value });
                        }}
                        name="onetimeonly"
                        isChecked={this.state.availabilitytype}
                        disabled={productCreated || isLoadingData}
                        checkedText="One-Time URL"
                        uncheckedText="Recurring URL"
                      />
                    </Col>
                  </Row>
                  <span>{!this.state.availabilitytype ? 'Want to share with only one customer? Change the toggle.' : null}</span>
                </Form.Item>
              </Col>
            </Row>
            {productCreated ? (
              <div>
                <br />
                <Title level={4}>Publish</Title>
                <Divider />
                <Form.Item>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Input
                        disabled
                        value={`${CUSTOMER_UI_URL}${this.props.productCreated}`}
                      />
                    </Col>
                    <Col span={12}>
                      <Button
                        style={{ margin: '6px' }}
                        size={'large'}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${CUSTOMER_UI_URL}${this.props.productCreated}`,
                          );
                          message.success({ content: 'Copied to clipboard' });
                        }}
                        shape="circle"
                        icon={<CopyOutlined />}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </div>
            ) : null}
            <Row
              style={{ display: productCreated ? 'none' : '' }}
              justify="end"
              gutter={16}
              className="bottom-btn__wrapper"
            >
              <Col>
                <Button
                  disabled={this.props.isLoadingData}
                  className="sp-secondary"
                  onClick={() => this.props.history.push(`/products`)}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  disabled={this.props.isLoadingData}
                  className="sp-primary"
                  htmlType="submit"
                  type="primary"
                  style={{ wordSpacing: '-4px' }}
                >
                  Save & Submit
                </Button>
              </Col>
            </Row>
          </Form>
          <Button
            style={{ display: productCreated ? '' : 'none' }}
            className="sp-primary"
            type="primary"
            onClick={() => this.props.history.push(`/products`)}
          >
            Go Back to Dashboard
          </Button>
        </div>
      </div>
    ) : (
      <Row align="middle" justify="center">
        <Col>
          <Spin size="large" tip="loading user info.." />
        </Col>
      </Row>
    );
  }
}

AddNewItem.propTypes = {
  user: PropType.object,
  actions: PropType.object,
  history: PropType.object,
  productCreated: PropType.object,
  isLoadingData: PropType.bool,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    productCreated: state.products.productCreated,
    isLoadingData: state.common.isLoadingData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewItem);
