import React from 'react';
import PropType from 'prop-types';
import {
  Button,
  Alert,
  Card,
  Col,
  Form,
  Divider,
  Input,
  Result,
  Row,
  Spin,
  Typography,
  Popconfirm,
} from 'antd';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as orderActions from '../../actions/orderActions';
// import { CopyOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { CopyOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { TransactionStatus } from '../../constants/enums';

const { Title } = Typography;

class OrderItemView extends React.Component {
  constructor() {
    super();
  }

  UNSAFE_componentWillMount() {
    this.props.actions.getOrderItemData(this.props.match.params.id);
  }

  onOrderStatusChange(status) {
    this.props.actions.completeOrder(this.props.match.params.id, status);
  }

  render() {
    const { itemData } = this.props;
    const layout = {
      labelCol: { span: 9 },
    };

    return itemData ? (
      <>
        {itemData.error ? (
          <Result
            status={itemData.errorCode}
            title={itemData.errorCode}
            subTitle={itemData.error}
            extra={
              <Button
                onClick={() => this.props.history.push(`/confirmed-orders`)}
                type="primary"
              >
                Back to Orders
              </Button>
            }
          />
        ) : (
            <>
             <div>
              <Button
                icon={<ArrowLeftOutlined />}
                className="sp-secondary"
                onClick={() => this.props.history.push(`/confirmed-orders`)}
              >
                Go Back
              </Button>
            </div>
            <br />
            <div className="status__wrapper">
              <Title>{`Order #${itemData.reciptNumber ? itemData.reciptNumber : ''}  `}</Title>
              <div className={"status-item small " + (itemData.transStatus === 'delivery in progress' ? 'dip' : itemData.transStatus)}>
                <div className="icon-holder"></div> 
                <span>{itemData.transStatus}</span>
              </div>
            </div>
              <Divider />
              <div className="provision-content-container">
                <Form
                  colon={false}
                  className="limited-width-form-container"
                  {...layout}
                  layout="vertical"
                >
                  <Form.Item label="Order received date">
                    <Input
                      disabled
                      value={itemData.paymentDate ? moment(itemData.paymentDate)
                        .format('DD-MM-YYYY HH:mm:ss') : '-'}
                    />
                  </Form.Item>

                  <Form.Item label="Customer MSISDN">
                    <Input disabled value={`0${itemData.customerConatctNumber.substring(2)}`} />
                  </Form.Item>

                  <Form.Item label="Item/Service ID">
                    <Input disabled value={itemData.itemServiceId} />
                  </Form.Item>

                  <Form.Item label="Item/Service name">
                    <Input disabled value={itemData.itemServiceName} />
                  </Form.Item>

                  <Form.Item label="Description">
                    <TextArea rows={8} disabled value={itemData.description} />
                  </Form.Item>

                  <Form.Item label="Payment method">
                    <Input disabled value={itemData.paymentMethod} />
                  </Form.Item>

                  {/*<Row>*/}
                  {/*  <Col span={12}>*/}
                  {itemData.deliveryAvailable && (
                    <Form.Item label="Shipping address">
                      <Input disabled value={itemData.shippingAddress} />
                    </Form.Item>
                  )}

                  <Form.Item label="Item/Service price (LKR)">
                    <Input disabled value={Number(itemData.subTotal).toFixed(2)} />
                  </Form.Item>

                  {itemData.deliveryAvailable && (
                    <Form.Item label="Delivery fee (LKR)">
                      <Input disabled value={Number(itemData.deliveryFee).toFixed(2)} />
                    </Form.Item>
                  )}
                  <Form.Item label="Subtotal (LKR)">
                    <Input disabled value={Number(parseFloat(itemData.subTotal) + parseFloat(itemData.deliveryFee)).toFixed(2)} />
                  </Form.Item>
                  <Form.Item label="Convenience fee (LKR) + Tax">
                    <Input disabled value={Number(parseFloat(itemData.convenienceFee) + parseFloat(itemData.taxAmount)).toFixed(2)} />
                  </Form.Item>
                  <Form.Item label="Total (LKR)">
                    <Input disabled value={itemData.total} />
                  </Form.Item>

                  <Alert
                      style={{ marginBottom: '15px' }}
                      message="The total vendor income will be considered by excluding commission + tax."
                      type="info"
                      showIcon
                    />

                  {/* check whether if this transaction is a refund transaction */}
                  {itemData.refundTransactionId == 0 &&
                    <div className="bottom-btn__wrapper">
                      <div>
                        {(itemData.deliveryAvailable && itemData.transStatus === TransactionStatus.PAID) ? (
                          <Popconfirm
                            title='Are you sure you want to make this order as delivery in progress?'
                            okText="Yes"
                            cancelText="No"
                            disabled=''
                            onConfirm={() => this.onOrderStatusChange('dip')}>
                            <Button className="sp-primary">
                              Delivery in Progress
                          </Button>
                          </Popconfirm>
                        ) : (itemData.transStatus === TransactionStatus.PAID || itemData.transStatus === TransactionStatus.DELIVERY_IN_PROGRES) && (
                          <Popconfirm
                            title='Are you sure you want to complete this order?'
                            okText="Yes"
                            cancelText="No"
                            disabled=''
                            onConfirm={() => this.onOrderStatusChange('complete')}>
                            <Button className="sp-primary">
                              Complete Order
                          </Button>
                          </Popconfirm>
                        )}
                      </div>
                    </div> 
                  }
                </Form>
              </div>
            </>
          )}
      </>
    ) : (
        <Row align="middle" justify="center">
          <Col>
            <Spin size="large" tip="loading item info.." />
          </Col>
        </Row>
      );
  }
}

OrderItemView.propTypes = {
  match: PropType.object,
  user: PropType.object,
  itemData: PropType.object,
  actions: PropType.object,
  history: PropType.object,
};

function mapStateToProps(state) {
  return {
    itemData: state.orders.itemData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(orderActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderItemView);
