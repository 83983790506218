import React from 'react';
import PropType from 'prop-types';
import {
  Result,
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  Typography,
  Upload,
  Popconfirm,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { CopyOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as productActions from '../../actions/productActions';
import { BASE_URL } from '../../constants/config';
import { ProductStatus } from '../../constants/enums';
import SPToggleButton from '../widgets/SPToggleButton';

const { Title } = Typography;

const { TabPane } = Tabs;

class ProductItemView extends React.Component {
  constructor() {
    super();
    this.state = {
      previewVisible: true,
      previewImage: '',
      previewTitle: '',
      fileList: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.props.actions.fetchItem(this.props.match.params.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    console.log(this.props);
    if (
      nextProps.itemData != null &&
      nextProps.itemData.imagesIds instanceof Array &&
      this.state.fileList.length === 0
    ) {
      if (nextProps) {
        const fileList = [];
        for (let i = 0; i < nextProps.itemData.imagesIds.length; i++) {
          fileList.push({
            uid: i,
            name: `image ${i}`,
            url: `${BASE_URL}/resources?resourceId=${nextProps.itemData.imagesIds[i]}&isOriginal=false`,
          });
        }
        this.setState({ fileList: fileList });
      }
    }
  }
  onDeactivateClick(item) {
    this.props.actions.changeItemStatus(item);
  }

  componentWillUnmount() {
    this.props.actions.clearItemData();
  }

  render() {
    const { itemData } = this.props;
    return itemData ? (
      <>
        {itemData.error ? (
          <Result
            status={itemData.errorCode}
            title={itemData.errorCode}
            subTitle={itemData.error}
            extra={
              <Button
                onClick={() => this.props.history.push(`/products`)}
                type="primary"
              >
                Back to Products
              </Button>
            }
          />
        ) : (
          <>
            <div>
              <Button
                icon={<ArrowLeftOutlined />}
                className="sp-secondary"
                onClick={() => this.props.history.push(`/products`)}
              >
                Go Back
              </Button>
            </div>
            <br />
            <Title>{itemData.name}</Title>
            <Tabs defaultActiveKey="1">
              <TabPane tab="DETAILS" key="1">
                <Title level={4}>Item/Service Details</Title>
                <Divider />
                <Form
                  className="limited-width-form-container"
                  layout="vertical"
                >
                  <Form.Item label={'Name'}>
                    <Input disabled value={`${itemData.name}`} />
                  </Form.Item>
                  <Form.Item label={'Description'}>
                    <TextArea
                      rows={8}
                      disabled
                      placeholder={`${itemData.description}`}
                    />
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane tab="MEDIA" key="2">
                <Form
                  className="limited-width-form-container"
                  layout="vertical"
                >
                  <Form.Item>
                    <Upload
                      listType="picture-card"
                      fileList={this.state.fileList}
                      disabled
                    />
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane tab="PRICING" key="3">
                <Form
                  className="limited-width-form-container"
                  layout="vertical"
                >
                  <Form.Item label={'Price (LKR)'}>
                    <Input disabled value={Number(itemData.price).toFixed(2)} />
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane tab="DELIVERY/PICKUP" key="4">
                <Form
                  className="limited-width-form-container"
                  layout="vertical"
                >
                  <Form.Item name={'homedelivery'} label="Delivery">
                    <Row gutter={8}>
                      <Col xs={24} sm={24} md={12} xl={12}>
                        <SPToggleButton
                          isChecked={itemData.deliveryAvailable}
                          disabled={true}
                          checkedText="Available"
                          uncheckedText="Not Available"
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item
                    hidden={!itemData.deliveryAvailable}
                    label={'Delivery Fee (LKR)'}
                  >
                    <Input
                      disabled
                      value={Number(itemData.deliveryFee).toFixed(2)}
                    />
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane tab="PUBLISH" key="5">
                <Title level={4}>Publish</Title>
                <Divider />
                <Form
                  className="limited-width-form-container"
                  layout="vertical"
                >
                  <Row gutter={8}>
                    <Col xs={20} sm={20} md={12} xl={12}>
                      <Form.Item>
                        <Input disabled value={itemData.url} />
                      </Form.Item>
                    </Col>
                    <Col xs={4} sm={4} md={12} xl={12}>
                      <Button
                        style={{ margin: '6px' }}
                        size={'large'}
                        onClick={() => {
                          navigator.clipboard.writeText(itemData.url);
                          message.success({ content: 'Copied to clipboard' });
                        }}
                        shape="circle"
                        icon={<CopyOutlined />}
                      />
                    </Col>
                  </Row>

                  <Space size={10} direction={'vertical'}>
                    {itemData.status === ProductStatus.ACTIVE && (
                      <Alert
                        message="This link is currently live."
                        type="success"
                        showIcon
                      />
                    )}
                    <Alert
                      style={{ marginBottom: '15px' }}
                      message="Here is your Click2Pay item/service URL which you can share with your customers via SMS, Social media or Web."
                      type="info"
                      showIcon
                    />
                  </Space>
                  <Form.Item label="Availability type">
                    <Row gutter={8}>
                      <Col xs={24} sm={24} md={12} xl={12}>
                        <SPToggleButton
                          isChecked={itemData.oneTimeUrl}
                          disabled={true}
                          checkedText="One-Time URL"
                          uncheckedText="Recurring URL"
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item label={'Status'}>
                    <Select
                      xs={24}
                      sm={24}
                      disabled
                      value={itemData.status.toUpperCase()}
                      style={{ width: 130 }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Row justify={'space-around'}>
                      <Popconfirm
                        title={itemData.status === ProductStatus.ACTIVE
                            ? 
                            <span>
                              Are you sure you want to deactivate this item/service?<br/>
                              Once you deactivated, shareable URL of this item/service will not be available to your customers.
                            </span>
                            : 
                            <span>
                              Are you sure you want to activate this item/service?<br/>
                              Once you activated, shareable URL of this item/service will be available to your customers.                        
                            </span>
                        }
                        okText="Yes"
                        cancelText="No"
                        disabled={
                          !(
                            itemData.status === ProductStatus.ACTIVE ||
                            itemData.status === ProductStatus.INACTIVE
                          )
                        }
                        onConfirm={() => this.onDeactivateClick(itemData)}
                      >
                      {(itemData.status === ProductStatus.ACTIVE || itemData.status === ProductStatus.INACTIVE) &&
                        <Button
                          type="primary"
                          className="deactivate-btn"
                          danger={itemData.status === ProductStatus.ACTIVE}
                          disabled={
                            !(
                              itemData.status === ProductStatus.ACTIVE ||
                              itemData.status === ProductStatus.INACTIVE
                            )
                          }
                        >
                          {itemData.status === ProductStatus.ACTIVE
                            ? 'Deactivate / Unpublish'
                            : 'Activate / Publish '}
                        </Button>
                      }
                      </Popconfirm>
                    </Row>
                  </Form.Item>
                </Form>
              </TabPane>
            </Tabs>
          </>
        )}
      </>
    ) : (
      <Row align="middle" justify="center">
        <Col>
          <Spin size="large" tip="loading item info.." />
        </Col>
      </Row>
    );
  }
}

ProductItemView.propTypes = {
  match: PropType.object,
  user: PropType.object,
  itemData: PropType.object,
  actions: PropType.object,
  history: PropType.object,
};

function mapStateToProps(state) {
  return {
    itemData: state.products.itemData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(productActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductItemView);
