import React from 'react';

const ContactUsPopUp = () => {
    return (
        <div className="fab-wrapper">
            <input id="fabCheckbox" type="checkbox" className="fab-checkbox" />
            <label className="fab" htmlFor="fabCheckbox">
                <span className="fab-dots fab-dots-1"></span>
                <span className="fab-dots fab-dots-2"></span>
                <span className="fab-dots fab-dots-3"></span>
            </label>
            <div className="fab-wheel">
                {/* <a className="fab-action fab-action-1" href="#">
                    <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <title>whatsapp (1)</title>
                        <g id="Click2Pay-Landing" stroke="none" strokewidth="1" fill="none" fillrule="evenodd">
                            <g id="Artboard" transform="translate(-894.000000, -638.000000)" fill="#FFFFFF" fillrule="nonzero">
                                <g id="whatsapp-(1)" transform="translate(894.000000, 638.000000)">
                                    <path d="M21.88375,17.8842903 L21.8725,17.9780403 C19.12375,16.6080403 18.83625,16.4255403 18.48125,16.9580403 C18.235,17.3267903 17.5175,18.1630403 17.30125,18.4105403 C17.0825,18.6542903 16.865,18.6730403 16.49375,18.5042903 C16.11875,18.3167903 14.915,17.9230403 13.49,16.6480403 C12.38,15.6542903 11.635,14.4355403 11.415,14.0605403 C11.04875,13.4280403 11.815,13.3380403 12.5125,12.0180403 C12.6375,11.7555403 12.57375,11.5492903 12.48125,11.3630403 C12.3875,11.1755403 11.64125,9.3380403 11.32875,8.6055403 C11.02875,7.8755403 10.72,7.9680403 10.48875,7.9680403 C9.76875,7.9055403 9.2425,7.9155403 8.77875,8.3980403 C6.76125,10.6155403 7.27,12.9030403 8.99625,15.3355403 C12.38875,19.7755403 14.19625,20.5930403 17.50125,21.7280403 C18.39375,22.0117903 19.2075,21.9717903 19.85125,21.8792903 C20.56875,21.7655403 22.06,20.9780403 22.37125,20.0967903 C22.69,19.2155403 22.69,18.4842903 22.59625,18.3155403 C22.50375,18.1467903 22.25875,18.0530403 21.88375,17.8842903 L21.88375,17.8842903 Z" id="Path"></path>
                                    <path d="M25.65,4.3117903 C16.03875,-4.9794597 0.1325,1.7592903 0.12625,14.8667903 C0.12625,17.4867903 0.8125,20.0417903 2.12,22.2980403 L0,30.0005403 L7.91875,27.9355403 C17.8,33.2730403 29.995,26.1855403 30,14.8742903 C30,10.9042903 28.45,7.1680403 25.63125,4.3605403 L25.65,4.3117903 Z M27.5025,14.8330403 C27.495,24.3742903 17.02125,30.3330403 8.7375,25.4630403 L8.2875,25.1955403 L3.6,26.4142903 L4.85625,21.8580403 L4.5575,21.3892903 C-0.5975,13.1830403 5.325,2.4580403 15.09,2.4580403 C18.4075,2.4580403 21.52125,3.7517903 23.86625,6.0955403 C26.21,8.4192903 27.5025,11.5330403 27.5025,14.8330403 Z" id="Shape"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                </a> */}
                <a className="fab-action fab-action-2" href="tel:+94773300330" alt="Hotline, Call for Support">
                    <i className="lni lni-phone"></i>
                </a>
                <a className="fab-action fab-action-3" href="mailto:support@click2pay.lk" alt="email us"><i className="lni lni-envelope"></i></a>
            </div>
        </div>
    );
};

export default ContactUsPopUp;
