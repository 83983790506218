export const API = '_API_';
export const API_START = '_API_START_';
export const API_END = '_API_END_';
export const ACCESS_DENIED = '_ACCESS_DENIED_';
export const API_ERROR = '_API_ERROR_';
export const API_ERROR_MESSAGE = '_API_ERROR_MESSAGE_';

export const SAVE_FUEL_SAVINGS = '_SAVE_FUEL_SAVINGS_';
export const CALCULATE_FUEL_SAVINGS = '_CALCULATE_FUEL_SAVINGS_';

export const GET_USER_DATA = '_GET_USER_DATA_';
export const SET_USER_DATA = '_SET_USER_DATA_';
export const SET_STAT_DATA = '_SET_STAT_DATA_';
export const SAVE_BANK_DATA = '_SAVE_BANK_DATA_';
export const SAVE_ACCEPT_TOC = '_SAVE_ACCEPT_TOC_';
export const REQUEST_OTP_SEND = '_REQUEST_OTP_SEND_';
export const SUBMIT_OTP = '_SUBMIT_OTP_';
export const START_PROVISIONING = '_START_PROVISIONING_';
export const SET_SETUP_STAGE = '_SET_SETUP_STAGE_';
export const SET_LOADER = '_SET_LOADER_';
export const CHANGE_OTP_FORM_STATE = '_CHANGE_OTP_FORM_STATE_';
export const SET_OTP_ID = '_SET_OTP_ID_';
export const SET_OTP_VERIFICATION = '_SET_OTP_VERIFICATION_';
export const SET_BANKS = '_SET_BANKS_';
export const SET_BRANCHES = '_SET_BRANCHES_';

//PRODUCT ACTIONS
export const RECEIVE_PRODUCT_GRID_DATA = '_RECEIVE_PRODUCT_GRID_DATA_';
export const SET_GRID_PAGINATION = '_SET_GRID_PAGINATION_';
export const SET_PRODUCT_GRID_FILTER_QUERY = '_SET_PRODUCT_GRID_FILTER_QUERY_';
export const SET_PRODUCT_CREATION_STATUS = '_SET_PRODUCT_CREATION_STATUS_';

//ORDER ACTIONS
export const RECEIVE_ORDER_GRID_DATA = '_RECEIVE_PRODUCT_GRID_DATA_';
export const RECEIVE_ORDER_ITEM_DATA = '_RECEIVE_ORDER_ITEM_DATA_';
export const SET_ORDER_GRID_PAGINATION = '_SET_GRID_PAGINATION_';
export const SET_ORDER_GRID_FILTER_QUERY = '_SET_ORDER_GRID_FILTER_QUERY_';

//MERCHANT ACTIONS
export const RECEIVE_BANK_ACCOUNT_DATA = '_RECEIVE_BANK_ACCOUNT_DATA_';
export const RECEIVE_PRODUCT_ITEM_DATA = '_RECEIVE_PRODUCT_ITEM_DATA_';
export const SET_MERCHANT_OTP_ID = '_SET_MERCHANT_OTP_ID_';
export const SET_MERCHANT_OTP_VERIFICATION = '_SET_MERCHANT_OTP_VERIFICATION_';
export const SET_MERCHANT_MOBILE_UPDATED = '_SET_MERCHANT_MOBILE_UPDATED_';
export const SET_IS_DOC_TYPE_VALUE_NIC_EXISTS = '_SET_IS_DOC_TYPE_VALUE_NIC_EXISTS_';
export const SET_IS_DOC_TYPE_VALUE_BR_EXISTS = '_SET_IS_DOC_TYPE_VALUE_BR_EXISTS_';
export const SET_IS_DOC_TYPE_VALUE_EMAIL_EXISTS = '_SET_IS_DOC_TYPE_VALUE_EMAIL_EXISTS_'

//REPORT ACTIONS
export const RECEIVE_REPORT_META_DATA = '_RECEIVE_REPORT_META_DATA_';
export const RECEIVE_REPORT_GRID_DATA = '_RECEIVE_REPORT_GRID_DATA_';
export const SET_REPORT_GRID_FILTER_QUERY = '_SET_REPORT_GRID_FILTER_QUERY_';
export const SET_REPORT_GRID_PAGINATION = '_SET_REPORT_GRID_PAGINATION_';
