import { defaultPagination } from '../constants/constants';

export default {
  provisioning: {
    bankInfo: null,
    acceptedToc: false,
    otpVerified: false,
    setupStage: 0,
    otpFormState: null,
    otpId: null,
    banksList: [],
    branchesList: [],
    docTypeValNicExists: null,
    docTypeValBrExists: null,
    docTypeEmailExists: null,
  },
  common: {
    user: null,
    isLoadingData: true,
    statistics: null,
  },
  products: {
    productCreated: null,
    gridData: null,
    itemData: null,
    gridFilterQuery: {},
    gridPagination: defaultPagination,
  },
  orders: {
    gridData: null,
    gridFilterQuery: {},
    gridPagination: defaultPagination,
    itemData: null,
  },
  merchant: {
    bankAccount: null,
    otpId: null,
    otpVerified: false,
    mobileUpdated: false,
  },
  payments: {},
  reports: {
    meta: { items: [], paymentMethods: [] },
    gridData: {
      currentPage: 1,
      results: [],
      resultsPerPage: 0,
      totalPages: 1,
      totalResults: 0,
    },
    gridFilterQuery: {},
    gridPagination: { offset: 0, limit: 50 },
  },
};
