import React from 'react';
import { Modal } from 'antd';
import PropType from 'prop-types';
import OTPSendModelForm from '../widgets/OTPSendModel';
import OTPVerifyModelForm from '../widgets/OTPVerifyModel';
import OTPSuccessModelForm from '../widgets/OTPSuccessModel';

const ProvisionMobileVerification = ({
  currentMobileNumber,
  onSendPressed,
  onVerifyPressed,
  onCompletePressed,
  onCancel,
  otpId,
  otpVerified,
  isLoadingData,
}) => {
  console.log(`otpVerified: ${otpVerified}`);
  console.log(`otpId: ${otpId}`);
  return (
    <Modal
      visible={true}
      centered={true}
      width={450}
      maskClosable={false}
      className="otp-modal-container"
      cancelButtonProps={{ hidden: true }}
      onCancel={onCancel || null}
      footer={null}
    >
      {otpVerified ? (
        <OTPSuccessModelForm
          onContinuePressed={onCompletePressed}
          isLoadingData={isLoadingData}
        />
      ) : otpId ? (
        <OTPVerifyModelForm
          onVerifyPressed={onVerifyPressed}
          onResendPressed={onSendPressed}
          isLoadingData={isLoadingData}
        />
      ) : (
        <OTPSendModelForm
          {...{ currentMobileNumber, onSendPressed, isLoadingData }}
        />
      )}
    </Modal>
  );
};

ProvisionMobileVerification.propTypes = {
  onCancel: PropType.func,
  onSendPressed: PropType.func,
  onVerifyPressed: PropType.func,
  onCompletePressed: PropType.func,
  form: PropType.object,
  currentMobileNumber: PropType.string,
  otpId: PropType.string,
  otpVerified: PropType.bool,
  isLoadingData: PropType.bool,
};

export default ProvisionMobileVerification;
