import React from 'react';
import { Button, Col, Row, Spin, Table, Tag } from 'antd';
import PropType from 'prop-types';
import { EyeTwoTone } from '@ant-design/icons';
import moment from 'moment';
import { ProductStatus } from '../../constants/enums';

class ProductGrid extends React.Component {
  constructor() {
    super();
    this.columns = [
      {
        title: 'Item/Service ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Item/Service name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Price + Delivery fee(LKR)',
        dataIndex: 'price',
        key: 'price',
        render: (text, row) => (row.deliveryAvailable) ? (Number(row.price) + Number(row.deliveryFee)).toFixed(2) : Number(row.price).toFixed(2),
        // render: (price) => Number(price).toFixed(2),
      },
      {
        title: 'Created date',
        dataIndex: 'createdOn',
        key: 'createdOn',
        render: (date) => {
          return moment.utc(date).local().format('YYYY-MM-DD');
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => this.itemStatusSelector(status),
      },
      {
        title: 'Actions',
        key: 'action',
        render: (v) => {
          return (
            <Button
              type="link"
              onClick={() => {
                this.props.onItemViewClicked(v.code);
              }}
            >
              View
              <EyeTwoTone />
            </Button>
          );
        },
      },
    ];
  }

  itemStatusSelector(status) {
    let color;
    switch (status) {
      case ProductStatus.ACTIVE:
        color = 'green';
        break;
      case ProductStatus.PENDING:
        color = 'blue';
        break;
      case ProductStatus.INACTIVE:
        color = 'purple';
        break;
      case ProductStatus.EXPIRED:
        color = 'volcano';
        break;
      case ProductStatus.SUSPENDED:
        color = 'red';
        break;
    }

    return <Tag color={color}>{status && status.toUpperCase()}</Tag>;
  }

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows,
      );
    },
  };

  render() {
    const onChange = (value) => {
      const offset = value.current === 1 ? 0 : (value.current - 1) * 10;
      this.props.onPaginate({ offset, limit: value.pageSize });
    };
    const { data, isLoading } = this.props;
    return data && data.results ? (
      <Table
        loading={isLoading}
        // rowSelection={{
        //   type: 'checkbox',
        //   ...this.rowSelection,
        // }}
        className="product-grid"
        pagination={{
          current: this.props.data.currentPage,
          total: this.props.data.totalResults,
        }}
        onChange={onChange}
        columns={this.columns}
        rowKey={(record) => record.id}
        dataSource={data.results}
      />
    ) : (
      <Row align="middle" justify="center">
        <Col>
          <Spin size="large" tip="loading table data.." />
        </Col>
      </Row>
    );
  }
}

ProductGrid.propTypes = {
  data: PropType.object,
  onItemViewClicked: PropType.func,
  isLoading: PropType.bool,
  getProducts: PropType.func,
  onPaginate: PropType.func,
};

export default ProductGrid;
