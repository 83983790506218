import {
  SET_LOADER,
  SET_STAT_DATA,
  SET_USER_DATA,
} from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

export default function globalReducer(state = initialState.common, action) {
  switch (action.type) {
    case SET_LOADER:
      console.log('SET_LOADER');
      console.log(state);
      return objectAssign({}, state, { isLoadingData: action.payload });
    case SET_USER_DATA:
      console.log('SET_USER_DATA');
      console.log(state);
      return objectAssign({}, state, { user: action.payload });
    case SET_STAT_DATA:
      console.log('SET_STAT_DATA');
      console.log(state);
      return objectAssign({}, state, { statistics: action.payload });

    default:
      return state;
  }
}
