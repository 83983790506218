// import {} from '../constants/actionTypes';
// import objectAssign from 'object-assign';
import initialState from './initialState';

export default function paymentReducer(state = initialState.payments, action) {
  switch (action.type) {
    //   case :
    //     return objectAssign({}, state, {
    //       gridData: action.payload,
    //     });
    default:
      return state;
  }
}
