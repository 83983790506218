import { Layout } from 'antd';
import React from 'react';
import privacyCover from '../assets/landing/privacy__cover.webp';
import Header from './common/Header';
import Footer from './common/Footer';

const { Content } = Layout;
const PrivacyPolicy = () => {
    return (
        <>
            {/* loader */}
            <div className="preloader">
                <div className="loader">
                    <div className="ytp-spinner">
                        <div className="ytp-spinner-container">
                            <div className="ytp-spinner-rotator">
                                <div className="ytp-spinner-left">
                                    <div className="ytp-spinner-circle"></div>
                                </div>
                                <div className="ytp-spinner-right">
                                    <div className="ytp-spinner-circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* header */}
            <header className="header-area">
                <Header />
                <div id="home" class="header-hero bg_cover d-lg-flex align-items-center">
                    <div className="container">
                        <div className="row align-items-center justify-content-center justify-content-lg-between">
                            <div className="col-lg-6 col-md-10">
                                <div className="header-hero-content">
                                    <h3 className="header-title wow fadeInUp" data-wow-duration="0.7s" data-wow-delay="0.2s">Privacy Policies</h3>
                                    <p className="text wow fadeInUp" data-wow-duration="0.7s" data-wow-delay="0.6s">Learn our privacy policy in detail</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-10">
                                <div className="header-image">
                                    <img src={privacyCover} alt="Header main cover image" className="image wow fadeInRightBig" data-wow-duration="1.3s" data-wow-delay="0.5s" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-shape-1 d-none d-md-block"></div>
                </div>
            </header>

            {/* section */}
            <section id="features" className="features-area pt-110">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <ul>
                                <li>
                                    <div className="features-items">
                                        <h3>Subscriber Details</h3><br />
                                        <p>Mobile subscriptions / connections obtained under the name of the enterprise and distributed among its employees shall be considered the property of the enterprise and the enterprise reserves the right to use such subscriptions / connections for any application purpose.</p>
                                        <br/>
                                        <p>This platform may enable enterprises to receive information pertaining to Dialog subscribers, with the consent of such subscribers, and the enterprise and its contracted developers shall at all times respect the privacy of the subscribers and be bound to protect subscriber identity, other subscriber details, including but not limited to phone numbers, customer locations or personal information of whatever nature, and to ensure that such information is not misused, publicized or sold to other parties.</p>
                                        <br/>
                                        <p>A subscriber should always willingly share such information with the enterprise or its contracted developer for any services provided by the IdeaBiz platform via SMS or any other means. Dialog shall not be liable in any manner for any information obtained without the consent of the subscriber.</p>
                                        <br/>
                                        <p>The enterprise should have documents to prove the subscriber’s consent as and when required by Dialog or any other legislative or regulatory authority.</p>
                                        <br/>
                                        <p>Notwithstanding the above, the Privacy Policy of Dialog Axiata PLC accessible at <a href="https://www.dialog.lk/privacy-policy" target="_blank">https://www.dialog.lk/privacy-policy</a>, shall be applicable in full force and effect.</p>
                                    </div>
                                </li><br />
                                {/* <li>
                                    <div className="features-items">
                                        <h3>1. Introduction</h3><br />
                                        <p>These Terms and Conditions (“Terms”) govern your use of the web based online video calling platform, VDOmeet (“Platform”). The Platform is provided by Dialog (hereinafter referred to as “we”, “us”, or “our”). These Terms
                                        shall constitute a binding agreement between us and you. By using the Platform, you agree that you have read, understood, and are bound by these Terms, as amended from time to time, and that you will comply with the
                                requirements mentioned herein. In the event you do not agree to be bound by all of these Terms or comply with the requirements herein, you may not use or access the Platform or provide any services through the Platform.</p>
                                    </div>
                                </li><br />
                                <li>
                                    <div className="features-items">
                                        <h3>1. Introduction</h3><br />
                                        <p>These Terms and Conditions (“Terms”) govern your use of the web based online video calling platform, VDOmeet (“Platform”). The Platform is provided by Dialog (hereinafter referred to as “we”, “us”, or “our”). These Terms
                                        shall constitute a binding agreement between us and you. By using the Platform, you agree that you have read, understood, and are bound by these Terms, as amended from time to time, and that you will comply with the
                            requirements mentioned herein. In the event you do not agree to be bound by all of these Terms or comply with the requirements herein, you may not use or access the Platform or provide any services through the Platform.</p>
                                    </div>
                                </li><br />
                                <li>
                                    <div className="features-items">
                                        <h3>1. Introduction</h3><br />
                                        <p>These Terms and Conditions (“Terms”) govern your use of the web based online video calling platform, VDOmeet (“Platform”). The Platform is provided by Dialog (hereinafter referred to as “we”, “us”, or “our”). These Terms
                                        shall constitute a binding agreement between us and you. By using the Platform, you agree that you have read, understood, and are bound by these Terms, as amended from time to time, and that you will comply with the
                        requirements mentioned herein. In the event you do not agree to be bound by all of these Terms or comply with the requirements herein, you may not use or access the Platform or provide any services through the Platform.</p>
                                    </div>
                                </li><br /> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;
