import React from 'react';
import { Button, Col, Row, Typography } from 'antd';
import PropType from 'prop-types';
import OrdersGrid from './OrdersGrid';
import * as orderActions from '../../actions/orderActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DownOutlined } from '@ant-design/icons';
import OrderFilters from './OrderFilters';
import { hot } from 'react-hot-loader';
import { ordersDefaultQuery } from '../../constants/constants';
import Statistics from '../widgets/Statistics';

const { Title } = Typography;

class OrdersView extends React.Component {
  constructor() {
    super();
    this.state = {
      isFiltersShown: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.actions.getOrders(ordersDefaultQuery);
  }

  toggleFilters() {
    this.setState({ isFiltersShown: !this.state.isFiltersShown });
  }

  onProductItemViewClicked(v) {
    this.props.history.push(`/confirmed-orders/view/${v}`);
  }

  onPaginate(paginateObject) {
    this.props.actions.getOrders(
      Object.assign({}, ordersDefaultQuery, {
        ...paginateObject,
        ...this.props.gridFilterQuery,
      }),
    );
    this.props.actions.setPagination(paginateObject);
  }

  onFilterClicked(filterObject) {
    this.props.actions.setGridFilterQuery(filterObject);
    this.props.actions.getOrders(filterObject);
  }

  render() {
    return (
      <div className="dashboard-subpage-container">
        <Statistics />
        <div className="subpage-main-content">
          <Title level={3}>Order List</Title>
          <Row justify="space-between" className="grid-actions">
            <Col className="left">
              <Button type="link" onClick={this.toggleFilters.bind(this)}>
                Filters <DownOutlined />
              </Button>
            </Col>
          </Row>
          <OrderFilters
            onFilterClicked={this.onFilterClicked.bind(this)}
            visible={this.state.isFiltersShown}
          />
          <OrdersGrid
            isLoading={this.props.isLoading}
            data={this.props.orders}
            onPaginate={this.onPaginate.bind(this)}
            onItemViewClicked={this.onProductItemViewClicked.bind(this)}
            getProducts={this.props.actions.getProducts}
          />
        </div>
      </div>
    );
  }
}

OrdersView.propTypes = {
  user: PropType.object,
  orders: PropType.object,
  actions: PropType.object,
  history: PropType.object,
  isLoading: PropType.bool,
  gridFilterQuery: PropType.object,
  gridPagination: PropType.object,
};

function mapStateToProps(state) {
  return {
    orders: state.orders.gridData,
    gridFilterQuery: state.orders.gridFilterQuery,
    gridPagination: state.orders.gridPagination,
    isLoading: state.common.isLoadingData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(orderActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(hot(module)(OrdersView));
