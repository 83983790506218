import {
  RECEIVE_ORDER_GRID_DATA,
  RECEIVE_ORDER_ITEM_DATA,
  SET_LOADER,
  SET_ORDER_GRID_FILTER_QUERY,
  SET_ORDER_GRID_PAGINATION,
} from '../constants/actionTypes';
import { Modal, notification } from 'antd';

import getHttpClient from '../apis/httpclient';

export function getOrders(data = {}) {
  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    dispatch(clearGridData());
    getHttpClient()
      .post('/transaction/filters', data)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.results &&
          response.data.data.results instanceof Array
        ) {
          dispatch({
            type: RECEIVE_ORDER_GRID_DATA,
            payload: response.data.data,
          });
        } else {
          return Promise.reject();
        }
      })
      .catch((error) => {
        console.log(error);
        notification.error({ message: 'Failed to Load Data' });
        dispatch(clearGridData());
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function setPagination(paginationObject) {
  return (dispatch) => {
    dispatch({ type: SET_ORDER_GRID_PAGINATION, payload: paginationObject });
  };
}

export function setGridFilterQuery(filterQuery) {
  return (dispatch) => {
    dispatch({ type: SET_ORDER_GRID_FILTER_QUERY, payload: filterQuery });
  };
}
function clearGridData() {
  console.log('clearGridData called');

  return (dispatch) => {
    dispatch({
      type: RECEIVE_ORDER_GRID_DATA,
      payload: {
        currentPage: 1,
        results: [],
        resultsPerPage: 0,
        totalPages: 1,
        totalResults: 0,
      },
    });
  };
}

export function getOrderItemData(paymentKey) {
  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    dispatch(clearGridData());
    getHttpClient()
      .get(`/transaction/${paymentKey}`)
      .then((response) => {
        if (response && response.data && response.data.data) {
          dispatch({
            type: RECEIVE_ORDER_ITEM_DATA,
            payload: response.data.data,
          });
        } else {
          return Promise.reject();
        }
      })
      .catch((error) => {
        console.log(error);
        notification.error({ message: 'Failed to Load Data' });
        dispatch({
          type: RECEIVE_ORDER_ITEM_DATA,
          payload: { error: 'Order not found !', errorCode: 404 },
        });
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function completeOrder(paymentKey, status) {
  console.log('service completeOrder method');
  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .put(`/transaction/${status}/${paymentKey}`)
      .then((res) => {
        let content = status === 'complete' ? `You have successfully completed the order!` : `You have changed the order to delivery in progress!`
        Modal.success({
          content
        });
        dispatch(getOrderItemData(paymentKey));
      })
      .catch((error) => {
        console.log(error);
        notification.error({ message: 'Failed to complete the order' });
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}
