import React from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import { Typography, Layout, Row, Col,Card } from 'antd';
import contactUsImg from '../../assets/ideameet-bg.png';
import dialogLogo from '../../assets/dialog_logo.svg';
import primaryLogo from '../../assets/primary.svg';
import PageFooter from '../common/PageFooter';
import { click2PayCustomerHotline, click2PayCustomerEmail } from '../../constants/constants';

const { Content, Footer } = Layout;
const { Title } = Typography;
class ContactUsPage extends React.Component {

  componentDidMount() {}

  render() {
    return (
      <Layout className="site-layout" style={{ marginTop: 15 }}>
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <div className="responsive-max-width-container">
            <Row gutter={20}>
              
              <Col span={24} style={{ marginTop: 15 }} className="provisioning-sidebar">
                <br />
                <Card className="round-corner paddingless-actions contact-us-card"
                  title="  " style={{ align: 'center', padding: '0 0 0 0' }}
                  actions={[
                    <div key="k1"
                      style={{
                        background: '#E6E6FF',
                        height: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                        justify: 'center'
                      }}>
                        <img src={primaryLogo} alt="contact-us-img" style={{ align:'right', width: "100px" }} />
                    </div>,
                  ]}>
                  <Row>
                    
                    <Col span={24}>
                      <Title level={1}>Contact Us</Title>
                      <p> Have any question on how to proceed with Click2Pay? Lets see how we can help
                              you to enhance your Click2Pay experience.</p>
                      <p> Give us a call or contact us via our email.</p>
                      <p> Phone (Biz Hrs) : {click2PayCustomerHotline} </p>
                      <p> Email (Biz Hrs) : {click2PayCustomerEmail}</p>
                      <br/>
                      
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <br /><br/>
            <PageFooter/>
            {/* <Footer style={{ margin: '0px 50px' }}>
              <div style={{ width: '1px', height: '1px', overflow: 'hidden' }}>
              </div>
              <Row align="stretch" justify="space-between">
                <Col>
                  <img src={dialogLogo} alt="dialog-logo" />
                  <span className="copyright-txt">
                    &nbsp;&nbsp; © 2021 Copyright. Engineered by Ideamart
                  </span>
                </Col>
                <Col>
                  <Row className="footer-right-menu">
                    <Col><a href="#">Licence</a></Col>
                    <Col><a href="#">Privacy</a></Col>
                    <Col><a href="#">Terms</a></Col>
                  </Row>
                </Col>
              </Row>
            </Footer> */}
          </div>
        </Content>
      </Layout>
    );
  }
}

export default connect()(hot(module)(ContactUsPage));
