import { Layout } from 'antd';
import React from 'react';
import licenseCover from '../assets/landing/license.webp';
import Header from './common/Header';
import Footer from './common/Footer';

const { Content } = Layout;
const License = () => {
    return (
        <>
            {/* loader */}
            <div className="preloader">
                <div className="loader">
                    <div className="ytp-spinner">
                        <div className="ytp-spinner-container">
                            <div className="ytp-spinner-rotator">
                                <div className="ytp-spinner-left">
                                    <div className="ytp-spinner-circle"></div>
                                </div>
                                <div className="ytp-spinner-right">
                                    <div className="ytp-spinner-circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* header */}
            <header className="header-area">
                <Header />
                <div id="home" class="header-hero bg_cover d-lg-flex align-items-center">
                    <div class="container">
                        <div class="row align-items-center justify-content-center justify-content-lg-between">
                            <div class="col-lg-6 col-md-10">
                                <div class="header-hero-content">
                                    <h3 class="header-title wow fadeInUp" data-wow-duration="0.7s" data-wow-delay="0.2s">License</h3>
                                    <p class="text wow fadeInUp" data-wow-duration="0.7s" data-wow-delay="0.6s">Crediting tools, software</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-10">
                                <div class="header-image">
                                    <img src={licenseCover} alt="Header main cover image" class="image wow fadeInRightBig" data-wow-duration="1.3s" data-wow-delay="0.5s" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="header-shape-1 d-none d-md-block"></div>
                </div>
            </header>

            {/* section */}
            <section id="features" class="features-area pt-110">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-12">
                            <ul>
                                <li>
                                    <div className="features-items">
                                        <h3>Illutrations</h3><br />
                                        <p>Illustration by Freepik Stories.</p>
                                    </div>
                                </li><br />
                                <li>
                                    <div className="features-items">
                                        <h3>Design tools</h3><br />
                                        <p>Designed by using Sketch.</p>
                                    </div>
                                </li><br />
                                <li>
                                    <div className="features-items">
                                        <h3>Other branding Logos, Colors, Names, and pictures</h3><br />
                                        <p>Any third party logo or branding shown in this page owned by their respect owners.</p>
                                    </div>
                                </li><br />
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default License;
