import {
  RECEIVE_PRODUCT_GRID_DATA,
  RECEIVE_PRODUCT_ITEM_DATA,
  SET_GRID_PAGINATION,
  SET_LOADER,
  SET_PRODUCT_CREATION_STATUS,
  SET_PRODUCT_GRID_FILTER_QUERY,
} from '../constants/actionTypes';
import { Modal, notification } from 'antd';

import getHttpClient from '../apis/httpclient';
import { ProductStatus } from '../constants/enums';
import {
  getResultsFromResponse,
  getErrorMessageFromResponse,
} from '../utils/actionHelpers';

export function getProducts(data = {}) {
  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    dispatch(clearGridData());
    getHttpClient()
      .post('/product/filters', data)
      .then((response) => {
        const data = getResultsFromResponse(response ? response.data : null);
        if (data) {
          dispatch({
            type: RECEIVE_PRODUCT_GRID_DATA,
            payload: data,
          });
        } else {
          return Promise.reject();
        }
      })
      .catch((error) => {
        console.log(error);
        notification.error({ message: 'Failed to Load Data' });
        dispatch(clearGridData());
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function addNewItem(data) {
  console.log('add new item called');

  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });

    const formData = new FormData();
    formData.append('name', data.itemdetail);
    formData.append('price', data.price);
    formData.append('deliveryAvailable', data.homedelivery);
    if (data.homedelivery) {
      formData.append('deliveryFee', data.deliveryfee);
    }
    formData.append('description', data.description);
    formData.append('oneTimeUrl', data.availabilitytype);
    for (let i = 0; i < data.image.fileList.length; i++) {
      const f = data.image.fileList[i];
      console.log(f);
      formData.append(`images`, f.originFileObj);
    }
    getHttpClient()
      .post('/product', formData)
      .then((res) => {
        dispatch({ type: SET_PRODUCT_CREATION_STATUS, payload: res.data.data });
        Modal.success({
          content:
          'Hooray! You have successfully created an item/service.\n'+
          'You can now copy and share the link with your customers.'
        });
        return res;
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        Modal.error({
          content: getErrorMessageFromResponse(
            error ? error.response : null,
            'Item/Service creation is failed. Please try\n' + 'again.',
          ),
        });
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function fetchItem(itemId) {
  console.log('fetch item called');

  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .get(`/product/${itemId}`)
      .then((res) => {
        if (res && res.data && res.data.data && res.data.data.product) {
          dispatch({
            type: RECEIVE_PRODUCT_ITEM_DATA,
            payload: res.data.data.product,
          });
        } else {
          return Promise.reject();
        }
        return res;
      })
      .catch((e) => {
        console.error(e);
        if (e && e.response && e.response.statusText) {
          dispatch({
            type: RECEIVE_PRODUCT_ITEM_DATA,
            payload: {
              error: e.response.statusText,
              errorCode: e.response.status,
            },
          });
        } else {
          dispatch({
            type: RECEIVE_PRODUCT_ITEM_DATA,
            payload: {
              error: 'An error has occurred during fetching  data',
              errorCode: 500,
            },
          });
        }
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}
export function changeItemStatus(itemData) {
  console.log('changeItemStatus called');
  let state;
  if (itemData.status === ProductStatus.INACTIVE) {
    state = ProductStatus.ACTIVE;
  } else {
    state = ProductStatus.INACTIVE;
  }

  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .put(`/product/${itemData.code}/changeStatus`, {
        // status: state,
      })
      .then((res) => {
        dispatch({
          type: RECEIVE_PRODUCT_ITEM_DATA,
          payload: Object.assign({}, itemData, { status: state }),
        });
        Modal.success({
          content: `You have successfully ${
            state === ProductStatus.ACTIVE ? 'activated' : 'deactivated'
          } the item/service!`,
        });
        return res;
      })
      .catch((e) => {
        console.error(e);
        Modal.error({
          content: 'Failed to change the item/service status!',
        });
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}
export function clearItemData() {
  console.log('clearItemData called');

  return (dispatch) => {
    dispatch({ type: RECEIVE_PRODUCT_ITEM_DATA, payload: null });
  };
}
function clearGridData() {
  console.log('clearGridData called');

  return (dispatch) => {
    dispatch({
      type: RECEIVE_PRODUCT_GRID_DATA,
      payload: {
        currentPage: 1,
        results: [],
        resultsPerPage: 0,
        totalPages: 1,
        totalResults: 0,
      },
    });
  };
}

export function setPagination(paginationObject) {
  return (dispatch) => {
    dispatch({ type: SET_GRID_PAGINATION, payload: paginationObject });
  };
}
export function setGridFilterQuery(filterQuery) {
  return (dispatch) => {
    dispatch({ type: SET_PRODUCT_GRID_FILTER_QUERY, payload: filterQuery });
  };
}
export function resetProductSubmitState(state) {
  return (dispatch) => {
    dispatch({ type: SET_PRODUCT_CREATION_STATUS, payload: state });
  };
}
