import { TransactionStatus } from './enums';

export const defaultPagination = {
  limit: 10,
  offset: 0,
};

export const ordersDefaultQuery = {
  ...defaultPagination,
  relations: ['product'],
  betweens: [],
  contains: [],
  equals: ['refundTxId=0'],
  // equals: [`status=${TransactionStatus.COMPLETE}`,`status=${TransactionStatus.PENDING}`],
  selects: ['paymentKey', 'price', 'transStatus', 'orderDate', 'invoiceNumber', "deliverFee", "deliverAvailable", 'product.name', 'createdOn'],
  sort: ["id-"]
};

export const productsDefaultQuery = {
  ...defaultPagination,
  "relations": [],
  "selects": ["name", "price", "status", "createdOn","deliveryFee", "deliveryAvailable", "code"],
  "equals": [],
  "sort": ["createdOn-"]
};

export const click2PayCustomerHotline = "0773300330";
export const click2PayCustomerEmail = "support@click2pay.lk";
