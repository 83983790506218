import React from 'react';
import PropType from 'prop-types';
import { Badge, Avatar } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';

class ThumbWithDeleteAction extends React.Component {
  render() {
    return (
      <span
        className="avatar-item"
        onClick={() => this.props.onActionTriggered(this.props.thumb)}
      >
        <Badge count={<CloseCircleFilled />}>
          <Avatar shape="square" size={64} src={this.props.thumb.url} />
        </Badge>
      </span>
    );
  }
}

ThumbWithDeleteAction.propTypes = {
  thumb: PropType.object,
  onActionTriggered: PropType.func,
};

export default ThumbWithDeleteAction;
