import React from 'react';
import PropType from 'prop-types';
import { Input, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

class SPToggleButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.isChecked || false,
    };
  }

  render() {
    return (
      <div className="sp-toggle">
        <Input
          disabled
          value={
            this.state.checked
              ? this.props.checkedText || 'On'
              : this.props.uncheckedText || 'Off'
          }
        />
        <Switch
          disabled={this.props.disabled}
          checked={this.state.checked}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={
            this.props.onChange
              ? (v) => {
                  this.props.onChange(v);
                  this.setState({ checked: v });
                }
              : null
          }
          name={this.props.name || 'unswitch'}
        />
      </div>
    );
  }
}

SPToggleButton.propTypes = {
  name: PropType.string,
  onChange: PropType.func,
  isChecked: PropType.bool,
  disabled: PropType.bool,
  checkedText: PropType.string,
  uncheckedText: PropType.string,
};

export default SPToggleButton;
