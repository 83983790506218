import React from 'react';
import { Row, Col, Input, DatePicker, Select, Button } from 'antd';
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import PropType from 'prop-types';
import { productsDefaultQuery } from '../../constants/constants';
import * as moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;
class ProductFilters extends React.Component {
  constructor() {
    super();
    this.state = {
      id: null,
      name: null,
      date: null,
      dateQuery: null,
      status: null,
    };
  }

  render() {
    const onFilterClicked = () => {
      const contains = [];
      const betweens = [];
      const equals = [];
      if (this.state.id) {
        contains.push(`id=${this.state.id}`);
      }
      if (this.state.name) {
        contains.push(`name=${this.state.name}`);
      }
      if (this.state.status) {
        equals.push(`status=${this.state.status}`);
      }
      if (this.state.dateQuery) {
        betweens.push(this.state.dateQuery);
      }

      this.props.onFilterClicked(
        Object.assign({}, productsDefaultQuery, { contains, betweens, equals }),
      );
    };
    const onIdChange = (e) => {
      this.setState({ id: e.target.value });
    };
    const onNameChange = (e) => {
      this.setState({ name: e.target.value });
    };
    const onDateChange = (value) => {
      if (value) {
        this.setState({
          dateQuery: `createdOn,${moment.utc(value[0]).startOf('day')},${moment.utc(
            value[1],
          ).endOf('day')}`,
          date: value,
        });
      }
    };
    const onStatusChange = (value) => {
      this.setState({ status: value });
    };

    const onFilterReset = () => {
      this.setState({
        id: null,
        name: null,
        date: null,
        status: null,
        dateQuery: null,
      });
      this.props.onFilterClicked(productsDefaultQuery);
    };

    return (
      <div
        className={`dashboard-filters product-filters ${
          this.props.visible ? 'visible' : 'hidden'
        }`}
      >
        <Row align="middle" justify="center" gutter={10}>
          <Col span={3} className="filter-col">
            <span className="filter-title">Item/Service ID</span>
            <Input
              value={this.state.id}
              onChange={onIdChange}
              placeholder="Eg: 324"
            />
          </Col>
          <Col span={4} className="filter-col">
            <span className="filter-title">Item/Service name</span>
            <Input
              value={this.state.name}
              onChange={onNameChange}
              placeholder="Eg: Chemistry"
            />
          </Col>
          <Col span={6} className="filter-col">
            <span className="filter-title">Created date</span>
            <RangePicker
              disabledDate={(current) =>
                current && current > moment().endOf('day')
              }
              format="YYYY-MM-DD"
              onChange={onDateChange}
              value={this.state.date}
            />
          </Col>
          <Col span={4} className="filter-col">
            <span className="filter-title">Status</span>
            <Select
              value={this.state.status}
              placeholder="Eg:- Active"
              onSelect={onStatusChange}
            >
              <Option value="ACTIVE">ACTIVE</Option>
              <Option value="INACTIVE">INACTIVE</Option>
              <Option value="SUSPENDED">SUSPENDED</Option>
              <Option value="EXPIRED">EXPIRED</Option>
            </Select>
          </Col>
          <Col span={3} className="filter-col">
            <span className="filter-title">&nbsp;</span>
            <Button onClick={onFilterClicked} className="sp-primary-alt">
              <SearchOutlined /> Apply
            </Button>
          </Col>
          <Col span={3} className="filter-col">
            <span className="filter-title">&nbsp;</span>
            <Button onClick={onFilterReset} className="sp-primary-alt">
              <UndoOutlined /> Reset
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

ProductFilters.propTypes = {
  visible: PropType.bool,
  onFilterClicked: PropType.func,
};
export default ProductFilters;
