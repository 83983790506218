import React from 'react';
import { Card, Checkbox } from 'antd';
import PropType from 'prop-types';
import { DownCircleFilled } from '@ant-design/icons';
import { click2PayCustomerHotline, click2PayCustomerEmail } from '../../constants/constants';

const ProvisionTOC = ({ onAgreeChecked }) => {

  //window.scrollTo(0, 0)
  return (
    <Card
      className="provision-content-container"
      title="Terms & Conditions"
      bordered={false}
    >
      <div>
        <div className="toc-cont" id="toc-cont">
        <ul>
            <li>
                <div className="features-items">
                    {/* <h3>1. Introduction</h3><br /> */}
                    <p>The Terms and Conditions set forth below shall govern your use of the Click2Pay Platform (“Platform”) owned and operated by Dialog Axiata PLC (“Dialog”) and the services offered via the Platform (“Services”) and your relationship with Dialog Axiata PLC (Company No: PQ 38), a company incorporated under the laws of Sri Lanka and having its registered office at No. 475, Union Place, Colombo 2, Sri Lanka. Additionally, your use of the Platform and its Services shall be subject to the Privacy Policy found at  <a href="https://www.dialog.lk/privacy-policy" target="_blank">https://www.dialog.lk/privacy-policy</a>. Please read and understand these Terms and Conditions as they apply to your access and use of the Platform.</p>
                    <p>By using the Platform, you indicate that you have read, understood and accepted these Terms and Conditions. If you do not wish to be bound by these Terms and Conditions, you may not access the Platform or use the Services. </p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>1. USE OF THE PLATFORM</h3><br />
                    <p>1.1. The Platform is provided to you for your use subject to these Terms and Conditions. By using the Platform, you agree to be bound by these Terms and Conditions and are also responsible for ensuring that all persons who access the Platform through your internet connection or Click2Pay account are aware of these Terms and Conditions and that they comply with them.  </p>
                    <p>1.2. We reserve the right at all times (but will not have an obligation) to remove or refuse to distribute any content on the Platform, to suspend or terminate you as a user of the Platform, and to reclaim your account without any liability to us. We also reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to: (i) satisfy any applicable law, regulation, legal process or governmental request, (ii) enforce these Terms and Conditions, including investigate potential violations hereof, (iii) detect, prevent, or otherwise address fraud, money laundering, illegal activities, security or technical issues, (iv) respond to user support requests, and/or (v) protect the rights, property or safety of Dialog, its users and the public.</p>
                    <p>1.3. Dialog reserves the right to prescribe a transaction limit which will be updated in the terms and conditions and if possible will communicated by electronic means. It is your responsibility to read and adhere accordingly.</p>
                    <p>1.4. We do not guarantee that the Platform will be compatible with all hardware and software which you may use. We will not be liable for damage to, or viruses or other codes that may affect, any computer or mobile equipment, software, data or other property as a result of your access to or use of the Platform.</p>
                    <p>1.5. Any applicable rates and charges for the usage of the Platform and its Services may be varied by Dialog from time to time. You can obtain the current rate of fees and charges on the Platform at any time.</p>
                    <p>1.6. You ensure that you only sell, display or list for sale, products and/or services that are available for delivery or pick up in the normal course of your business.</p>
                    <p>1.7. You will ensure that you only offer products and/or services for sale only if you have the legal right to market and sell such products and/or services and products that you currently have in-stock or services that you currently can accommodate.</p>
                    <p>1.8. You will not sell products and or services that are prohibited or banned in terms of Sri Lankan law. </p>
                    <p>1.9. You will not use the Platform or its Services to engage in illegal or fraudulent activities. </p>
                    <p>1.10. Service Fee: <br/>For each transaction that your customer completes by paying via your Click2Pay link, below commission charges will be retained by us based on the payment method your customer has used;<br/></p>
                    <ol>
                        <li>1.  Ezcash 5% commission</li>
                        <li>2.  Genie (Credit/Debit cards or bank accounts) 5% commission</li>
                        <li>3.	Add to Mobile bill (Dialog) – 20% convenience fee will be charged from customer on top of your price & delivery cost where applicable</li>
                        <br/>
                    </ol>
                    <p>Dialog shall only remit the sums payable to you subject to the;</p>
                    <ol>
                        <li>a)	reduction of the relevant commission rates as mentioned above; and</li>
                        <li>b)	the total payable sum (after deduction of (a) above and any other amounts to be withheld or deducted) remaining in Your account is Sri Lankan Rupees Two Thousand (LKR 2000/-) or more.</li>
                        <br/>
                    </ol>
                    <p>In the event Your total payable sum remaining in Your account for a respective week is below LKR 2,000/-, the remaining sum shall be taken forward as a balance to the next successive week settlement cycle.</p>
                    <p>You may only dispute transaction fees or other charges charged to you within 1 month from the date of transaction or date of such charges. Dialog shall make all endeavors to settle or resolve such dispute and you agree that the decision of Dialog with regard to such disputes will be final and conclusive. The transaction logs available with Dialog will be deemed as authentic source of information for any dispute and the information presented from the said logs shall be final and conclusive evidence for dispute resolution.</p>
                    <p>1.11. Customer Complaints <br/>In the event of complaints from your customers, these should be promptly addressed by you within 24 hours of escalation by your customer. If the customer contacts us we would direct the customer to resolve the issue with you. Where the complaint is with regard to the quality of the service, or product or delivery or fulfillment, these complaints should be resolved by you within a day of escalation by customer. If the customer reaches us with proof that you have not provided the service or product as per expectation, we reserve the right to hold payment, or deduct payment from your future funds until you confirm the issue has been rectified. Based on the gravity of the issue we may also block your account at any time.</p>
                    <p>Also, if there are any complaints with regard to payment issues or errors, in the event the customer informs you of this, you may escalate this issue to us via email and we will check and revert accordingly. We will not be handling your end customers queries directly.</p>
                    <p>Where the acquirer bank related to Genie or ezCash transactions requests for a chargeback initiated by your end user, please note that the payment with regard to such transaction will be withheld or deducted from your future payments. However, it is required for you to provide proof of product /service and proof of delivery and fulfillment immediately by way of scanned/hardcopies of the copy of receipt issued to the customer, copy of delivery note to customer, copy of a confirmation of receipt by customer. You are required to keep such records for at least a period of one year from date of transaction and you are required to share this within 48 hours of request by the Click2Pay team.</p>
                    <p>The contact information provided below is only for your inquiries and you may not provide the below mentioned contact details to your customers or end users for their queries. All your inquiries are to be directed to;<br/>Email: {click2PayCustomerEmail}<br/>Hotline: {click2PayCustomerHotline}</p>
                    <p>1.12. Reversal of Payments: <br/>Refunds: In the event any refunds are required for an order that has been cancelled , or not delivered, or returned by a customer due to an issue and you chose to provide the refund to the customer, you are required to inform Click2Pay team to process the said refund. You shall not refund this amount via cash or other means without consulting the Click2Pay team.</p>
                    <p>1.13. Invoice to Customer: You shall provide an invoice/receipt to the customer digitally or as a hard copy as Click2Pay is only acting as a facilitator to allow you to make a transaction by communicating digitally with the customer. Click2Pay shall not at any time issue invoices or receipts to your customer except a transaction confirmation on the portal after a payment is made.</p>
                    <p>1.14. Reconciliation and Settlements: Reconciliation of transactions and settlements will occur on a monthly basis. Payment shall be remitted to a valid bank account that you provide (with the requested proof documentation) at the point of creating your account, by the 10th of the following month (the period of settlement may change from time-to-time and you will be updated accordingly). You can view your successful transactions on the Platform at any time.</p>
                    <p>1.15. We will not be responsible for the quality and merchantability of the products and/or services that you sell or display on your website or on other platforms.</p>
                    <p>1.16. It shall be your responsibility to ensure that only authorised transactions are performed by end users on the Platform or via the link that will be generated by Dialog.</p>
                    <p>1.17	It shall be your responsibility to display terms and conditions and privacy policy which shall be applicable to your end user and will ensure that adequate security measures and protocols are provided in ensuring the integrity of transactions performed through your platform and/or website.</p>
                    <p>1.18	You will be responsible for your access to the Account created by you.</p>
                    <p>1.19	Dialog will be not be responsible for any financial loss due to your Account being used by unauthorized users, manipulation of data or for any other reason. Furthermore, you shall not commit any form of forgery or use illegal methods to utilize the Platform. </p>
                    <p>1.20	You shall not provide false contact information and business/merchant information.</p>
                    <p>1.21	You shall provide any additional information which Dialog may reasonably request  from time to time failing which it may result in the suspension or termination of your account.</p>
                    <p>1.22. You should validate with your end user and include costs of delivery charges as is applicable to the end user. You are responsible for ensuring the products/services is delivered to your end user. If the product/services is not delivered to the end user, the amounts may not be settled to you, or will be deducted from future funds.</p>
                    <p>1.23. Any disputes with your end user should be resolved amicably by you and Dialog will not be responsible to resolve such issues, except provide transaction information and contact information, if required.</p>
                    <p>1.24. Provide detailed information of services or products delivered or handed over to the end user, and provide documentary evidence such as receipts, delivery confirmation documents within 2 days of request for such information.</p>
                    <p>1.25. You will not use the Platform to collect payments for illegal purposes. Such transactions may be blocked or charged back. </p>
                    <p>1.26. Your Account may be blocked or an item may be blocked or suspended without any notice whatsoever, if there are any suspicious activity or complaints received from end users.</p>
                    <p>1.27. You will not fraudulently register an Account. If Dialog discovers that you are impersonating any other person or entity, you will be solely responsible for any liability that may arise as a result of such fraudulent activity. Abusing the Platform may result in immediate termination / suspension of your Account and will be reported to the relevant authorities.</p>
                    <p>1.28. The use of the Platform is subject to such other applicable specific terms, conditions, rules, directions and regulations as specified by the Central Bank of Sri Lanka and / or any other regulatory or governmental authority.</p>
                    <p>1.29. You shall be responsible for checking and verifying all transactions including, without limitation, the amount and recipient information. Dialog shall not entertain any request to reverse a wrongful entry as a result of your negligence.</p>
                    <p>1.30. Dialog is not responsible for any transactions made through the Platform between you and transacting third parties. Any dispute, error and mistake in transactions must be resolved between you and the transacting party. Dialog will facilitate the resolution and settlement of any such dispute to its best effort.</p>
                    <p>1.31. Apart from these Terms and Conditions, the Terms and Conditions of the payment methods associated with the Platform shall also apply to you. </p>
                    <p>1.32. The privacy of end users is important to Dialog and You shall keep all and any information of the end users and the Service in strict confidence and shall not disclose same unless required by a court of law or government authority with competent jurisdiction. Your usage of the Platform and/or the Service will at all times be subjected to the privacy policy located at <a href="https://www.dialog.lk/privacy-policy" target="_blank">https://www.dialog.lk/privacy-policy</a>as amended from time to time.</p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>2. SERVICES</h3><br />
                    <p>2.1. The Platform offers the below Services:
                        <br/>
                        This Platform allows you to create an Account (profile) which should be validated via documents and approved by the Click2Pay team. Once the account is approved you can create product or service pages for generic products or services with prices or specific products and services to be used only one time by one customer. You can share this link with your potential customer via SMS or any other social media or direct messaging service. Your customer can then view the product and if your customer is satisfied with the details provided in the product/service page, your customer may proceed to pay via available payment methods. 
                        <br/>
                        Click2Pay will notify you on your dashboard if the payment was successful and you are then responsible to deliver or fulfil the product or the service to the customer retaining proof of such delivery/fulfillment.
                        <br/>
                        Click2Pay will collect your funds (for all successful transactions as at the 24th of each month) and settle you periodically (on a monthly basis). However, this is subject to change with prior notification.  
                    </p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>3. AMENDMENTS</h3><br />
                    <p>Dialog may modify the Terms and Conditions at any time. We will notify you of the changes by posting the new Terms and Conditions on the Platform. Such modification shall be effective immediately upon posting of the modified Terms and Conditions. Your continued access to this Platform and usage of Services thereafter shall constitute your acceptance of the revised Terms and Conditions by you. If you do not wish to accept the revised Terms and Conditions, you should not continue to use the Platform.</p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>4. REGISTRATION</h3><br />
                    <p>4.1. To register on the Platform, you must be over eighteen (18) years of age.  </p>
                    <p>4.2. For corporate entities, the said corporate entities will have to submit the required documentation to Dialog as will be communicated by Dialog.</p>
                    <p>4.3. Any personal information that you may supply to this Platform in the process of obtaining Services will be used by Dialog in accordance with the Privacy Policy. By using the Platform, you warrant that all information submitted to us, whether via the Platform or otherwise, is true, accurate, complete and is not misleading by omission or otherwise. In the event of any change to the information provided, you should inform us immediately of this in order for us to communicate with you and for you to continue using the Platform. </p>
                    <p>4.4. Your registration is strictly conditioned upon your acceptance and compliance with these Terms and Conditions. </p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>5. PASSWORD AND SECURITY</h3><br />
                    <p>5.1. When you register to use the Platform, you will be asked to create a password. In order to prevent fraud, you must keep this password strictly confidential and must not disclose it or share it with anyone. All actions validated by your password shall be deemed to have been authorized by you. </p>
                    <p>5.2. You will also verify a mobile number on creation of the Account. The mobile number provided to us must be active at all times and if there is any reason that you are not contactable on the mobile number that is provided to us, we may discontinue your access to the Platform and the Services.</p>
                    <p>5.3. In the event your Account can be accessed by unauthorized users, you should immediately notify us and request to reset the password to your Account.</p>
                    <p>5.4. If Dialog has reason to believe that there is likely to be a breach of security or misuse of the Platform, we may require you to change your password or we may suspend your account. </p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>6. INTELLECTUAL PROPERTY RIGHTS</h3><br />
                    <p>6.1. The Platform including all user submissions, text documents, scripts, sounds, photos, logos, tradenames, all trademarks, copyright, database and other intellectual property rights of any nature in the Platform together with the underlying software codes is protected by copyright, trademarks, database rights and other intellectual property rights, as applicable and is the property of Dialog, its subsidiaries, affiliates or Dialog licensors. You may retrieve and display the content of the Platform on a computer screen, mobile phone screen, tablet screen (or equivalent), store such content in electronic form on disk, download or print copies of such content for your own use, provided you keep intact all and any copyright and proprietary notices. You may not reproduce, modify, copy or distribute or use for commercial purposes any of the materials or content on the Platform without prior written permission from Dialog, which may be withheld or conditioned at the sole discretion of Dialog. Furthermore, you may not use our logo or the logo of any payment method displayed on the Platform. </p>
                    <p>6.2. You acknowledge that any software and related documentation that may be available to download from the Platform is the property of Dialog. </p>
                    <p>6.3. We do not have control over third party content published on the Platform and cannot guarantee the authenticity of any content which users may provide generally during the course of their use of the Platform. Therefore, we do not accept liability for any content or material, of whatever nature, that is uploaded to or shared on the Platform that is not uploaded by us, regardless of its provenance. You acknowledge that all content accessed by you using the Platform is at your own risk and you will be solely responsible for any danger or loss to any party resulting therefrom. </p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>7. PROHIBITED USE </h3><br />
                    <p>7.1. You may not use the Platform for inter alia the following purposes: 
                        <br/><br/>
                        <ul>
                            <li>(a)	any activities that violate these Terms and Conditions or any other relevant third party’s terms of service;</li>
                            <li>(b)	disseminating any unlawful, harassing, libelous, abusive, threatening, harmful, vulgar, obscene, or otherwise objectionable material or otherwise breaching any laws; </li>
                            <li>(c)	transmitting material that encourages conduct that constitutes a criminal offence, or otherwise breaches any applicable laws, regulations or codes of practice; </li>
                            <li>(d)	for any purpose which is against public interest, public order, and/or relevant laws and regulations; </li>
                            <li>(e)	interfering with any other user or person’s use or enjoyment of the Platform;</li>
                            <li>(f)	access, tamper with, or use non-public areas of the Platform, Dialog’s computer systems, or the technical delivery systems of Dialog’s providers, which includes causing excessive or disproportionate load on the Platform;  </li>
                            <li>(g)	probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures;  </li>
                            <li>(h)	access or search or attempt to access or search the Platform by any means (automated or otherwise) other than through our currently available, published interfaces that are provided by us (and only pursuant to those Terms and Conditions);  </li>
                            <li>(i)	forge any TCP/IP packet header or any part of the header information in any email or posting;  </li>
                            <li>(j)	reverse engineer, translate, adapt or modify any software used in connection with the Platform; or </li>
                            <li>(k)	interfere with, or disrupt, (or attempt to do so), the access of any user, host or network, including, without limitation, sending a virus, trojan, worm, logic bomb or other material which is malicious or technologically harmful, overloading, flooding, spamming, mailbombing the Platform, or by scripting the creation of content in such a manner as to interfere with or create an undue burden on the Platform. </li>
                            <li>(l)	to display content or product or service images that violate any applicable laws, that are obscene or threatening in any manner. </li>
                        </ul>
                    </p>
                    <p>7.2. In the event of any of the above in Clause 7.1, we reserve the right, at all times, to remove or refuse to distribute any of your material on the Platform, to suspend or terminate your account and we also reserve the right to retain any payments due to you for a period of three (03) months to enable settlement of any disputes.  </p>
                    <p>7.3. You will be responsible for our losses and costs resulting from your breach of this Clause 7 and agree to indemnify and render us harmless in this regard.  </p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>8. AVAILABILITY OF THE PLATFORM</h3><br />
                    <p>8.1. Whilst we will use commercially reasonable efforts to make the Platform available to you without any interruption, we do not guarantee that the Platform will be fault-free. If a fault occurs with the Platform, you should report it to Dialog and we will attempt to correct the fault as soon as we reasonably can. </p>
                    <p>8.2. The Platform is dependent on other third-party service providers, including but not limited to website domain hosts and payment providers. We do not guarantee that the Platform will be available at all times or uninterrupted. As such, we will not be liable to you if for any reason the Platform is unavailable at any time or for any period. </p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>9. TERMINATION AND SUSPENSION  </h3><br />
                    <p>9.1. We may suspend or cancel your account immediately at our reasonable discretion, or if you breach any of your obligations under these Terms and Conditions. We will take reasonable efforts to notify you at the email address provided by you of such suspension or cancellation, or we will notify you at your next attempt to access your account. </p>
                    <p>9.2. The suspension or cancellation of your account and your right to use the Platform shall not affect either party’s statutory rights or liabilities nor shall it be deemed or construed as a waiver of our rights to take any action against you for any loss or damages suffered as a result of your breach of these Terms and Conditions. </p>
                    <p>9.3. Dialog may at its absolute discretion immediately terminate, suspend, impose conditions or restrictions on you in respect of the use of the Platform and Service or change the procedures, the mode of operation of the Platform and Service with notice to you. </p>
                    <p>9.4. Notwithstanding the above, Dialog reserves the right, at its absolute discretion and at any time, to immediately suspend / terminate the use of the Platform and Services for any reason, including but not limited to the following circumstances:
                        <br/><br/>
                        9.4.1.  in the opinion of Dialog, you have indulged in any dishonest, fraudulent, illegal and / or criminal conduct or misrepresentation;
                        <br/>
                        9.4.2   you are in breach of any of the provisions of the Terms and Conditions or has engaged in any conduct prejudicial to Dialog;
                        <br/>
                        9.4.3	you are in breach of any acts, statutes, laws, by-laws, rules and / or regulations imposed by any party, regulatory body or government agency;
                        <br/>
                        9.4.4	you have submitted false documents or has declared false information during the application for the Platform; 
                        <br/>
                        9.4.5	you have acted with bad or malicious intent; and / or
                        <br/>
                        9.4.6	Dialog is of the opinion that your acts are prejudicial to the interests of Dialog.
                    </p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>10. THIRD PARTY LINKS</h3><br />
                    <p>10.1. As a convenience to our users, the Platform may includes links to other websites or material which are beyond Dialog’s control. If you choose to utilize such websites and or material, you shall be bound by terms and conditions attached to such websites and material. Dialog is not responsible for the accuracy or availability of any content on any third party website or links that are outside of the Platform, whether or not such websites belong to, or are hosted by, a user of the Platform.  </p>
                    <p>10.2. Links to such third party websites and services do not constitute an endorsement by us of such websites or services, or the information, products, advertising or other materials made available by such third parties. In addition, Dialog is not liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with your use of such content, information, products or services available on or through such linked websites. </p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>11. INDEMNITY</h3><br />
                    <p>You agree to defend, indemnify and hold us harmless from and against all liabilities, damages, claims, actions, costs and expenses (including legal fees), which may be suffered or incurred by us as a result of or in connection with or arising out of information you submit, post, transmit or make available through the Platform, from your breach of any of these Terms and Conditions, your use of the Platform and/or in connection thereof and from any third party claims. You agree to indemnify and hold Dialog harmless for unauthorized transactions done through the Platform or the links generated by Dialog. </p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>12. DISCLAIMER OF WARRANTIES</h3><br />
                    <p>All content, including software, services, information, text and related graphics contained within or available through the Platform are provided to you on an "as is," "as available" basis. Dialog makes no representations or warranties of any kind, either express or implied, as to the operation of this Platform or the information, content or materials included on this Platform. To the fullest extent permissible pursuant to applicable law, Dialog disclaims all representations, conditions and warranties of any kind, either express or implied, including, but not limited to, the implied conditions or warranties of merchantability or satisfactory workmanlike effort, informational content, title, or non-infringement of the rights of third parties. Dialog does not warrant or make any representations that the Platform will operate error-free or uninterrupted, that defects will be corrected, or that the Platform and/or its Services will be free of viruses and/or other harmful components. Dialog does not warrant or make any representations regarding suitability, availability, accuracy, reliability, completeness, or timeliness of any material of any kind contained within the Platform for any purpose, including software, products, services, information, text and related graphics content.  </p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>13. LIMITATION OF LIABILITY</h3><br />
                    <p>13.1. TO THE EXTENT PERMITTED BY LAW, IN NO EVENT SHALL DIALOG INCLUDING ITS RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, REPRESENTATIVES, SUBSIDIARIES, AFFILIATES, LICENSORS, AGENTS OR OTHERS INVOLVED IN CREATING, SPONSORING, PROMOTING, OR OTHERWISE MAKING AVAILABLE THE PLATFORM AND ITS CONTENTS (COLLECTIVELY THE "COVERED PARTIES"), BE LIABLE TO ANY PERSON OR ENTITY WHATSOEVER FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, COMPENSATORY, CONSEQUENTIAL, OR PUNITIVE DAMAGES OR ANY DAMAGES OR LOSSES WHATSOEVER, INCLUDING BUT NOT LIMITED TO (WHERE RELEVANT, CAUSED BY): (I) LOSS OF PRODUCTION, LOSS OF PROFIT, LOSS OF REVENUE, LOSS OF CONTRACT, LOSS OF OR DAMAGE TO GOODWILL OR REPUTATION, LOSS OF CLAIM, BUSINESS INTERRUPTION, DATA OR OTHER INTANGIBLE LOSSES; (II) YOUR INABILITY TO USE, UNAUTHORIZED USE OF, DELAY, PERFORMANCE OR NON-PERFORMANCE OF THE PLATFORM; (III) UNAUTHORIZED ACCESS TO OR TAMPERING WITH YOUR PERSONAL DATA OR TRANSMISSIONS; (IV) THE PROVISION OR FAILURE TO PROVIDE ANY SERVICE, INCLUDING SERVICES RENDERED OR PRODUCTS OFFERED BY SERVICE PROVIDER (V) ERRORS OR INACCURACIES CONTAINED ON THE PLATFORM OR ANY INFORMATION (INCLUDING BUT NOT LIMITED TO THE (DESCRIPTIVE) INFORMATION (INCLUDING RATES, AVAILABILITY AND RATINGS) OF THE SERVICE AS MADE AVAILABLE ON THE PLATFORM), SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS OBTAINED THROUGH THE PLATFORM; (VI) ANY TRANSACTIONS ENTERED INTO THROUGH THE PLATFORM; (VII) ANY PROPERTY DAMAGE INCLUDING DAMAGE TO YOUR DEVICE OR DEVICES CAUSED BY VIRUSES OR OTHER HARMFUL COMPONENTS, DURING OR ON ACCOUNT OF ACCESS TO OR USE OF THE PLATFORM OR ANY SITE TO WHICH IT PROVIDES HYPERLINKS; OR (VIII) DAMAGES OTHERWISE ARISING OUT OF THE USE OF THE PLATFORM, ANY USE, DELAY OR INABILITY TO USE THE PLATFORM, OR ANY INFORMATION, PRODUCTS, OR SERVICES OBTAINED THROUGH THE PLATFORM; OR (IX) ANY (PERSONAL) INJURY, DEATH, PROPERTY DAMAGE, OR OTHER DAMAGES ATTRIBUTABLE TO 
                        DIALOG (ITS EMPLOYEES, DIRECTORS, OFFICERS, AGENTS, REPRESENTATIVES OR SUBSIDIARIES OR AFFILIATED COMPANIES) (X) ANY DAMAGES CAUSED BY A FORCE MAJEURE EVENT. THE LIMITATIONS OF LIABILITY SHALL APPLY REGARDLESS OF THE FORM OF ACTION, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF A COVERED PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.</p>
                    <p>13.2. Dialog holds no liability for any activity that is performed by you via the Platform and by using the Services that are made available to you in Dialog. This shall include any activity performed by you in managing your account / this Platform and obtaining Service. </p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>14. SEVERANCE</h3><br />
                    <p>If any provision of these Terms and Conditions is prohibited by law or judged by a court to be unlawful, void or unenforceable, the provision shall, to the extent required, be severed from these Terms and Conditions and rendered ineffective as far as possible without modifying the remaining provisions and shall not in any way affect any other circumstances of or the validity or enforcement of these Terms and Conditions.</p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>15. ACKNOWLEDGMENT</h3><br />
                    <p>You agree that you use the Platform and the Services available on the Platform at your own risk. We do not assume any advisory, fiduciary or other similar duties. You agree that you have taken, or will take, the necessary independent legal, tax, financial or other advice before you use the Services on the Platform or contract with another user on the Platform.</p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>16. ELECTRONIC COMMUNICATION</h3><br />
                    <p>16.1. We will communicate with you electronically to engage with you about the services offered through the Platform, enter into agreements as necessary and communicate with you for any other matter connected with your use of the Platform and the Services.</p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>17. APPLICABLE LAW</h3><br />
                    <p>17.1. The Platform and the Terms and Conditions herein contained shall be governed and construed in accordance with the laws of the Democratic Socialist Republic of Sri Lanka.</p>
                    <p>17.2. If the dispute remains unresolved, the parties irrevocably submit to the exclusive jurisdiction of the Courts of Democratic Socialist Republic of Sri Lanka to settle any disputes which may arise out of or in connection with these Terms and Conditions. </p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>18. MISCELLANEOUS</h3><br />
                    <p>18.1. You may not transfer any of your rights under these Terms and Conditions to any other person. We may transfer our rights under these Terms and Conditions to another business or legal entity.</p>
                    <p>18.2. If you breach these Terms and Conditions, no failure or delay by Dialog to exercise any right or remedy provided under these Terms and Conditions shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall prevent or restrict the further exercise of that or any other right or remedy.</p>
                </div>
            </li><br />
            <li>
                <div className="features-items">
                    <h3>19. ANTI BRIBERY AND CORRUPTION</h3><br />
                    <p>19.1. You agree to comply with the clauses pertaining to Anti-Bribery and Corruption located at <a href="https://www.dialog.lk/dialogdocroot/content/pdf/legal/anti-bribery-and-anti-corruption-clauses11.pdf" target="_blank">https://www.dialog.lk/dialogdocroot/content/pdf/legal/anti-bribery-and-anti-corruption-clauses11.pdf</a> or at such other location as DIALOG may determine from time-to-time.</p>
                </div>
            </li><br />
        </ul>
          <div className="toc-checkbox-container" id="toc-check">
            <Checkbox onChange={onAgreeChecked}>
              <span className="lbl">
                I do hereby agree to create this account and to abide by the
                mentioned privacy policy and conditions.
              </span>
            </Checkbox>
          </div>
        </div>
        <div
          className="scroldownlink"
          onClick={() => {
            console.log('dddd');
            const el = document.querySelector('#toc-cont');
            el.scrollTo(0, el.scrollHeight);
          }}
        >
          <span>Scroll down to read & accept before continue</span>
          <br />
          <DownCircleFilled />
        </div>
      </div>
    </Card>
  );
};
ProvisionTOC.propTypes = {
  userInfo: PropType.object,
  onAgreeChecked: PropType.func,
};

export default ProvisionTOC;
