import React from 'react';
import primaryLogo from '../../assets/landing/primary.svg';

class Header extends React.Component {
    constructor() {
        super();
        this.state = {
          baseDomain: 'https://click2pay.lk/',
          toggle_show: '',
          aria_expanded: false
        }
    }

    onToggleNav = () => {
        if(!this.state.aria_expanded){
            this.setState({
                toggle_show: 'show',
                aria_expanded: true
            });
        }else{
            this.setState({
                toggle_show: '',
                aria_expanded: false
            });
        }
    }

    clicked = () => {
        this.setState({
            toggle_show: '',
            aria_expanded: false
        });
    }

    render() {
        return (
            <div className="navbar-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="navbar navbar-expand-lg">
                                <a className="navbar-brand" href="/">
                                    <img src={primaryLogo} alt="click 2pay logo" />
                                </a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                                aria-expanded={this.state.aria_expanded}
                                aria-label="Toggle navigation" onClick={this.onToggleNav}>
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                </button>
                                <div className={this.state.toggle_show ? "collapse navbar-collapse sub-menu-bar "+this.state.toggle_show : "collapse navbar-collapse sub-menu-bar"} id="navbarSupportedContent">
                                    <ul id="nav" className="navbar-nav ml-auto">
                                        <li className="nav-item active">
                                            <a className="page-scroll nav-link" href={`${this.state.baseDomain}#home`} onClick={this.clicked}>What's Click2Pay</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="page-scroll nav-link" href={`${this.state.baseDomain}#why`} onClick={this.clicked}>Why</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="page-scroll nav-link" href={`${this.state.baseDomain}#signup`} onClick={this.clicked}>Learn</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="page-scroll  btn btn-signup" href="https://signup.click2pay.lk/signup">
                                                SIGN UP</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/account-provision" className="page-scroll nav-link">SIGN IN</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        );
    }  
};

export default Header;
