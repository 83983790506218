/* eslint-disable import/no-named-as-default */
import PropTypes from 'prop-types';
import React from 'react';
import {
  SettingOutlined,
  ShoppingOutlined,
  ShopOutlined,
  TagOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Row, Col } from 'antd';
import primaryLogo from '../../assets/primary.svg';
import dialogLogo from '../../assets/dialog_logo.svg';
import UserHeaderControl from '../widgets/UserHeaderControl';
import { Link, Redirect } from 'react-router-dom';
import otpSuccess from '../../assets/otp-success-icon.svg';
import otpVerify from '../../assets/mobile-verify-icon.svg';
import otpSubmit from '../../assets/otp-submit-icon.svg';
import PageFooter from '../common/PageFooter';
import ContactUsPopUp from '../common/ContactUsPopUp';

const { Header, Content, Footer, Sider } = Layout;

class DashboardContainerLayout extends React.Component {
  constructor() {
    super();
    this.state = {
      class_name: '',
      aria_expanded: "false"
    }
  }

  onToggleNav = () => {
    if (this.state.class_name === '') {
        this.setState({
            class_name: 'show',
            aria_expanded: true
        });
    } else {
        this.setState({
            class_name: '',
            aria_expanded: false
        });
    }
}

  render() {
    if (this.props.user && !this.props.user.accountProvisioned) {
      return <Redirect to="/" />;
    }

    return (
      this.props.user &&
      this.props.user.accountProvisioned && (
        <Layout className={this.state.class_name}>
          <Sider width={200} className={this.state.class_name ? "dashboard-sider "+this.state.class_name : "dashboard-sider"}>
            <div className="logo-container">
              <img className="logo" src={primaryLogo} alt={'logo'} />
            </div>
            <div className="sp-menu-title">Menu</div>
            <Menu
              mode="inline"
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              style={{ borderRight: 0 }}
            >
              <Menu.Item icon={<ShoppingOutlined />} key="2">
                <Link to="/products">Items & Services</Link>
              </Menu.Item>
              <Menu.Item icon={<TagOutlined />} key="4">
                <Link to="/confirmed-orders">Order List</Link>
              </Menu.Item>
              <Menu.Item icon={<ShopOutlined />} key="5">
                <Link to="/reports">Reports</Link>
              </Menu.Item>
            </Menu>

            <div className="sider-bottom-container">
              <Menu>
                <Menu.Item icon={<SettingOutlined />} key="6">
                  <Link to="/dashboard/bank-details">Settings</Link>
                </Menu.Item>
              </Menu>
              <UserHeaderControl
                userName={this.props.user.firstName}
                pathFrom={'dashboard'}
                src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              >
                <Link to="/dashboard/profile">Profile</Link>
              </UserHeaderControl>
            </div>
          </Sider>
          <Layout className="dashboard-container-layout">
            <Header className="header">
              Dashboard&nbsp;
              <span>
                {this.props.currentBreadCrumbPath
                  ? `/ ${this.props.currentBreadCrumbPath}`
                  : ''}
              </span>
            {/* Mobile hamberger menu button */}
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={this.state.aria_expanded} 
              aria-label="Toggle navigation" onClick={this.onToggleNav}>
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
              </button>
            </Header>
            <Content className="dashboard-content-container">
              {this.props.children}
              <ContactUsPopUp/>
            </Content>
            <PageFooter/>
          </Layout>
        </Layout>
      )
    );
  }
}

DashboardContainerLayout.propTypes = {
  children: PropTypes.element,
  isLoadingData: PropTypes.bool,
  user: PropTypes.object,
  currentBreadCrumbPath: PropTypes.string,
};

export default DashboardContainerLayout;
