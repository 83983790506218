import { Layout, Button } from 'antd';
import React from 'react';
import unauthorized from '../assets/unauthorized.svg';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import { bindActionCreators } from 'redux';
import * as commonActions from '../actions/commonActions';

class UnauthorizedPage extends React.Component {

  constructor() {
    super();
  }

  componentDidMount() {
    this.props.commonActions.fetchUserInfo();
  }

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <img src={unauthorized} className="infomation-msg-img" />
        <p className="information-msg-title">Access Denied!</p>
        <p className="infomation-msg-description">
          Your are not authorized to accesss this page. Please create a Click2Pay account and login.
        </p>
        <Button className="sp-primary-alt" href="/">
          Back to Home
        </Button>
      </div>
    );
  }
};

UnauthorizedPage.propTypes = {
  commonActions: PropType.any
};

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(hot(module)(UnauthorizedPage));