import React from 'react';
import { Button, Col, Row, Typography } from 'antd';
import PropType from 'prop-types';
import CollectionReportGrid from './CollectionReportGrid';
import * as reportActions from '../../actions/reportActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DownOutlined } from '@ant-design/icons';
import CollectionReportFilters from './CollectionReportFilters';
import Statistics from '../widgets/Statistics';

const { Title } = Typography;

class CollectionReportView extends React.Component {
  constructor() {
    super();
    this.state = {
      isFiltersShown: true,
    };
  }

  componentDidMount() {
    this.props.reportActions.getReportMetaData();
  }

  componentWillUnmount() {
    this.props.reportActions.clearGridData();
  }

  toggleFilters() {
    this.setState({ isFiltersShown: !this.state.isFiltersShown });
  }

  // onPaginate(paginateObject) {
  // this.props.reportActions.getTransactions(
  //   Object.assign({}, this.props.gridFilterQuery, paginateObject)
  // );
  // this.props.reportActions.setPagination(paginateObject);
  // }

  onFilterClicked(filterObject) {
    // this.props.reportActions.setGridFilterQuery(filterObject);
    this.props.reportActions.getReportGridData(filterObject);
  }

  onDownloadClicked(downloadObject) {
    this.props.reportActions.getReportGridData(downloadObject);
    this.props.reportActions.generateCollectionReport(downloadObject);
  }

  render() {
    return (
      <div className="dashboard-subpage-container">
        <Statistics />
        <div className="subpage-main-content">
          <Title level={3}>Collection Report</Title>
          <Row justify="space-between" className="grid-actions">
            <Col className="left">
              <Button type="link" onClick={this.toggleFilters.bind(this)}>
                Filters <DownOutlined />
              </Button>
            </Col>
            <Col className="right">&nbsp;</Col>
          </Row>
          <CollectionReportFilters
            onFilterClicked={this.onFilterClicked.bind(this)}
            onDownloadClicked={this.onDownloadClicked.bind(this)}
            visible={this.state.isFiltersShown}
            items={this.props.metaItems ? this.props.metaItems : []}
            paymentMethods={
              this.props.metaPaymentMethods ? this.props.metaPaymentMethods : []
            }
          />
          {this.props.transactions ? (
            <CollectionReportGrid
              isLoading={this.props.isLoading}
              data={this.props.transactions}
              // onPaginate={this.onPaginate.bind(this)}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

CollectionReportView.propTypes = {
  transactions: PropType.object,
  metaPaymentMethods: PropType.array,
  metaItems: PropType.array,
  reportActions: PropType.object,
  history: PropType.object,
  isLoading: PropType.bool,
  gridFilterQuery: PropType.object,
  gridPagination: PropType.object,
  user: PropType.object,
  meta: PropType.object,
  gridData: PropType.gridData,
};

function mapStateToProps(state) {
  return {
    transactions: state.reports.gridData,
    gridFilterQuery: state.reports.gridFilterQuery,
    gridPagination: state.reports.gridPagination,
    isLoading: state.common.isLoadingData,
    metaPaymentMethods: state.reports.meta.paymentMethods,
    metaItems: state.reports.meta.items,
    meta: state.reports.meta,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportActions: bindActionCreators(reportActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollectionReportView);
