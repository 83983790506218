import React from 'react';
import { Button, Form } from 'antd';
import otpSuccessIcon from '../../assets/otp-success-icon.svg';
import PropType from 'prop-types';

const OTPSuccessModal = (props) => (
  <>
    <Form
      layout="vertical"
      name="form_in_modal"
      initialValues={{
        modifier: 'public',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <img src={otpSuccessIcon} />
        <p className="otptitle">Number verified</p>
        <p className="otpdescription">
          Your number has been verified.<br/>Please click continue to proceed.
        </p>
      </div>
    </Form>
    <Button
      loading={props.isLoadingData}
      className="sp-primary"
      onClick={props.onContinuePressed || null}
    >
      Continue
    </Button>
  </>
);

OTPSuccessModal.propTypes = {
  onContinuePressed: PropType.func,
  isLoadingData: PropType.bool,
};

export default OTPSuccessModal;
