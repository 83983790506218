import {
  SET_USER_DATA,
  SET_LOADER,
  SET_OTP_VERIFICATION,
  SET_OTP_ID,
  SET_STAT_DATA,
} from '../constants/actionTypes';
import getHttpClient from '../apis/httpclient';
import { notification } from 'antd';
import { getErrorMessageFromResponse } from '../utils/actionHelpers';

export function fetchUserInfo() {
  console.log('called');

  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .get('/merchant/check')
      .then((res) => {
        if (res.error) {
          console.error(res.error);
          throw res.error;
        }
        if (!res.message) {
          dispatch({ type: SET_USER_DATA, payload: res.data.data });
          // dispatch({ type: SET_SETUP_STAGE, payload: res.provisioningState });
        }
        return res;
      })
      .catch((e) => {
        console.error(e);
        dispatch({ type: SET_USER_DATA, payload: '' });
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function fetchStatistics() {
  console.log('called fetchStatistics');

  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .get(`/transaction/statistics`)
      .then((res) => {
        if (res && res.data && res.data.data) {
          dispatch({ type: SET_STAT_DATA, payload: res.data.data });
        }
        return res;
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function verifyOtp(values) {
  console.log(values);

  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .post('/merchant/otp/submitPin', values)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        dispatch({ type: SET_OTP_VERIFICATION, payload: true });
      })
      .catch((error) => {
        notification.error({
          message: getErrorMessageFromResponse(error ? error.response : null),
        });
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function verifyOtpMobile(values) {
  console.log(values);

  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .post('/merchant/otp/verify', values)
      .then((response) => {
        dispatch({ type: SET_OTP_ID, payload: response.data.data.otpId });
      })
      .catch((error) => {
        notification.error({
          message: getErrorMessageFromResponse(error ? error.response : null),
        });
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function setLoaderStatus(loading) {
  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: loading });
  };
}

export function notificationmsg(values) {
  console.log(values);

  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .post('/api/notification/create', values)
      .then((response) => {
        console.log("responce",response)
      })
      .catch((error) => {
        notification.error({
          message: getErrorMessageFromResponse(error ? error.response : null),
        });
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}
