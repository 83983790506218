import React from 'react';
import { Card, Row, Col, Typography } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';
import PropType from 'prop-types';
const { Title } = Typography;

class ProvisionFinal extends React.Component {
  constructor() {
    super();
    this.state = { currentState: 0 };
  }
  componentDidMount() {
    const handle = setInterval(() => {
      if (this.state.currentState === 2) {
        clearInterval(handle);
        if (this.props.onCompleted) this.props.onCompleted();
      } else {
        this.setState((prevState) => ({
          currentState: prevState.currentState + 1,
        }));
      }
    }, 3000);
  }
  render() {
    const provisioningState = this.state.currentState;
    console.log(`currentState: ${this.state.currentState}`);
    window.scrollTo(0, 0)
    return (
      <Card className="provision-content-container" title="" bordered={false}>
        <Row
          className="provision-final-progress"
          justify="space-around"
          align="middle"
        >
          <Col
            id="f-step-1"
            className={provisioningState >= 0 ? 'fstep completed' : 'fstep'}
          >
            {provisioningState >= 0 ? (
              <svg className="checkmark" viewBox="0 0 52 52">
                <circle
                  className="checkmark-circle"
                  fill="none"
                  cx="26"
                  cy="26"
                  r="25"
                />
                <path
                  className="checkmark-check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            ) : (
              <FileDoneOutlined size={16} />
            )}
            &nbsp;&nbsp;Your Data
          </Col>
          <Col
            id="f-step-2"
            className={provisioningState >= 1 ? 'fstep completed' : 'fstep'}
          >
            {provisioningState >= 1 ? (
              <svg className="checkmark" viewBox="0 0 52 52">
                <circle
                  className="checkmark-circle"
                  fill="none"
                  cx="26"
                  cy="26"
                  r="25"
                />
                <path
                  className="checkmark-check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            ) : (
              <FileDoneOutlined size={16} />
            )}
            &nbsp;&nbsp;Account
          </Col>
          <Col
            id="f-step-3"
            className={provisioningState >= 2 ? 'fstep completed' : 'fstep'}
          >
            {provisioningState >= 2 ? (
              <svg className="checkmark" viewBox="0 0 52 52">
                <circle
                  className="checkmark-circle"
                  fill="none"
                  cx="26"
                  cy="26"
                  r="25"
                />
                <path
                  className="checkmark-check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            ) : (
              <FileDoneOutlined size={16} />
            )}
            &nbsp;&nbsp;Click2Pay
          </Col>
        </Row>
        <Row className="banner-txt">
          <Col span={24}>
            <Title
              level={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
            >
              We are processing your data…
            </Title>
          </Col>
          <Col span={24}>
            <Title
              level={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {this.state.currentState === 2
                ? 'Your account has been successfully created. Please wait until admin team approves your account.'
                : 'This might take some times.'}
            </Title>
          </Col>
          {/* <Col span={12} >Sample2</Col> */}
        </Row>
      </Card>
    );
  }
}

ProvisionFinal.propTypes = {
  onCompleted: PropType.func,
};

export default ProvisionFinal;
