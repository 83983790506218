import { Layout, Button } from 'antd';
import React from 'react';
import notFound from '../assets/not-found.svg';

const { Content } = Layout;
const NotFoundPage = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <img src={notFound} className="infomation-msg-img" />
      <p className="information-msg-title">Page Not Found</p>
      <p className="infomation-msg-description">
        Sorry, the page you have visted does not exist.
          </p>
      <Button className="sp-primary-alt" href="/">
        Back to Home
      </Button>
    </div>
  );
};

export default NotFoundPage;
