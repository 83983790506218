import {
  RECEIVE_REPORT_META_DATA,
  RECEIVE_REPORT_GRID_DATA,
  SET_REPORT_GRID_FILTER_QUERY,
  SET_REPORT_GRID_PAGINATION,
} from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

export default function reportReducer(state = initialState.reports, action) {
  switch (action.type) {
    case RECEIVE_REPORT_META_DATA:
      console.log(action.payload);
      return objectAssign({}, state, {
        meta: {
          items: action.payload.itemsServices,
          paymentMethods: action.payload.paymentMethods,
        },
      });
    case RECEIVE_REPORT_GRID_DATA:
      return objectAssign({}, state, {
        gridData: action.payload,
      });
    case SET_REPORT_GRID_PAGINATION:
      return objectAssign({}, state, {
        gridPagination: action.payload,
      });
    case SET_REPORT_GRID_FILTER_QUERY:
      return objectAssign({}, state, {
        gridFilterQuery: action.payload,
      });
    default:
      return state;
  }
}
