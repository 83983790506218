import React, { useState } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import mobileVerifyIcon from '../../assets/mobile-verify-icon.svg';
import PropType from 'prop-types';

const { Title } = Typography;

const OTPSendModal = ({
  currentMobileNumber,
  onSendPressed,
  isLoadingData,
}) => {
  const [fields, setFields] = useState([
    {
      name: ['mobilenumber'],
      value: currentMobileNumber,
    },
  ]);
  return (
    <>
      <Form
        visible={true}
        layout="vertical"
        name="form_in_modal"
        fields={fields}
        // initialValues={{
        //   modifier: 'public',
        // }}
      >
        <div style={{ textAlign: 'center', marginBottom:'20px' }}>
          <img src={mobileVerifyIcon} />
          <p className="otptitle">Verify your given mobile number</p>
          <p className="otpdescription">
            A PIN will be sent to below number.
          </p>
          <Title level={3}>{currentMobileNumber}</Title>
        </div>
        {/* <Form.Item
          name="mobilenumber"
          rules={[
            {
              required: true,
              message: 'Please input mobile number',
            },
          ]}
        >
          <Input type="tel" disabled={true} />
        </Form.Item> */}
      </Form>

      <Button
        loading={isLoadingData}
        className="sp-primary"
        onClick={onSendPressed || null}
      >
        Send PIN
      </Button>
    </>
  );
};

OTPSendModal.propTypes = {
  onSendPressed: PropType.func,
  form: PropType.object,
  currentMobileNumber: PropType.string,
  isLoadingData: PropType.bool,
};

export default OTPSendModal;
