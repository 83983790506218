import React from 'react';
import PropType from 'prop-types';
import Statistics from '../widgets/Statistics';

class DashboardLandingPage extends React.Component {
  getGreeting(){
    const myDate = new Date();
    const hrs = myDate.getHours();

    let greet;

    if (hrs < 12)
      greet = 'Good Morning';
    else if (hrs >= 12 && hrs <= 17)
      greet = 'Good Afternoon';
    else if (hrs >= 17 && hrs <= 24)
      greet = 'Good Evening';

    return greet;
  }
  render() {
    return (
      <div className="dashboard-subpage-container">
        <p className="welcome-msg">
          {this.getGreeting()}, {this.props.user ? this.props.user.firstName : null}
        </p>
        <Statistics />
      </div>
    );
  }
}

DashboardLandingPage.propTypes = {
  user: PropType.object,
};

export default DashboardLandingPage;
