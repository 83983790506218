import {
  RECEIVE_ORDER_GRID_DATA, RECEIVE_ORDER_ITEM_DATA,
  SET_ORDER_GRID_FILTER_QUERY,
  SET_ORDER_GRID_PAGINATION,
} from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

export default function orderReducer(state = initialState.orders, action) {
  switch (action.type) {
    case RECEIVE_ORDER_GRID_DATA:
      return objectAssign({}, state, {
        gridData: action.payload,
      });
    case RECEIVE_ORDER_ITEM_DATA:
      return objectAssign({}, state, {
        itemData: action.payload,
      });
    case SET_ORDER_GRID_PAGINATION:
      return objectAssign({}, state, {
        gridPagination: action.payload,
      });
    case SET_ORDER_GRID_FILTER_QUERY:
      return objectAssign({}, state, {
        gridFilterQuery: action.payload,
      });
    default:
      return state;
  }
}
