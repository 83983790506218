import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { connect, Provider } from 'react-redux';
import ProvisionContainerLayout from './containers/ProvisionContainerLayout';
import { Redirect, Route, Switch } from 'react-router-dom';
import NotFoundPage from './NotFoundPage';
import AppHome from './AppHome';
import ProductsPage from './products';
import DashboardPage from './dashboard';
import OrdersPage from './orders';
import ReportsPage from './reports';
import ContactUsPage from './contactUs';
import PendingApprovalPage from './PendingApprovalPage';
import C2PKeyCloakProvider from '../utils/C2PKeyCloakProvider';
import TermsAndConditions from './TermsAndConditions';
import CustomerTermsAndConditions from './CustomerTermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import License from './License';
import AccountSuspendedPage from './AccountSuspendedPage';
import UnauthorizedPage from './UnauthorizedPage';

class Root extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(this.props);
    console.log(nextProps);
    if (!this.props.user && nextProps.user) {
      if (nextProps.user.accountProvisioned) {
        if(nextProps.user.profileState === 'pending'){
          this.props.history.push(`/pending-approval`);
        }else if(nextProps.user.profileState === 'suspended'){
          this.props.history.push(`/account-suspended`);
        }else{
          this.props.history.push(`/products`);
        }
      } else {
        this.props.history.push(`/account-provision`);
      }
    }else if(!this.props.user && !nextProps.user){
      this.props.history.push(`/unauthorized`);
    }
  }

  render() {
    const { store, history } = this.props;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path="/" component={AppHome} />
            <Route exact path="/terms" component={TermsAndConditions} />
            <Route exact path="/customerTerms" component={CustomerTermsAndConditions} />
            <Route exact path="/privacy" component={PrivacyPolicy} />
            <Route exact path="/license" component={License} />

            <C2PKeyCloakProvider>
              <Route
                path="/account-provision"
                component={ProvisionContainerLayout}
              />
              <Route path="/dashboard" component={DashboardPage} />
              <Route path="/products" component={ProductsPage} />
              <Route path="/confirmed-orders" component={OrdersPage} />
              <Route path="/reports" component={ReportsPage} />
              <Route path="/contact-us" component={ContactUsPage} />
              <Route path="/pending-approval" component={PendingApprovalPage} />
              <Route path="/account-suspended" component={AccountSuspendedPage} />
              <Route path="/unauthorized" component={UnauthorizedPage} />
              <Route path="/not-found" component={NotFoundPage} />
            </C2PKeyCloakProvider>
            <Route>
              <Redirect to="/not-found" />
            </Route>
          </Switch>
        </ConnectedRouter>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    user: state.common.user,
  };
}

export default connect(mapStateToProps)(Root);
