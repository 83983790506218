export const stage = {
  VERIFY: 'Send',
  OTP: 'Verify',
  SUCCESS: 'Continue',
};
export const ProvisioningState = {
  BANK_DETAILS: 'bank_details', // 0
  TOC: 'toc', //	   1
  MOBILE_VERIFY: 'mobile_verify', // 2
  PROVISION: 'provision', //    3
  COMPLETE: 'complete', //      4
};

export const getProvisionStateNumber = (state) => {
  switch (state) {
    case ProvisioningState.BANK_DETAILS:
      return 0;
    case ProvisioningState.TOC:
      return 1;
    case ProvisioningState.MOBILE_VERIFY:
      return 2;
    case ProvisioningState.PROVISION:
      return 3;
    case ProvisioningState.COMPLETE:
      return 4;
    default:
      return 0;
  }
};

export const ProductStatus = {
  PENDING: 'pending',
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  INACTIVE: 'inactive',
  EXPIRED: 'expired',
};

export const ProfileState = {
  PENDING: 'pending',
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  REJECTED: 'rejected',
  MODIFIED: 'modified',
  DECLINED: 'declined',
};

export const TransactionStatus = {
  PENDING: 'pending',
  COMPLETE: 'complete',
  DISPUTE: 'dispute',
  FAILED: 'failed',
  PAID: 'paid',
  DELIVERY_IN_PROGRES: 'delivery in progress'
};

export const PaymentSlug = {
  GENIE: 'genie',
  ATB: 'atb',
  CC: 'cc',
  EZC: 'ezc',
};

export const getPaymentMethod = (method) => {
  switch (method) {
    case PaymentSlug.EZC:
      return 'EZ Cash';
    case PaymentSlug.ATB:
      return 'Dialog Prepaid';
    case PaymentSlug.CC:
      return 'Card Payment';
    case PaymentSlug.GENIE:
      return 'Genie';
    default:
      return 'Other';
  }
};
