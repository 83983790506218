import {
  CHANGE_OTP_FORM_STATE,
  SAVE_ACCEPT_TOC,
  SET_BANKS,
  SET_BRANCHES,
  SET_LOADER,
  SET_OTP_ID,
  SET_SETUP_STAGE,
  SET_IS_DOC_TYPE_VALUE_NIC_EXISTS,
  SET_IS_DOC_TYPE_VALUE_BR_EXISTS,
  SET_IS_DOC_TYPE_VALUE_EMAIL_EXISTS,
} from '../constants/actionTypes';
import { notification } from 'antd';
import { fetchUserInfo } from './commonActions';

import getHttpClient from '../apis/httpclient';
import { getErrorMessageFromResponse } from '../utils/actionHelpers';

export function saveBankInfo(data) {
  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    const formData = new FormData();
    formData.append('bankName', data.bankName);
    formData.append('bankCode', data.bank);
    formData.append('branchName', data.branchName);
    formData.append('branchCode', data.branch);
    formData.append('accountNumber', data.accountnumber);
    formData.append('accountHolderName', data.accountholdername);
    formData.append('businessName', data.businessName);
    formData.append('nicNumber', data.nicNumber ? data.nicNumber : '');
    formData.append('brNumber', data.brNumber ? data.brNumber : '');

    if(data.otherNatureOfBusiness){
      const otherNatureOfBusiness = "Other-"+data.otherNatureOfBusiness;
      data.natureOfBusiness.push(otherNatureOfBusiness);
      formData.append('natureOfBusiness', data.natureOfBusiness);
    }else{
      formData.append('natureOfBusiness', data.natureOfBusiness);
    }

    for (let i = 0; i < data.passbookproofs.fileList.length; i++) {
      const f = data.passbookproofs.fileList[i];
      console.log(f);
      formData.append(`passbookProofs`, f.originFileObj);
    }
    for (let i = 0; i < data.businessdocuments.fileList.length; i++) {
      const f = data.businessdocuments.fileList[i];
      console.log(f);
      formData.append(`businessRegistrationDocuments`, f.originFileObj);
    }

    formData.append('businessLogo', data.businesslogo.file);

    getHttpClient()
      .post(`/merchant/bankFinancial`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response && response.data && response.data.success) {
          dispatch(fetchUserInfo());
        } else {
          return Promise.reject({ message: response.data.message || null });
        }
      })
      .catch((error) => {
        if (error) {
          notification.error({
            message: getErrorMessageFromResponse(error ? error.response : null),
          });
        }
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function acceptToc() {
  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .post(`/merchant/tocAccept/`)
      .then((res) => {
        dispatch(fetchUserInfo());
        dispatch({ type: SAVE_ACCEPT_TOC, payload: true });
        return res;
      })
      .catch((e) => {
        notification.error({ message: e.message });
        console.error(e);
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function markProvisionAsCompleted() {
  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .post(`/merchant/provision`)
      .then((res) => {
        dispatch(fetchUserInfo());
        dispatch({ type: SAVE_ACCEPT_TOC, payload: true });
        return res;
      })
      .catch((e) => {
        notification.error({ message: e.message });
        console.error(e);
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function changeStep(step) {
  console.log('called');
  return (dispatch) => {
    dispatch({ type: SET_SETUP_STAGE, payload: step });
  };
}

export function getBanks() {
  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .get('/bank')
      .then((response) => {
        dispatch({ type: SET_BANKS, payload: response.data.data });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: SET_BANKS, payload: [] });
        notification.error({ message: error.message });
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function getBranches(branchCode) {
  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .get(`/bank/${branchCode}`)
      .then((response) => {
        dispatch({ type: SET_BRANCHES, payload: response.data.data });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: SET_BRANCHES, payload: [] });
        notification.error({ message: error.message });
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  };
}

export function resetOTPVerification() {
  return (dispatch) => {
    // dispatch({ type: SET_OTP_VERIFICATION, payload: false });
    dispatch({ type: SET_OTP_ID, payload: null });
  };
}

export function setOtpFormState(state) {
  return (dispatch) => {
    dispatch({ type: CHANGE_OTP_FORM_STATE, payload: state });
  };
}

export function checkdocTypeValExists(key, value) {
  return (dispatch) => {
    dispatch({ type: SET_LOADER, payload: true });
    getHttpClient()
      .get(`/merchant/check/${key}/${value ? value:null}`)
      .then((response) => {
        if(key === 'nicNumber'){
          dispatch({ type: SET_IS_DOC_TYPE_VALUE_NIC_EXISTS, payload: response.data });
        }else if(key === 'brNumber'){
          dispatch({ type: SET_IS_DOC_TYPE_VALUE_BR_EXISTS, payload: response.data });
        }else if(key === 'email'){
          dispatch({type: SET_IS_DOC_TYPE_VALUE_EMAIL_EXISTS,payload: response.data})
        }
      })
      .catch((error) => {
        console.log(error);
        if(key === 'nicNumber'){
          dispatch({ type: SET_IS_DOC_TYPE_VALUE_NIC_EXISTS, payload: [] });
        }else if(key === 'brNumber'){
          dispatch({ type: SET_IS_DOC_TYPE_VALUE_BR_EXISTS, payload: []});
        }else if(key === 'email'){
          dispatch({ type:SET_IS_DOC_TYPE_VALUE_EMAIL_EXISTS, payload: []})
        }
        notification.error({ message: error.message });
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
      });
  }
}
