/* eslint-disable import/no-named-as-default */
import PropTypes from 'prop-types';
import React from 'react';
import { hot } from 'react-hot-loader';
import { Switch, Route, Redirect } from 'react-router-dom';
import DashboardLandingPage from '../dashboard/DashboardLandingPage';
import UserProfilePage from '../dashboard/UserProfilePage';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/commonActions';
import LandingPage from '../LandingPage';
import { connect } from 'react-redux';
import DashboardContainerLayout from '../containers/DashboardContainerLayout';
import BankDetailsPage from './BankDetailsPage';
import PendingApprovalPage from '../PendingApprovalPage';
import AccountSuspendedPage from '../AccountSuspendedPage';

class DashboardPage extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    this.props.actions.fetchUserInfo();
    this.props.actions.fetchStatistics();
  }

  render() {
    return this.props.user ? (this.props.user.profileState == 'pending') ? <PendingApprovalPage /> 
    : (this.props.user.profileState == 'suspended') ? <AccountSuspendedPage /> 
    : (
      <DashboardContainerLayout
        match={this.props.match}
        user={this.props.user}
        isLoadingData={this.props.isLoadingData}
      >
        <Switch>
          <Route
            exact
            path={`${this.props.match.path}`}
            render={(props) => (
              <DashboardLandingPage {...props} user={this.props.user} />
            )}
          />
          <Route
            path={`${this.props.match.path}/profile`}
            render={(props) => (
              <UserProfilePage {...props} user={this.props.user} />
            )}
          />
          <Route
            path={`${this.props.match.path}/bank-details`}
            render={(props) => (
              <BankDetailsPage {...props} user={this.props.user} />
            )}
          />
          <Route>
            <Redirect to="/not-found" />
          </Route>
        </Switch>
      </DashboardContainerLayout>
    ) : (
        <LandingPage />
      );
  }
}

DashboardPage.propTypes = {
  children: PropTypes.element,
  match: PropTypes.object,
  isLoadingData: PropTypes.bool,
  user: PropTypes.object,
  actions: PropTypes.object,
  history: PropTypes.object,
  statistics: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    isLoadingData: state.common.isLoadingData,
    user: state.common.user,
    statistics: state.common.statistics,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(hot(module)(DashboardPage));
