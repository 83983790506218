import React from 'react';
import { Layout, Row, Spin, Col } from 'antd';

const { Content } = Layout;
const LandingPage = () => {
  return (
    <Layout style={{ height: '100vh' }}>
      <Content>
        <Row align="middle" justify="center" style={{ height: '100vh' }}>
          <Col>
            <Spin size="large" tip="Loading..." />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default LandingPage;
