import React from 'react';
import {
  Alert,
  Avatar,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Tabs,
  Tag,
  Typography,
  Spin,
  Button,
} from 'antd';
import { CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import PropType from 'prop-types';
import * as merchantActions from '../../actions/merchantActions';
import * as provisioningActions from '../../actions/provisioningActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  nameValidator,
  emailValidator
} from '../../utils/inputValidators';

const { Title } = Typography;
const { TabPane } = Tabs;

class UserProfilePage extends React.Component {

  constructor() {
    super();
    this.profileForm = React.createRef();
    this.state = {
      updatedFields: false,
      updatedProfileDetails: {},
      isEmailExists: false,
      merchantInfo: null,
    }
  }

  onUpdateProfile() {
    this.profileForm.current
    .validateFields()
    .then(() => {
      this.props.merchantActions.updateMerchantProfile(this.state.updatedProfileDetails);
    })
    .catch((e) => console.log(e));
  }

  componentDidUpdate(){
    console.log("check state",this.state);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    const merchantInfo = {
      email: nextProps.user.email,
    };

    this.setState({ merchantInfo });

    if((!this.props.docTypeValEmail && nextProps.docTypeValEmail) ||  (this.props.docTypeValEmail && this.props.docTypeValEmail !== nextProps.docTypeValEmail)){
      if(nextProps.docTypeValEmail.data.index !== -1 && this.state.updatedProfileDetails.email !== this.state.merchantInfo.email){

        this.setState({
            ...this.state,
            isEmailExists: true,
          }, function(){
            this.profileForm.current.validateFields();
          })
      }else{
        this.setState({
          ...this.state,
          isEmailExists: false,
        }, function(){
            this.profileForm.current.validateFields();
        })
      }
    }
  }

  onProfileFormValuesChanged(changedVals, allVals) {
    this.setState((state) => ({
      updatedProfileDetails: { ...state.updatedProfileDetails, ...changedVals },
      updatedFields: true
    }));

    // let existingValues = this.props.user;
    // if((this.state.updatedProfileDetails.firstName == '' ||
    //    this.state.updatedProfileDetails.firstName == existingValues.firstName) &&
    //    (this.state.updatedProfileDetails.lastName == '' ||
    //    this.state.updatedProfileDetails.lastName == existingValues.lastName) &&
    //    (this.state.updatedProfileDetails.email == '' ||
    //    this.state.updatedProfileDetails.email == existingValues.email)){

    //     this.setState({
    //       updatedFields: false
    //     })
    //   }else{
    //     this.setState({
    //       updatedFields: true
    //     })
    //   }

    console.log(allVals);
  }

  onSelectChangeDocValue (type) {
    console.log("type",type);
    const value = !this.profileForm.current.getFieldValue([type]) ? null: this.profileForm.current.getFieldValue([type])
    this.props.actions.checkdocTypeValExists(type, value);
  }

  render() {
    const { user } = this.props;
    const stateAttributes = this.state;
    return user ? (
      <div className="dashboard-subpage-container">
        <div className="provision-content-container">
          <Title level={3}>Profile Settings</Title>
          <Row style={{ margin: '1.5em' }} gutter={10} align="middle">
            <Col>
              <Avatar size={64} icon={<UserOutlined />} />
            </Col>
            <Col>
              <Title
                level={4}
                style={{ margin: 0 }}
              >{`${user.firstName} ${user.lastName}`}</Title>
              <p style={{ margin: 0 }}>Merchant</p>
            </Col>
          </Row>

          <Tabs defaultActiveKey="1" size="large" style={{ marginBottom: 32 }}>
            <TabPane tab="Details" key="1">
              <Title level={3}>Basic Details</Title>
              <Divider />
              <Form 
                ref={this.profileForm}
                className="limited-width-form-container" 
                layout="vertical"
                initialValues={{
                  username: user.userName,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  email: user.email,
                  mobile: `0${user.mobile.substring(2)}`,
                }}
                onValuesChange={this.onProfileFormValuesChanged.bind(this)}
                >
                <Form.Item label={'Username'} name="username">
                  <Input disabled  />
                </Form.Item>
                <Row gutter={[40, 8]}>
                  <Col span={12}>
                    <Form.Item 
                      label={'First name'}  
                      name="firstName"
                      rules={[
                        ...nameValidator(),
                      ]}>
                      <Input placeholder="Type your first name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item 
                      label={'Last name'} 
                      name="lastName"
                      rules={[
                        ...nameValidator(),
                      ]}>
                      <Input placeholder="Type your last name" />
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Alert
                  type="info"
                  showIcon
                  message={
                    <>
                      To edit these information please headover to
                      <a target='_blank' href="https://keycloak.ideabiz.lk/auth/realms/MarketPlace/account/"> user portal</a>
                    </>
                  }
                /> */}
                <br />

                <Title level={3}>Contact Details</Title>
                <Divider />
                <Form.Item 
                  label={'Email address'} 
                  name="email"
                  rules={[
                    ...emailValidator(),
                    () => ({
                      validator(rule, value) {
                        if(stateAttributes.isEmailExists == true ){
                          return Promise.reject('Email already exists.');
                        } else {
                          return Promise.resolve();
                        }
                      },
                    })
                  ]}
                  >
                  <Input
                    onBlur={this.onSelectChangeDocValue.bind(this, 'email')}
                    placeholder="Type your email address (email@domain.com)"
                  />
                </Form.Item>
                <Form.Item label={'Mobile number'} name="mobile">
                  <Tag icon={<CheckCircleOutlined />} color="#5ec086" style={{marginBottom:'10px'}}>
                    Verified
                  </Tag>
                  <Input disabled defaultValue={`0${user.mobile.substring(2)}`}/>
                </Form.Item>
                <Form.Item>
                  <Button
                    className="sp-primary"
                    onClick={() => this.onUpdateProfile()}
                    disabled={
                      !this.state.updatedFields || stateAttributes.isEmailExists == true
                    }
                  >
                    Update Profile Details
                  </Button>
                </Form.Item>
              </Form>
            </TabPane>
          </Tabs>
        </div>
      </div>
    ) : (
      <Row align="middle" justify="center">
        <Col>
          <Spin size="large" tip="loading user info.." />
        </Col>
      </Row>
    );
  }
}

UserProfilePage.propTypes = {
  user: PropType.object,
  merchantActions: PropType.object,
  docTypeValEmail: PropType.object,
};

function mapStateToProps(state) {
  return {
    docTypeValEmail: state.provisioning.docTypeEmailExists,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    merchantActions: bindActionCreators(merchantActions, dispatch),
    actions: bindActionCreators(provisioningActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePage);
