import { combineReducers } from 'redux';
import provisioning from './provisioningReducer';
import { connectRouter } from 'connected-react-router';
import globalReducer from './globalReducer';
import productReducer from './productReducer';
import merchantReducer from './merchantReducer';
import paymentReducer from './paymentReducer';
import orderReducer from './orderReducer';
import reportReducer from './reportReducer';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    provisioning,
    common: globalReducer,
    products: productReducer,
    orders: orderReducer,
    merchant: merchantReducer,
    payments: paymentReducer,
    reports: reportReducer,
  });

export default rootReducer;
