import React from 'react';
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import { InfoCircleFilled, InfoCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/provisioningActions';
import PropType from 'prop-types';
import { imageRules } from '../../utils/inputValidators';
import natureData from '../../data/natureofbusiness';

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

class ProvisionBankDetails extends React.Component {
  constructor() {
    super();
    this.validateMessages = {
      required: ' ',
      pattern: {
        mismatch: 'Please enter a valid character !',
      },
      max: 'Exceeded maximum allowed character count',
      min: 'Input character count is less than the allowed',
    };
    this.state = {
      bankName: null,
      branchName: null,
      otherNatureOfBusiness: null,
    }

    this.state = {
      natureOfBusiness: [null],
      OtherTextareaShow: false,
      nicTextShow: false,
      brTextShow: false,
      isNicNumberExists: false,
      isBrNumberExists: false,

    }
  }

  componentDidMount() {
    this.props.actions.getBanks();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps && nextProps.docTypeValNic){
      if(nextProps.docTypeValNic.data.index !== -1){
        this.setState({
          ...this.state,
          isNicNumberExists: true,
        }, function(){
          this.props.actionRef.current.validateFields(["nicNumber"]);
        })
      }else{
        this.setState({
          ...this.state,
          isNicNumberExists: false,
        }, function(){
          this.props.actionRef.current.validateFields(["nicNumber"]);
        })
      }
    }

    //validation for BR number duplicate
    if(nextProps && nextProps.docTypeValBr ){
      if(nextProps.docTypeValBr.data.index !== -1){
        this.setState({
          ...this.state,
          isBrNumberExists: true,
        }, function(){
          this.props.actionRef.current.validateFields(["brNumber"]);
        })
      }else{
        this.setState({
          ...this.state,
          isBrNumberExists: false,
        }, function(){
          this.props.actionRef.current.validateFields(["brNumber"]);
        })
      }
    }
  }

    onSelectChangeNature = (value) => {
      let arr
      this.setState({ OtherTextareaShow:false})
      if (!this.state.natureOfBusiness.includes(null) && value.includes(null)) {
        arr = [null];
      } else if (this.state.natureOfBusiness.includes(null) && value.length > 1) {
       arr = value.filter((n) => n);
      } else {
        arr = value;
      }
  
      this.setState({ natureOfBusiness: arr });
      const arrayLength = arr.length;
        for (var i = 0; i < arrayLength; i++) {
            if (arr[i] == "Other") {
              this.setState({ OtherTextareaShow:true})
          }
      }
    }

    onSelectChangeDocType = (value) => {
      this.setState({ nicTextShow:false, brTextShow: false});
      for (var i = 0; i < value.length; i++) {
        if (value[i] == "nic") {
          this.setState({ nicTextShow:true})
        }
        if (value[i] == "br") {
          this.setState({ brTextShow:true})
        }
      }
    }

  onSelectChangeDocValue (type) {
    let fieldValue;
    const value = this.props.actionRef.current.getFieldValue([type])
    if(value){
      fieldValue = value.trim();
      this.props.actions.checkdocTypeValExists(type, fieldValue);
    }
  }

  render() {
    const { isLoading } = this.props;
    const stateAttributes = this.state;

    return this.props.bankList ? (
      <Card
        className="provision-content-container"
        title="Bank & Commercial Details"
        bordered={false}
      >
        <Form
          validateMessages={this.validateMessages}
          ref={this.props.actionRef}
          layout="vertical"
          className="limited-width-form-container"
          onFinish={(formData) => {
            this.props.actionRef.current
              .validateFields()
              .then(() => {
                this.props.actions.saveBankInfo(
                  Object.assign({}, formData, this.state),
                );
              })
              .catch((er) => {
                console.log(er);
              });
          }}
        >
          <Form.Item
            rules={[
              {
                required: true,
              },
              () => ({
                validator(rule, value) {
                  if (!value) {
                    return Promise.reject('You can’t keep this empty');
                  }
                  {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
            label="Bank name"
            name="bank"
          >
            <Select
              showSearch={true}
              placeholder="-select-"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={this.props.isLoading}
              onSelect={(v) => {
                this.props.actionRef.current.resetFields(['branch']);
                this.setState({
                  branchName: null,
                  bankName: this.props.bankList.find((x) => x.bankCode === v)
                    .bankName,
                });
                this.props.actions.getBranches(v);
              }}
            >
              {this.props.bankList
                ? Object.keys(this.props.bankList).map((k) => (
                  <Option
                    key={this.props.bankList[k].bankCode}
                    value={this.props.bankList[k].bankCode}
                  >{`${this.props.bankList[k].bankName} [${this.props.bankList[k].bankCode}]`}</Option>
                ))
                : null}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
              },
              () => ({
                validator(rule, value) {
                  if (!value) {
                    return Promise.reject('You can’t keep this empty');
                  }
                  {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
            label="Branch name"
            name="branch"
          >
            <Select
              showSearch={true}
              placeholder="-select-"
              optionFilterProp="children"
              loading={isLoading}
              disabled={isLoading}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSelect={(v) => {
                this.setState({
                  branchName: this.props.branchList.find(
                    (x) => x.branchCode === v,
                  ).branchName,
                });
              }}
            >
              {this.props.branchList
                ? Object.keys(this.props.branchList).map((k) => (
                  <Option
                    key={this.props.branchList[k].branchCode}
                    value={this.props.branchList[k].branchCode}
                  >{`${this.props.branchList[k].branchName} [${this.props.branchList[k].branchCode}]`}</Option>
                ))
                : null}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
              },
              () => ({
                validator(rule, value) {
                  if (!value || !(value.trim())) {
                    return Promise.reject('You can’t keep this empty');
                  } else if (value && value.length > 12) {
                    return Promise.reject('Account number is too lengthy');
                  } else if (!RegExp('^\\d+$').test(value)) {
                    return Promise.reject('Please enter a valid character');
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
            label="Account number"
            name="accountnumber"
          >
            <Input
              disabled={this.props.isLoading}
              placeholder="XXXXXXXXXXXXXXXXX"
            />
          </Form.Item>
          <Form.Item
            label="Account holder's name"
            name="accountholdername"
            rules={[
              {
                required: true,
              },
              () => ({
                validator(rule, value) {
                  if (!value || !(value.trim())) {
                    return Promise.reject('You can’t keep this empty');
                  } else if (value.length < 3) {
                    return Promise.reject('Account holder’s name is too short');
                  } else if (value && value.length > 25) {
                    return Promise.reject(
                      'Account holder’s name is too lengthy',
                    );
                  } else if (
                    !RegExp('^\\b(?!.*?\\s{2})[A-Za-z ]{0,25}\\b$').test(value)
                  ) {
                    return Promise.reject('Please enter a valid character');
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input
              disabled={this.props.isLoading}
              placeholder="Type the account holder's name"
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
              },
              ...imageRules(),
            ]}
            label={
              <>
                Proofs(Photos) of the passbook &nbsp;
                <Tooltip
                  placement="top"
                  title={
                    'Please upload a copy of bank passbook or a bank confirmation letter which shows bank name, ' +
                    'bank code, branch name, branch code, account number and account holder’s name.'
                  }
                >
                  <InfoCircleFilled />
                </Tooltip>
              </>
            }
            name="passbookproofs"
          >
            <Upload
              className="sp-file-upload"
              accept=".jpg,.png,.pdf"
              multiple
              disabled={this.props.isLoading}
              listType="picture"
              beforeUpload={(file) => {
                if (file.size > 4194304) {
                  return Promise.reject(
                    Modal.error({
                      content: 'Maximum size exceeded',
                      centered: true,
                      maskClosable: false,
                    }),
                  );
                } else return false;
              }}
            >
              <Input disabled placeholder="<4MB,PNG,JPEG,PDF" />
              <Button>Browse</Button>
            </Upload>
          </Form.Item>
          <br />
          <Title level={4}>Commercial Documents</Title>
          <Divider />
          <Form.Item
            label={
              <>
                Business name &nbsp;
                <Tooltip
                  placement="top"
                  title={
                    'Business name will be visible to your customers'
                  }
                >
                  <InfoCircleFilled />
                </Tooltip>
              </>
            }
            name="businessName"
            rules={[
              {
                required: true,
              },
              () => ({
                validator(rule, value) {
                  if (!value || !(value.trim())) {
                    return Promise.reject('You can’t keep this empty');
                  } else if (value.length < 3) {
                    return Promise.reject('Business name is too short');
                  } else if (value && value.length > 30) {
                    return Promise.reject(
                      'Business name is too lengthy',
                    );
                  } 
                  // else if (
                  //   !RegExp('^\\b(?!.*?\\s{2})[A-Za-z ]{0,25}\\b$').test(value)
                  // ) {
                  //   return Promise.reject('Please enter a valid character');
                  // } 
                  else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input
              disabled={this.props.isLoading}
              placeholder="Type your Business name"
            />
          </Form.Item>
          {/* document type dropdown */}
          <Form.Item
              label="Document type"
              name="documentType"
              rules={[
                {
                  required: true,
                },
                () => ({
                  validator(rule, value) {
                    if (!value) {
                      return Promise.reject('You can’t keep this empty');
                    }else if(value.length === 0){
                      return Promise.reject('You can’t keep this empty');
                    }else
                    {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}>
              <Select
                mode="multiple"
                className={'ant-custom-select'}
                size={'large'}
                allowClear
                placeholder="Select the document type"
                onChange={this.onSelectChangeDocType}
                disabled={this.props.isLoading}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.natureOfBusiness}
              >
              <Option value="nic">National Identity Card</Option>
              <Option value="br">Business Registration</Option>
              </Select>
            </Form.Item>
          {this.state.nicTextShow && 
          <Form.Item
            label="National identity card number"
            name="nicNumber"
            validateTrigger='onChange'
            onChange={(value) => {
              this.setState({ isNicNumberExists: false });
            }}
            rules={[
              {
                required: true,
              },
              () => ({
                validator(rule, value) {
                  if (!value || !(value.trim())) {
                    return Promise.reject('You can’t keep this empty');
                  } else if (value && value.length > 12) {
                    return Promise.reject('NIC number is too lengthy');
                  } else if (!RegExp('^([0-9]{9}[x|X|v|V]|[0-9]{12})$').test(value)) {
                    return Promise.reject('Please enter a valid NIC number');
                  } else if(stateAttributes.isNicNumberExists == true ){
                    return Promise.reject('NIC number already exists.');
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}>
            <Input
              disabled={this.props.isLoading}
              onBlur={this.onSelectChangeDocValue.bind(this, 'nicNumber')}
              placeholder="Type your NIC number"
            />
          </Form.Item>}
          {this.state.brTextShow && 
          <Form.Item
            label="Business registration number"
            name="brNumber"
            validateTrigger='onChange'
            onChange={(value) => {
              this.setState({ isBrNumberExists: false });
            }}
            rules={[
              {
                required: true,
              },
              () => ({
                validator(rule, value) {
                  if (!value || !(value.trim())) {
                    return Promise.reject('You can’t keep this empty');
                  } else if (value && value.length > 15) {
                    return Promise.reject('BR number is too lengthy')
                  } else if (value && value.length < 5) {
                    return Promise.reject('BR number is too short')
                  } else if(stateAttributes.isBrNumberExists == true ){
                    return Promise.reject('BR number already exists.');
                  } else if(!RegExp('(?=.*?[0-9]).+').test(value)){
                    return Promise.reject('Should include at least one number ');
                  } else if(!RegExp('(?=.*?[A-Za-z]).+').test(value)){
                    return Promise.reject('Should include at least one alphabet');
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}>
            <Input
              disabled={this.props.isLoading}
              onBlur={this.onSelectChangeDocValue.bind(this, 'brNumber')}
              placeholder="Type your BR number"
            />
          </Form.Item>}
          <Form.Item
            label={<>NIC/Business registration documents</>}
            rules={[
              {
                required: true,
              },
              ...imageRules(),
            ]}
            name="businessdocuments"
          >
            <Upload
              className="sp-file-upload"
              accept=".jpg,.png,.pdf"
              listType="picture"
              multiple
              disabled={this.props.isLoading}
              beforeUpload={(file) => {
                if (file.size > 4194304) {
                  return Promise.reject(
                    Modal.error({
                      content: 'Maximum size exceeded',
                      centered: true,
                      maskClosable: false,
                    }),
                  );
                } else return false;
              }}
            >
              <Input disabled placeholder="<4MB,PNG,JPEG,PDF" />
              <Button>Browse</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label={<>Business logo/image</>}
            name="businesslogo"
            rules={[
              {
                required: true,
              },
              () => ({
                validator(rule, value) {
                  if (
                    !value ||
                    (value.fileList && value.fileList.length === 0)
                  ) {
                    return Promise.reject('Please upload at least one image');
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Upload
              disabled={this.props.isLoading}
              className="sp-file-upload"
              accept=".jpg,.png"
              listType="picture"
              beforeUpload={(file) => {
                if (file.size > 2621440) {
                  return Promise.reject(
                    Modal.error({
                      content: 'Maximum size exceeded',
                      centered: true,
                      maskClosable: false,
                    }),
                  );
                }
                if (
                  this.props.actionRef.current.getFieldValue('businesslogo') &&
                  this.props.actionRef.current.getFieldValue('businesslogo')
                    .fileList.length > 0
                ) {
                  return Promise.reject(
                    Modal.error({
                      content: 'Only one item allowed',
                      centered: true,
                      maskClosable: false,
                    }),
                  );
                } else return false;
              }}
            >
              <Input disabled placeholder="<4MB,PNG,JPEG, resolution of 250*100 pixels" />
              <Button>Browse</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
              },
              () => ({
                validator(rule, value) {
                  if (!value || value.length == 0) {
                    return Promise.reject('You can’t keep this empty');
                  }
                  {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
            label="Nature of the business"
            name="nature"
          >

            <Select
              mode="multiple"
              className={'ant-custom-select'}
              size={'large'}
              allowClear
              placeholder="Select the nature of your business"
              onChange={this.onSelectChangeNature}
              disabled={this.props.isLoading}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={this.state.natureOfBusiness}
            >
              {natureData.natureList.map((itm, idx) => {
                return (
                  <Option key={idx} value={itm.nature}>
                    {itm.nature}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {/* if select other */}
        {this.state.OtherTextareaShow && 
        <Form.Item
          rules={[
            {
              required: true,
            },
            () => ({
              validator(rule, value) {
                if (!value || !(value.trim())) {
                  return Promise.reject('You can’t keep this empty');
                } else if (value && value.length > 250) {
                  return Promise.reject(
                    'Nature Of Business is too lengthy')
                  }else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
          label="Other"
          name="otherNatureOfBusiness"
        >
          <TextArea
            disabled={this.props.isLoading}
            placeholder="Type the nature of your business"
          />
        </Form.Item>}
        </Form>
      </Card>
    ) : null;
  }
  
}

ProvisionBankDetails.propTypes = {
  actions: PropType.object,
  actionRef: PropType.any,
  userInfo: PropType.object,
  bankList: PropType.array,
  branchList: PropType.array,
  user: PropType.object,
  isLoading: PropType.bool,
  docTypeValNic: PropType.object,
  docTypeValBr: PropType.object,
};

function mapStateToProps(state) {
  return {
    bankList: state.provisioning.banksList,
    branchList: state.provisioning.branchesList,
    docTypeValNic: state.provisioning.docTypeValNicExists,
    docTypeValBr: state.provisioning.docTypeValBrExists,
    user: state.common.user,
    isLoading: state.common.isLoadingData,
  };
}

function handleChange(value) {
  console.log(`selected ${value}`);
}



function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProvisionBankDetails);
