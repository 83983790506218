import {
  SAVE_BANK_DATA,
  SAVE_ACCEPT_TOC,
  SET_SETUP_STAGE,
  CHANGE_OTP_FORM_STATE,
  SET_OTP_ID,
  SET_OTP_VERIFICATION,
  SET_BANKS,
  SET_BRANCHES,
  SET_IS_DOC_TYPE_VALUE_NIC_EXISTS,
  SET_IS_DOC_TYPE_VALUE_BR_EXISTS,
  SET_IS_DOC_TYPE_VALUE_EMAIL_EXISTS,
} from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

export default function provisioningReducer(
  state = initialState.provisioning,
  action
) {
  switch (action.type) {
    case SAVE_BANK_DATA:
      return objectAssign({}, state, {
        /* updated state values here */
      });
    case SAVE_ACCEPT_TOC:
      return objectAssign({}, state, {
        acceptedToc: action.payload,
      });
    case SET_SETUP_STAGE:
      return objectAssign({}, state, { setupStage: action.payload });
    case CHANGE_OTP_FORM_STATE:
      return objectAssign({}, state, { otpFormState: action.payload });
    case SET_OTP_ID:
      return objectAssign({}, state, { otpId: action.payload });
    case SET_OTP_VERIFICATION:
      return objectAssign({}, state, { otpVerified: action.payload });
    case SET_BANKS:
      return objectAssign({}, state, { banksList: action.payload });
    case SET_BRANCHES:
      return objectAssign({}, state, { branchesList: action.payload });
    case SET_IS_DOC_TYPE_VALUE_NIC_EXISTS:
        return objectAssign({}, state, { docTypeValNicExists: action.payload });
    case SET_IS_DOC_TYPE_VALUE_BR_EXISTS:
        return objectAssign({}, state, { docTypeValBrExists: action.payload });
    case SET_IS_DOC_TYPE_VALUE_EMAIL_EXISTS:
        return objectAssign({}, state, { docTypeEmailExists: action.payload })
    default:
      return state;
  }
}
