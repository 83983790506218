import {
  RECEIVE_PRODUCT_GRID_DATA,
  RECEIVE_PRODUCT_ITEM_DATA,
  SET_GRID_PAGINATION,
  SET_PRODUCT_GRID_FILTER_QUERY,
  SET_PRODUCT_CREATION_STATUS,
} from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

export default function productReducer(state = initialState.products, action) {
  switch (action.type) {
    case RECEIVE_PRODUCT_GRID_DATA:
      return objectAssign({}, state, {
        gridData: action.payload,
      });
    case RECEIVE_PRODUCT_ITEM_DATA:
      return objectAssign({}, state, {
        itemData: action.payload,
      });
    case SET_GRID_PAGINATION:
      return objectAssign({}, state, {
        gridPagination: action.payload,
      });
    case SET_PRODUCT_GRID_FILTER_QUERY:
      return objectAssign({}, state, {
        gridFilterQuery: action.payload,
      });
    case SET_PRODUCT_CREATION_STATUS:
      return objectAssign({}, state, {
        productCreated: action.payload,
      });
    default:
      return state;
  }
}
