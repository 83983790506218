export function getErrorMessageFromResponse(payload, message) {
  const msg = payload
    ? payload.data
      ? payload.data.message || null
      : null
    : null;
  return msg || message || 'An error occurred';
}

//Specific for product actions
export function getResultsFromResponse(responseData) {
  const results = responseData
    ? responseData.data
      ? responseData.data.results || null
      : null
    : null;
  return results && results instanceof Array ? responseData.data : null;
}
