/* eslint-disable import/no-named-as-default */
import { Redirect } from 'react-router-dom';

import ProvisionBankDetails from '../provisioning/ProvisionBankDetails';
import PropType from 'prop-types';
import React from 'react';
import { hot } from 'react-hot-loader';
import { Typography, Layout, Row, Col, Steps, Card, Button, Modal } from 'antd';
import logo from '../../assets/primary.svg';
import * as moment from 'moment'
import dialogLogo from '../../assets/dialog_logo.svg';
import otpSuccess from '../../assets/otp-success-icon.svg';
import otpVerify from '../../assets/mobile-verify-icon.svg';
import otpSubmit from '../../assets/otp-submit-icon.svg';
import chatIcon from '../../assets/chat-icon.png';
import UserHeaderControl from '../widgets/UserHeaderControl';
import ProvisionTOC from '../provisioning/ProvisionTOC';
import ProvisionFinal from '../provisioning/ProvisionFinal';
import { bindActionCreators } from 'redux';
import * as commonActions from '../../actions/commonActions';
import * as provisioningActions from '../../actions/provisioningActions';
import { connect } from 'react-redux';
import LandingPage from '../LandingPage';
import ProvisionMobileVerification from '../provisioning/ProvisionMobileVerification';
import { getProvisionStateNumber } from '../../constants/enums';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { keycloak } from '../../utils/C2PKeyCloakProvider';
import PageFooter from '../common/PageFooter';

const { Header, Content, Footer } = Layout;
const { Title } = Typography;
const { Step } = Steps;

class ProvisionContainerLayout extends React.Component {
  constructor() {
    super();

    this.childActionRef = React.createRef();

    this.provisionOptions = {
      pane0: {
        backBtn: { isHidden: true, name: '' },
        actionBtn: { isHidden: false, name: 'Next' },
      },
      pane1: {
        backBtn: { isHidden: false, name: 'I do not agree' },
        actionBtn: {
          hasDisable: true,
          isHidden: false,
          name: 'Create my account',
        },
      },
      pane2: {
        backBtn: { isHidden: false, name: 'I do not agree' },
        actionBtn: {
          hasDisable: true,
          isHidden: false,
          name: 'Create my account',
        },
      },
      pane3: {
        backBtn: { isHidden: true, name: '' },
        actionBtn: { isHidden: true, name: '' },
      },
    };
    this.state = {
      actionBtnDisabled: true,
      showOtp: true,
    };
  }

  onChangeStep() {
    console.log('step changed');
  }

  componentDidMount() {
    this.props.commonActions.setLoaderStatus(false);
    this.props.commonActions.fetchUserInfo();
  }

  componentWillUnmount() {
    //clear provision data
  }

  onBackButtonPressed = () => {
    this.setState({ requestChildSubmitAction: true });
    console.log('onBackButtonPressed called');
    const cStep = getProvisionStateNumber(
      this.props.userInfo.provisioningState,
    );
    if (cStep > 1) {
      // this.props.history.go();
    }
    if (cStep > 0) {
      Modal.confirm({
        title:
          'Are you sure you want to cancel all the\n' +
          'configuration made and exit ?',
        okText: 'Yes',
        centered: true,
        cancelText: 'No',
        icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
        onOk: () =>  keycloak.logout({ redirectUri: window.location.origin }),
      });
    }
  };

  onActionButtonPressed() {
    console.log('onActionButtonPressed called');

    const cStep = getProvisionStateNumber(
      this.props.userInfo.provisioningState,
    );

    if (cStep > 2 || cStep < 0) return;

    switch (cStep) {
      case 0:
        this.childActionRef.current.submit();
        break;
      case 1:
        this.props.provisionActions.acceptToc();
        break;
      case 2:
        this.setState({ showOtp: true });
        break;
      default:
        console.log(
          'onActionButtonPressed: invalid case triggered! attention plz',
        );
        break;
    }
  }

  onTOCAgreed(el) {
    console.log(el.target.checked);
    this.setState({ actionBtnDisabled: !el.target.checked });
  }

  onMobileVerificationSendPressed() {
    this.props.commonActions.verifyOtpMobile({
      mobile: this.props.userInfo.mobile.substring(2),
      appPath: 'MERCHANT_VERIFICATION',
      referenceName: 'keycloakId',
      referenceId: this.props.userInfo.keycloakId
    });
  }

  onOtpSubmitPressed(pin) {
    this.props.commonActions.verifyOtp({
      merchantId: this.props.userInfo.id,
      pin: parseInt(pin),
      otpId: this.props.otpId,
    });
  }

  onOtpCancelPressed() {
    Modal.confirm({
      title: 'Are you sure you want to cancel the verification?',
      okText: 'Yes',
      centered: true,
      cancelText: 'No',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      onOk: () => {
        this.setState({ showOtp: false });
        this.props.provisionActions.resetOTPVerification();
      },
    });
  }

  onContinueToFinalStepPressed() {
    this.props.commonActions.fetchUserInfo();
  }

  onProvisionCompleted() {
    // const notficationData = {
    //   type: 'merchant',
    //   message: `New merchant account has been created by ${this.props.userInfo.userName}`,
    // }
    // this.props.commonActions.notificationmsg(notficationData);
    this.props.provisionActions.markProvisionAsCompleted();
  }

  renderActionButtons() {
    const cStep = getProvisionStateNumber(
      this.props.userInfo.provisioningState,
    );
    console.log(`pane${cStep}`);
    if (this.provisionOptions[`pane${cStep}`]) {
      const options = this.provisionOptions[`pane${cStep}`];
      console.log(options);
      const renderedActionBtn = (
        <Button
          className={
            options.actionBtn.isHidden ? 'sp-primary hide' : 'sp-primary'
          }
          onClick={this.onActionButtonPressed.bind(this)}
          disabled={
            options.actionBtn.hasDisable && this.state.actionBtnDisabled
          }
          loading={this.props.isLoading}
        >
          {options.actionBtn.name}
        </Button>
      );
      const renderedBackBtn = (
        <Button
          onClick={this.onBackButtonPressed.bind(this)}
          className={
            options.backBtn.isHidden ? 'sp-secondary hide' : 'sp-secondary'
          }
        >
          {options.backBtn.name}
        </Button>
      );

      return (
        <div className="provision-main-nav-btn-container">
          {renderedBackBtn}
          {renderedActionBtn}
        </div>
      );
    } else {
      return <div className="provision-main-nav-btn-container" />;
    }
  }

  renderProvisionSteps() {
    let cStep = getProvisionStateNumber(this.props.userInfo.provisioningState);
    // cStep = cStep === 3 ? 2 : cStep;
    return (
      <Steps current={cStep} onChange={this.onChangeStep} direction="vertical">
        <Step title="Bank & Commercial Details" />
        <Step title="Terms & Conditions" />
        <Step title="Mobile Number Verification" />
        {/* <Step title="Account completion" /> */}
      </Steps>
    );
  }

  renderProvisionStage() {
    const { userInfo } = this.props;
    switch (getProvisionStateNumber(userInfo.provisioningState)) {
      case 0: // bank details
        return (
          <ProvisionBankDetails
            actionRef={this.childActionRef}
            userInfo={userInfo}
          />
        );
      case 1: // TOC
        return (
          <ProvisionTOC
            onAgreeChecked={this.onTOCAgreed.bind(this)}
            userInfo={userInfo}
          />
        );
      case 2: // Mobile verification
        return (
          <>
            <ProvisionTOC
              userInfo={userInfo}
              onAgreeChecked={this.onTOCAgreed.bind(this)}
            />
            {this.state.showOtp && (
              <ProvisionMobileVerification
                currentMobileNumber={`0${userInfo.mobile.substring(2)}`}
                onSendPressed={this.onMobileVerificationSendPressed.bind(this)}
                onVerifyPressed={this.onOtpSubmitPressed.bind(this)}
                onCompletePressed={this.onContinueToFinalStepPressed.bind(this)}
                onCancel={this.onOtpCancelPressed.bind(this)}
                otpId={this.props.otpId}
                otpVerified={this.props.otpVerified}
                isLoadingData={this.props.isLoading}
              />
            )}
          </>
        );
      case 3: // final provision (assign to tenents and groups)
        return (
          <ProvisionFinal
            actionRef={this.childActionRef}
            userInfo={userInfo}
            onCompleted={this.onProvisionCompleted.bind(this)}
          />
        );
      case 4: // completed provisioning. never to execute. :-)
      default:
        console.log('redirecting...');
        return <Redirect to="/" />;
    }
  }

  render() {
    const { userInfo, isLoading } = this.props;
    console.log(`isLoading: ${isLoading}`);
    console.log(userInfo);
    return isLoading === true && !userInfo ? (
      <LandingPage />
    ) : isLoading === false && !userInfo ? (
      <Redirect to="/" />
    ) : userInfo && userInfo.accountProvisioned === true ? (
      <Redirect to="/dashboard" />
    ) : (
      <Layout className="provision-layout">
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
          <div className="responsive-max-width-container">
            <Row justify="center" align="middle">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row justify="space-between">
                  <Col span={12}>
                    <a className="logo" href="#">
                      <img src={logo} alt="logo" />
                    </a>
                  </Col>
                  <Col span={12} className="user-profile-__wrapper">
                    <div className="provision-header-right-container">
                      <UserHeaderControl
                        userName={userInfo.userName}
                        pathFrom={'provision'}
                        src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Header>
        <Layout className="site-layout" style={{ marginTop: 65 }}>
          <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
            <div className="responsive-max-width-container">
              <div className="provision-title">
                <Title level={1}>Setup your account</Title>
              </div>
              <Row gutter={30}>
                <Col
                  xs={24} sm={24} md={7} lg={7} xl={7}
                  style={{ marginTop: 15 }}
                  className="provisioning-sidebar"
                >
                  {this.renderProvisionSteps()}
                  <br />
                  <Card
                    className="round-corner paddingless-actions contact-us-card"
                    title="Have a doubt?"
                    actions={[
                      <div
                        key="k1"
                        style={{
                          background: '#E6E6FF',
                          height: '70px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Row
                          align="middle"
                          justify="center"
                          style={{ width: '80%', height: '100%' }}
                        >
                          <Col span={4}>
                            <img src={chatIcon} alt="chat-icon" />
                          </Col>
                          <Col span={16}>
                            <Link to='/contact-us'><h5>CONTACT US</h5></Link>
                          </Col>
                        </Row>
                      </div>,
                    ]}>
                    Have any issues or doubts to sort out before you continue? Our
                    team would be glad to help you.
                  </Card>
                  <br />
                </Col>
                <Col xs={24} sm={24} md={17} lg={17} xl={17}>{this.renderProvisionStage(this)}</Col>
              </Row>
              <Row>
                <Col span={24}>{this.renderActionButtons()}</Col>
              </Row>
            </div>
          </Content>
          <PageFooter/>
        </Layout>
      </Layout>
    );
  }
}

ProvisionContainerLayout.propTypes = {
  children: PropType.element,
  match: PropType.object,
  currentStep: PropType.number,
  history: PropType.object,
  userInfo: PropType.object,
  commonActions: PropType.any,
  provisionActions: PropType.any,
  isLoading: PropType.bool,
  otpId: PropType.string,
  otpVerified: PropType.bool,
};

function mapStateToProps(state) {
  return {
    otpId: state.provisioning.otpId,
    otpVerified: state.provisioning.otpVerified,
    currentStep: state.provisioning.setupStage,
    userInfo: state.common.user,
    isLoading: state.common.isLoadingData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
    provisionActions: bindActionCreators(provisioningActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(hot(module)(ProvisionContainerLayout));
