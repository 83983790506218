import React from 'react';
import { Dropdown, Avatar, Menu, Space, Modal } from 'antd';
import PropTypes from 'prop-types';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { keycloak } from '../../utils/C2PKeyCloakProvider';

const UserHeaderControl = ({ children, userName, profileThumb, pathFrom }) => {
  const userLogOut = () => {
    Modal.confirm({
      title: 'Are you sure you want to logout from the user portal ?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => keycloak.logout({ redirectUri: window.location.origin }),
      centered: true,
    });
  };

  const userMenu = (
    <Menu>
      <Menu.Item>{children}</Menu.Item>
      <Menu.Item>
        <a onClick={userLogOut}>Log out</a>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="user-header-control" style={{marginLeft: '12px'}}>
      <Dropdown overlay={userMenu} placement={pathFrom === 'provision' ? 'bottomCenter': 'topCenter'}>
        <Space>
          <Avatar size="default" icon={<UserOutlined />}>
            {profileThumb && <img src={profileThumb} alt="profile-thumb" />}
          </Avatar>
          <span className="user-name">{userName}</span> <DownOutlined />
        </Space>
      </Dropdown>
    </div>
  );
};

UserHeaderControl.propTypes = {
  children: PropTypes.any,
  userName: PropTypes.string,
  profileThumb: PropTypes.string,
  pathFrom: PropTypes.string,
};

export default UserHeaderControl;
