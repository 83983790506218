import axios from 'axios';
import { BASE_URL } from '../constants/config';

let instance = null;

const getMerchantClient = () => {
  if (!instance) {
    instance = axios.create({
      baseURL: BASE_URL,
      responseType: 'json',
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `bearer ${window.kcit}`,
      },
    });
    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }
  return instance;
};

export default getMerchantClient;
