import React from 'react';
import { Layout } from 'antd';
import Footer from './common/Footer';
import Header from './common/Header';
import featureHeader from '../assets/landing/feature-header.svg';
import featureCover from '../assets/landing/feature-cover.webp';
import everythinYouNeed from '../assets/landing/everythings-you-need-full.webp';
import signUpPoster from '../assets/landing/sign-up-poster.webp';
import questionsCover from '../assets/landing/Questions__cover.svg';
import { click2PayCustomerEmail, click2PayCustomerHotline } from '../constants/constants';

const { Content } = Layout;

class AppHome extends React.Component {
  componentDidMount() {
    console.log('========================');
    console.log(window.kcit);
    //this.props.actions.fetchUserInfo();
  }
  render() {
    console.log('============render============');
    return (
      <>
        {/* loader */}
        <div className="preloader">
          <div className="loader">
            <div className="ytp-spinner">
              <div className="ytp-spinner-container">
                <div className="ytp-spinner-rotator">
                  <div className="ytp-spinner-left">
                    <div className="ytp-spinner-circle"></div>
                  </div>
                  <div className="ytp-spinner-right">
                    <div className="ytp-spinner-circle"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* header */}
        <header className="header-area">
          <Header />
          <div id="home" className="header-hero bg_cover d-lg-flex align-items-center">
            <div className="container">
              <div className="row align-items-center justify-content-center justify-content-lg-between">
                <div className="col-lg-6 col-md-10">
                  <div className="header-hero-content">
                    <h3 className="header-title wow fadeInUp" data-wow-duration="0.7s" data-wow-delay="0.2s">Make every click a <br /> sale.​</h3>
                    <p className="text wow fadeInUp" data-wow-duration="0.7s" data-wow-delay="0.6s">From impressions, product description and to a confirmed sale, Click2Pay got you covered. With every click, give your customers many options to pay you easily and faster.​</p>
                    <ul className="d-flex">
                      <li><a href="https://signup.click2pay.lk/signup" className="main-btn wow fadeInUp" data-wow-duration="0.7s" data-wow-delay="0.8s">Start making money now</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-10">
                  <div className="header-image">
                    <img src={featureHeader} alt="Header main cover image" className="image wow fadeInRightBig" data-wow-duration="1.3s" data-wow-delay="0.5s" />
                  </div>
                </div>
              </div>
            </div>
            <div className="header-shape-1 d-none d-md-block"></div>
          </div>
        </header>



        {/* section learn */}
        <section id="learn" className="services-area pt-110 pb-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="section-title text-center pb-25">
                  <h3 className="title">Lock-in Your Sales​</h3>
                  <p className="text">Whether you're a social media seller, or you run a small grocery shop, Click2Pay gives you many options to collect your payments with just a click. And get started without any upfront or annual cost.​</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="single-services services-color-1 text-center mt-30 wow fadeInUpBig" data-wow-duration="0.6s" data-wow-delay="0.2s">
                  <div className="services-content">
                    <h4 className="services-title"><a href="#">Keep it simple​</a></h4>
                    <p className="text">Create and share links of your products in seconds.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="single-services services-color-2 text-center mt-30 wow fadeInUpBig" data-wow-duration="0.6s" data-wow-delay="0.4s">
                  <div className="services-content">
                    <h4 className="services-title"><a href="#">Sell to anybody</a></h4>
                    <p className="text">Never say NO, let your customers pay via any payment method securely.​</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="single-services services-color-3 text-center mt-30 wow fadeInUpBig" data-wow-duration="0.6s" data-wow-delay="0.6s">
                  <div className="services-content">
                    <h4 className="services-title"><a href="#">Power in your hands</a></h4>
                    <p className="text">Don't lose control of your sales - 360° view of your sales anytime with Click2Pay.​</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* section about */}
        <section id="about" className="about-area pt-70 pb-120">
          <div className="container">
            <div className="align-items-center">
              <div className="col-lg-12">
                <div className="about-image mt-50 wow fadeInUpBig" data-wow-duration="0.6s" data-wow-delay="0.2s">
                  <div className="about-shape"></div>
                  <img className="app" src={everythinYouNeed} alt="click2pay dashboard full of features" />
                </div>
              </div>
              <div className="col-lg-12 ">
                <div className="about-content mt-50 wow fadeInUpBig" data-wow-duration="0.6s" data-wow-delay="0.4s">
                  <div className="section-title">
                    <h3 className="title">Simple but Secure​!</h3>
                    <p className="text">Simple process that suits individuals and businesses selling products or services. Secure collection of payments, all at your fingertips - all you need is a Click2Pay account.​</p>
                    <a href="https://signup.click2pay.lk/signup" className="main-btn">Sign up now for free​</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* section why */}
        <section id="why" className="features-area pt-110">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="section-title text-center pb-25">
                  <h3 className="title">Why Click2Pay?​</h3>
                  <p className="text">Why pay initial fees, annual or monthly fees when Click2Pay allows anyone and everyone to get paid easily​</p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="features-items">
                  <div className="single-features features-color-1 d-sm-flex mt-30 wow fadeInUpBig" data-wow-duration="1.3s" data-wow-delay="0.2s">
                    <div className="features-icon d-flex justify-content-center align-items-center">
                      <i className="lni lni-postcard"></i>
                    </div>
                    <div className="features-content media-body">
                      <h4 className="features-title">Sign Up</h4>
                      <p className="text">Click2Pay welcomes any individuals including skilled workers such as painters, electricians, plumbers, social media sellers, as well as small & medium shops or businesses.​ </p>
                    </div>
                  </div>
                  <div className="single-features features-color-2 d-sm-flex mt-30 wow fadeInUpBig" data-wow-duration="1.3s" data-wow-delay="0.4s">
                    <div className="features-icon d-flex justify-content-center align-items-center">
                      <i className="lni lni-mobile"></i>
                    </div>
                    <div className="features-content media-body">
                      <h4 className="features-title">Create Links & Share</h4>
                      <p className="text">Make a page for your product or service, set a price and share a link within seconds. Share this link via SMS, social media, or on a webpage, where anybody can view on any device and proceed to pay.​</p>
                    </div>
                  </div>
                  <div className="single-features features-color-3 d-sm-flex mt-30 wow fadeInUpBig" data-wow-duration="1.3s" data-wow-delay="0.6s">
                    <div className="features-icon d-flex justify-content-center align-items-center">
                      <i className="lni lni-money-protection"></i>
                    </div>
                    <div className="features-content media-body">
                      <h4 className="features-title">Get Paid​</h4>
                      <p className="text">Get paid to your validated bank account for all your Click2Pay sales. Your customers can pay via credit or debit cards, eZCash or add to their mobile bill.​</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="features-image wow fadeInRightBig" data-wow-duration="1.3s" data-wow-delay="0.5s">
                  <img className="image" src={featureCover} alt="App" />
                  <div className="features-shape-1"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* section signup */}
        <section id="signup" className="download-area pt-70 pb-40">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-9">
                <div className="download-image mt-50 wow fadeInRightBig" data-wow-duration="1.3s" data-wow-delay="0.2s">
                  <img className="image" src={signUpPoster} alt="download" />
                  <div className="download-shape-1"></div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="download-content mt-45 wow fadeInUpBig" data-wow-duration="1.3s" data-wow-delay="0.5s">
                  <h3 className="download-title">Discover powerful features that work for your business. Sign up today!</h3>
                  <p className="text">Get onboard today to extend your sales to new market segments, allow customers to pay via many payment options and checkout​ faster. Energize your business today and try out Click2Pay with no setup fees or annual fees​.</p>
                  <ul>
                    <li>
                      <a className="main-btn" href="https://signup.click2pay.lk/signup">Sign up now​</a>
                    </li>
                    {/* <li>
                      <a className="main-btn" href="#">Request for a Demo</a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* section contactus */}
        <section id="contactus" className="download-area pt-70 pb-40">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6">
                <div className="download-content mt-45 wow fadeInUpBig" data-wow-duration="1.3s" data-wow-delay="0.5s">
                  <h3 className="download-title">Any Questions?</h3>
                  <p className="text">We are here to answer any question you have<strong> in any working hour.</strong>​</p>
                  <ul>
                    <li>
                      <a className="main-btn" href="tel:+94773300330"><i className="lni lni-phone"></i> &nbsp;{click2PayCustomerHotline}</a>
                    </li>
                    <li>
                      <a className="main-btn" href="mailto:support@click2pay.lk"><i className="lni lni-envelope"></i> &nbsp;{click2PayCustomerEmail}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-9">
                <div className="download-image mt-50 wow fadeInRightBig" data-wow-duration="1.3s" data-wow-delay="0.2s">
                  <img className="image" src={questionsCover} alt="download" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* footer */}
        <Footer />
      </>
    );
  }
}

export default AppHome;

// AppHome.propTypes = {
//   children: PropType.element,
//   match: PropType.object,
//   isLoadingData: PropType.bool,
//   user: PropType.object,
//   actions: PropType.object,
//   history: PropType.object,
// };

// function mapDispatchToProps(dispatch) {
//   return {
//     actions: bindActionCreators(actions, dispatch),
//   };
// }
// function mapStateToProps(state) {
//   return {
//     isLoadingData: state.common.isLoadingData,
//     user: state.common.user,
//   };
// }
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(hot(module)(AppHome));
