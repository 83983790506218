import React from 'react';
import PropTypes from 'prop-types';
import { keycloak } from '../../utils/C2PKeyCloakProvider';
import { IDLE_TIMEOUT_MINS } from '../../constants/config';

class Idle extends React.Component {
  constructor(props) {
    super(props);
  }

  logout = () => {
    keycloak.logout({ redirectUri: window.location.origin });
  };

  logoutTimeout = null;
  events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

  setTimeouts = () => {
    this.logoutTimeout = setTimeout(this.logout, IDLE_TIMEOUT_MINS * 600000);
  };
  clearTimeouts = () => {
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  };

  componentDidMount() {
    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress',
    ];

    const resetTimeout = () => {
      this.clearTimeouts();
      this.setTimeouts();
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    this.setTimeouts();
  }

  componentWillUnmount() {
    for (let i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
      this.clearTimeouts();
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

Idle.propTypes = {
  children: PropTypes.any,
};

export default Idle;
