import { Layout, Row, Col } from 'antd';
import React from 'react';
import dialogLogo from '../../assets/dialog_logo.svg';
import otpSuccess from '../../assets/otp-success-icon.svg';
import otpVerify from '../../assets/mobile-verify-icon.svg';
import otpSubmit from '../../assets/otp-submit-icon.svg';

const { Footer } = Layout;

const PageFooter = () => {
    return (
        <Footer style={{ paddingLeft: '24px', paddingRight: '24px' }}>
              <div style={{ width: '1px', height: '1px', overflow: 'hidden' }}>
                <img src={otpSuccess} />
                <img src={otpSubmit} />
                <img src={otpVerify} />
              </div>
              <Row align="stretch" justify="space-between">
                <Col className="copyright__wrapper">
                  <img src={dialogLogo} alt="dialog-logo" />
                  <span className="copyright-txt">
                    &nbsp;&nbsp; © 2021 Copyright. Engineered by Ideamart.
                  </span>
                </Col>
                <Col className="footer-right__wrapper">
                  <Row className="footer-right-menu">
                    <Col><a href="/terms" target="_blank">Terms</a></Col>
                    <Col><a href="/privacy" target="_blank">Privacy</a></Col>
                    <Col><a href="/license" target="_blank">License</a></Col>
                  </Row>
                </Col>
              </Row>
            </Footer>
    );
};

export default PageFooter;
