import React from 'react';
import { Col, Row, Spin, Table } from 'antd';
import PropType from 'prop-types';
import moment from 'moment';

class CollectionReportGrid extends React.Component {
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows,
      );
    },
  };

  constructor() {
    super();
    this.columns = [
      {
        title: 'Order date',
        dataIndex: 'dateTimeStamp',
        key: 'dateTimeStamp',
        render: (dateTimeStamp) => {
          return moment(dateTimeStamp).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: 'Transaction ID',
        dataIndex: 'transactionId',
        key: 'transactionId',
      },
      {
        title: 'Item/Service name',
        dataIndex: 'itemServiceName',
        key: 'itemServiceName',
      },
      {
        title: 'Payment method',
        dataIndex: 'paymentMode',
        key: 'paymentMode',
      },
      {
        title: 'Price(LKR)',
        dataIndex: 'price',
        key: 'price',
        align: 'right',
        render: (price) => Number(price).toFixed(2),
      },
      {
        title: 'Delivery fee(LKR)',
        dataIndex: 'deliveryCost',
        key: 'deliveryCost',
        align: 'right',
        render: (deliveryCost) => Number(deliveryCost).toFixed(2),
      },
      {
        title: 'Transaction value(LKR)',
        dataIndex: 'transactionValue',
        key: 'transactionValue',
        align: 'right',
        render: (transactionValue) => Number(transactionValue).toFixed(2),
      },
      {
        title: 'Amount charged to customer',
        dataIndex: 'totalAmountChargedToCustomer',
        key: 'totalAmountChargedToCustomer',
        align: 'right',
        render: (totalAmountChargedToCustomer) => Number(totalAmountChargedToCustomer).toFixed(2),
      },
      {
        title: 'Commission + Tax(LKR)',
        dataIndex: 'commission',
        key: 'commission',
        align: 'right',
        render: (commission) => Number(commission).toFixed(2),
      },
      {
        title: 'Vendor amount(LKR)',
        dataIndex: 'totalVendorCollection',
        key: 'totalVendorCollection',
        align: 'right',
        render: (totalVendorCollection) => Number(totalVendorCollection).toFixed(2),
      },
    ];
  }

  render() {
    // const onChange = (value) => {
    //   const offset = value.current === 1 ? 0 : (value.current - 1) * 10;
    //   this.props.onPaginate({ offset, limit: value.pageSize });
    // };
    const { data, isLoading } = this.props;
    return data && data.results ? (
      <Table
        loading={isLoading}
        className="product-grid"
        // pagination={{
        //   current: this.props.data.currentPage,
        //   total: this.props.data.totalResults,
        // }}
        // onChange={onChange}
        columns={this.columns}
        rowKey={(record) => record.id}
        dataSource={data.results}
        scroll={{ x: 1500 }}
      />
    ) : (
        <Row align="middle" justify="center">
          <Col>
            <Spin size="large" tip="loading table data.." />
          </Col>
        </Row>
      );
  }
}

CollectionReportGrid.propTypes = {
  data: PropType.object,
  onItemViewClicked: PropType.func,
  isLoading: PropType.bool,
  onPaginate: PropType.func,
};

export default CollectionReportGrid;
