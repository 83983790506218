import React from 'react';
import DashboardContainerLayout from '../containers/DashboardContainerLayout';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropType from 'prop-types';
import { bindActionCreators } from 'redux';
import * as commonActions from '../../actions/commonActions';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import OrdersView from './OrdersView';
import OrderItemView from './OrderItemView';

class OrdersPage extends React.Component {
  componentDidMount() {
    this.props.commonActions.fetchUserInfo();
  }
  render() {
    return (
      <DashboardContainerLayout
        user={this.props.user}
        isLoadingData={this.props.isLoadingData}
        match={this.props.match}
      >
        <Switch>
          <Route
            exact
            path={`${this.props.match.path}`}
            render={(props) => <OrdersView user={this.props.user} {...props} />}
          />
          <Route
            path={`${this.props.match.path}/view/:id`}
            render={(props) => (
              <OrderItemView user={this.props.user} {...props} />
            )}
          />
          <Route>
            <Redirect to="/not-found" />
          </Route>
        </Switch>
      </DashboardContainerLayout>
    );
  }
}

OrdersPage.propTypes = {
  match: PropType.object,
  isLoadingData: PropType.bool,
  user: PropType.object,
  commonActions: PropType.object,
  history: PropType.object,
};

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    isLoadingData: state.common.isLoadingData,
    user: state.common.user,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(hot(module)(OrdersPage));
