import React from 'react';
import PropType from 'prop-types';
import { Col, Row, Statistic } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as commonActions from '../../actions/commonActions';

class Statistics extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.actions.fetchStatistics();
  }

  render() {
    const { stats } = this.props;
    return (
      <Row align="middle" justify="space-between" className="stat-container">
        <Col className="stat-item">
          <Statistic
            title="Active Items/Services"
            value={stats ? stats.activeItemsServices : '-'}
          />
        </Col>
        <Col className="stat-item">
          <Statistic
            title="Transactions for the Month"
            value={stats ? stats.numberOfTransactionForMonth : '-'}
          />
        </Col>
        <Col className="stat-item">
          <Statistic
            title="Revenue for the Month"
            value={`LKR ${stats ? stats.totalRevenueReceived : '-'}`}
          />
        </Col>
      </Row>
    );
  }
}

Statistics.propTypes = {
  stats: PropType.object,
  actions: PropType.object,
};

function mapStateToProps(state) {
  return {
    stats: state.common.statistics,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(commonActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
