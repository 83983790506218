import { Layout, Button } from 'antd';
import React from 'react';
import accountSuspended from '../assets/account-suspended.svg';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import { bindActionCreators } from 'redux';
import * as commonActions from '../actions/commonActions';
import { click2PayCustomerHotline, click2PayCustomerEmail } from '../constants/constants';

const { Content } = Layout;
class AccountSuspendedPage extends React.Component {

  constructor() {
    super();
  }

  componentDidMount() {
    this.props.commonActions.fetchUserInfo();
  }

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <img src={accountSuspended} className="infomation-msg-img" />
        <p className="information-msg-title">Your Click2Pay Account is Suspended!</p>
        <p className="infomation-msg-description">
          For more information, contact Click2Pay team via {click2PayCustomerHotline}.
            </p>
        <Button className="sp-primary-alt" href="/">
          Back to Home
        </Button>
      </div>
    );
  }
};

AccountSuspendedPage.propTypes = {
  commonActions: PropType.any
};

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(hot(module)(AccountSuspendedPage));