import {
  RECEIVE_BANK_ACCOUNT_DATA,
  SET_MERCHANT_MOBILE_UPDATED,
  SET_MERCHANT_OTP_ID,
  SET_MERCHANT_OTP_VERIFICATION,
} from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

export default function merchantReducer(state = initialState.merchant, action) {
  switch (action.type) {
    case RECEIVE_BANK_ACCOUNT_DATA:
      return objectAssign({}, state, {
        bankAccount: action.payload,
      });
    case SET_MERCHANT_OTP_ID:
      return objectAssign({}, state, {
        otpId: action.payload,
      });
    case SET_MERCHANT_OTP_VERIFICATION:
      return objectAssign({}, state, {
        otpVerified: action.payload,
      });
    case SET_MERCHANT_MOBILE_UPDATED:
      return objectAssign({}, state, {
        mobileUpdated: action.payload,
      });
    default:
      return state;
  }
}
